import { useContext, useState, useEffect } from 'react';

// context
import GoogleMapContext from '../../context/GoogleMapContext';

// Utilities
import { isEmpty } from 'lodash';
import removeValueType from '../../utils/removeValueType';
import createMarker from '../../utils/createMarker';
import { MarkerProps } from '../index';
import { GoogleMapsMap } from '../../types';

/**
 * Initialize a new Marker object
 *
 * @see https://developers.google.com/maps/documentation/javascript/reference/marker#MarkerOptions for supported Marker options
 *
 * @param {Object} markerProps
 */
const useMarker = (markerProps: MarkerProps) => {
  const { map, google } = useContext(GoogleMapContext);
  const [marker, setMarker] = useState<google.maps.Marker | {}>({});

  useEffect(() => {
    const { lat, lng, ...rest } = markerProps;
    const sanitizedOptions = removeValueType(rest, 'function');
    let isMounted = true;
    const trulyMounted = !isEmpty(map) && isMounted;

    if (trulyMounted && isEmpty(marker)) {
      const addMarker = createMarker(map as GoogleMapsMap, google);
      const newMarker = addMarker({
        position: { lat, lng },
        ...sanitizedOptions,
      });

      setMarker(newMarker);
    }

    if (trulyMounted && !isEmpty(marker)) {
      (marker as google.maps.Marker).setMap(map as GoogleMapsMap);
    }

    return () => {
      isMounted = false;
    };
  }, [markerProps]); // eslint-disable-line

  return marker;
};

export default useMarker;
