import { trackAddToCompare } from '../../services/mixpanelTracking';

// Types
import { CompareState, CompareStateAction } from './types';

export const compareDefaultState: CompareState = {
  'high-school': [],
  'elementary-school': [],
  showPopup: false,
  schoolGroup: 'elementary-school',
};

export const types: {
  ADD_SCHOOL: 'add_school';
  REMOVE_SCHOOL: 'remove_school';
  OPEN_POPUP: 'open popup';
  CLOSE_POPUP: 'close popup';
} = {
  ADD_SCHOOL: 'add_school',
  REMOVE_SCHOOL: 'remove_school',
  OPEN_POPUP: 'open popup',
  CLOSE_POPUP: 'close popup',
};

export function compareReducer(
  prevState: CompareState = compareDefaultState,
  action: CompareStateAction
) {
  switch (action.type) {
    case types.ADD_SCHOOL: {
      let error = false;
      const { schoolGroup, schoolID } = action.payload;

      if (prevState[schoolGroup].length < 5) {
        prevState[schoolGroup].push(schoolID);
        // mixpanel tracking
        trackAddToCompare(schoolID);
      } else {
        error = true;
      }

      if (prevState[schoolGroup].length > 5) {
        prevState[schoolGroup] = prevState[schoolGroup].slice(0, 5);
      }

      return {
        ...prevState,
        showPopup: true,
        schoolGroup,
        popupError: error,
      };
    }
    case types.REMOVE_SCHOOL: {
      const { schoolGroup, schoolID } = action.payload;
      const index = prevState[schoolGroup].indexOf(schoolID);

      prevState[schoolGroup].splice(index, 1);

      return {
        ...prevState,
        schoolGroup,
        popupError: false,
      };
    }
    case types.OPEN_POPUP:
      return { ...prevState, showPopup: true, popupError: false };
    case types.CLOSE_POPUP:
      return { ...prevState, showPopup: false, popupError: false };
    default:
      console.error('invalid action type');
      return prevState;
  }
}
