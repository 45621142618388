import { useState, useEffect, useContext } from 'react';
import eventNames from '../../EventNames/marker';

// context
import GoogleMapContext from '../../context/GoogleMapContext';

// Utilities
import { isEmpty } from 'lodash';
import handleEvent from '../../utils/handleEvent';
import { MarkerProps } from '../index';
import { EventListeners } from '../../types';

const useEventListeners = (marker: google.maps.Marker, props: MarkerProps) => {
  const [eventListeners, setEventListeners] = useState<EventListeners>({});
  const { google } = useContext(GoogleMapContext);

  useEffect(() => {
    const listeners = {} as EventListeners;
    let isMounted = true;
    const trulyMounted =
      !isEmpty(marker) && isMounted && isEmpty(eventListeners);

    if (trulyMounted) {
      eventNames.forEach(eventName => {
        const handler = handleEvent(eventName, props, marker);
        listeners[eventName] = {
          handler,
          listener: marker.addListener(eventName, handler),
        };
      });

      setEventListeners(listeners);
    }

    if (!isEmpty(eventListeners)) {
      Object.keys(eventListeners).forEach(eventName => {
        marker.addListener(eventName, eventListeners[eventName].handler);
      });
    }

    return () => {
      isMounted = false;

      if (!isEmpty(eventListeners)) {
        Object.keys(eventListeners).forEach(eventName => {
          google.maps.event.removeListener(eventListeners[eventName].listener);
        });
      }
    };
  }, [marker, props]); // eslint-disable-line

  return eventListeners;
};

export default useEventListeners;
