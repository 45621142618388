import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SetStateFunction } from '../../../types';

const useListenToRouterHistory = (setOpen: SetStateFunction<boolean>) => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => setOpen(false));

    return () => {
      unlisten();
    };
  }, [history, setOpen]);
};

export default useListenToRouterHistory;
