// Filters
import { defaultFilters } from './index';

// Utilities
import { get, includes, isEmpty } from 'lodash';
import isFilterGroupChecked from './utils/isFilterGroupChecked';
import utilities from '../../../services/utilities';
import getFiltersFromFilterGroup from './utils/getFiltersFromFilterGroup';
import { MapSchool } from '../types';

const programs = (schools: MapSchool[] = [], filters = defaultFilters) => {
  let schoolsUpdated = [...schools];
  const { programs } = filters;

  if (isEmpty(programs)) {
    return schoolsUpdated;
  }

  const filtersChange = isFilterGroupChecked(programs);

  // To reduce the number of 'filtering' we perform a check if ALL or NONE of the programs
  // have been checked off and return the schools that were passed in.
  if (filtersChange) {
    return schoolsUpdated;
  }

  const filtersChecked = getFiltersFromFilterGroup(programs, true, 'value');

  schoolsUpdated = schoolsUpdated.filter(school => {
    let isMatch = false;
    const { program = [] } = school;

    if (isEmpty(program)) {
      return isMatch;
    }

    const schoolPrograms = program.map(program => {
      const programSpecialty = get(program, 'program_specialty');
      const programGroup = get(program, 'program');

      const programName = !isEmpty(programSpecialty)
        ? programSpecialty
        : programGroup;

      return utilities.createObjectKey(programName);
    });

    schoolPrograms.forEach(program => {
      if (includes(filtersChecked, program)) {
        isMatch = true;
      }
    });

    return isMatch;
  });

  return schoolsUpdated;
};

export default programs;
