import React, { FC } from 'react';
import Lang from '../../../components/Lang';
import PropTypes from 'prop-types';
import { get } from 'lodash';

import { useSchoolContext } from '../../../context/SchoolContext';

import s from '../profile.module.scss';

interface SurveyDetailProps {
  dataKey: string;
}

const SurveyDetail: FC<SurveyDetailProps> = props => {
  const { dataKey } = props; // in this case, this key will always be the survey results
  const schoolData = useSchoolContext();
  const metricData = get(schoolData, dataKey);
  // TODO: refactor to _.last
  // TODO: Double-check shouldn't be _.first (check data order)
  const thisYear = metricData[metricData.length - 1];

  const { score } = thisYear;
  let legendKeyMap = {
    'Well Organized': 'climateWellOrganizedHelp',
    Organized: 'climateOrganizedHelp',
    'Moderately Organized': 'climateModeratelyOrganizedHelp',
    'Partially Organized': 'climatePartiallyOrganizedHelp',
    'Not Yet Organized': 'climateNotYetOrganizedHelp',
  };

  const legend = get(legendKeyMap, score);

  return (
    <div className={s.detailContainer}>
      <h3
        className={`sqrp-green 
      ${score === 'Well Organized' ? s.activeSurveyLevel : s.surveyLevel}`}>
        <Lang textKey="wellOrganized" />
      </h3>
      <h3
        className={`sqrp-light-green 
      ${score === 'Organized' ? s.activeSurveyLevel : s.surveyLevel}`}>
        <Lang textKey="organized" />
      </h3>
      <h3
        className={`sqrp-yellow 
      ${
        score === 'Moderately Organized' ? s.activeSurveyLevel : s.surveyLevel
      }`}>
        <Lang textKey="moderatelyOrganized" />
      </h3>
      <h3
        className={`sqrp-light-red 
      ${
        score === 'Partially Organized' ? s.activeSurveyLevel : s.surveyLevel
      }`}>
        <Lang textKey="partiallyOrganized" />
      </h3>
      <h3
        className={`sqrp-red 
      ${score === 'Not Yet Organized' ? s.activeSurveyLevel : s.surveyLevel}`}>
        <Lang textKey="notYetOrganized" />
      </h3>
      <p>
        <Lang textKey={legend} />{' '}
        <Lang
          textKey="climateSourceHelp"
          values={[
            '<a href="https://www.5-essentials.org/cps/5e/2018/">',
            '</a>',
          ]}
        />
      </p>
    </div>
  );
};

SurveyDetail.propTypes = {
  dataKey: PropTypes.string.isRequired,
};

export default SurveyDetail;
