import * as _ from 'lodash';
import { Constants } from './constants';
import { registerGeolocation } from './mixpanelTracking';
import { School } from '../hooks/useSchoolData';
import { Filters } from '../reducers/map/types';

class UtilityService {
  /**
   * For use with Chart.js API where rgba/hex values are requried
   *
   * @param {string} sqrpPoint SQRP sub-metric 'Points' value
   *
   * @returns {string} rgba/hex value for colorizing canvas graphic
   */
  pointToCanvasColor(sqrpPoint: string): string | null | void {
    switch (sqrpPoint) {
      case '1':
        return 'rgb(200, 20, 20, 1)';
      case '2':
        return 'rgba(249, 0, 7, 1)';
      case '3':
        return 'rgba(250, 190, 49, 1)';
      case '4':
        return 'rgba(60, 215, 60, 1)';
      case '5':
        return 'rgba(50, 178, 50, 1)';
      case null:
        return null;
      default:
        console.error(
          'invalid point value given to pointVectorSwitch utility method',
          sqrpPoint
        );
    }
  }

  pointToCanvasColorHex(sqrpPoint: string) {
    switch (sqrpPoint) {
      case '1':
        return '#C81414';
      case '2':
        return '#F90007';
      case '3':
        return '#FABE31';
      case '4':
        return '#3CD73C';
      case '5':
        return '#32B232';
      case null:
        return null;
      default:
        console.error(
          'invalid point value given to pointVectorSwitch utility method',
          sqrpPoint
        );
    }
  }

  /**
   * For use in colorizing text to match a sub-metric's point value
   * for properties other than 'color', add the appropriate prefix
   * Ex) fill- or stroke-
   *
   * @param {string} sqrpPoints sqrp sub-metric 'Points' value
   *
   * @returns {string} className to modify an elements color
   */
  pointToColor(sqrpPoints: string) {
    switch (sqrpPoints) {
      case '1':
        return 'sqrp-red';
      case '2':
        return 'sqrp-light-red';
      case '3':
        return 'sqrp-yellow';
      case '4':
        return 'sqrp-light-green';
      case '5':
        return 'sqrp-green';
      default:
        return 'sqrp-gray';
    }
  }

  levelColorMap = {
    'Level 3': 'sqrp-red',
    'Level 2': 'sqrp-light-red',
    'Level 2+': 'sqrp-yellow',
    'Level 1': 'sqrp-light-green',
    'Level 1+': 'sqrp-green',
  };

  levelColorMapHex = {
    'Level 3': '#C81414',
    'Level 2': '#F90007',
    'Level 2+': '#FABE31',
    'Level 1': '#3CD73C',
    'Level 1+': '#32B232',
  };

  levelToColor(levelRating: string) {
    const exp = /Level [1-5]\+?/;
    const matches = levelRating.match(exp);
    const match = _.get(matches, 0, '');

    return _.get(this, ['levelColorMap', match], 'gray-text');
  }

  levelToColorValue(levelRating: string): string {
    const exp = /Level [1-5]\+?/;
    const matches = levelRating.match(exp);
    const match = _.get(matches, 0, '');

    return _.get(this, ['levelColorMapHex', match], '#888888');
  }

  /**
   * calculates the synthetic score color for the given metric group
   * @param {string} group metric data group key
   * @param {object} metricParams metric group json object with group params
   * @param {object} schoolData data for individual school record with all fields
   * @return {("sqrp-red" | "sqrp-green" | "sqrp-light-green" | "sqrp-light-red" | "sqrp-yellow" | "sqrp-gray")} sqrp color class
   */
  colorMetricGroup(group: string, metricParams: any, schoolData: any) {
    try {
      const params = metricParams[group];
      const metrics = params.metrics;

      const metricRecords = metrics
        .map((metric: string) => schoolData[metric])
        .map((historyRecord: any) => historyRecord[historyRecord.length - 1])
        .filter((rec: any) => rec.score !== '');

      const totalWeight = metricRecords.reduce(
        (total: number, each: any) => (total += parseFloat(each.weight)),
        0
      );

      const avgScore =
        metricRecords.reduce(
          (total: number, each: any) =>
            (total += parseFloat(each.points) * parseFloat(each.weight)),
          0
        ) / totalWeight;

      const points = Math.round(avgScore);

      return this.pointToColor(String(points));
    } catch {
      return 'sqrp-gray';
    }
  }

  /**
   * Searches through school record objects, modifying 'match' property of record objects.
   * Charactor case and word order is ignored.
   * @param {string} term query string to search against
   * @param {array} schools array of school record objects
   */
  searchSchools(term: string, schools: any[]) {
    let words = _.split(_.toLower(term), ' ');

    words = _.filter(words, word => !!word);
    words = _.map(words, _.trim);
    schools.forEach(school => {
      let match = true;

      _.forEach(words, word => {
        if (!_.includes(_.get(school, 'name_lower'), word)) {
          match = false;
        }
      });
      // should only show matches that are already meant to be shown based on other criteria
      school.match = match;
    });
    return schools;
  }

  /**
   * reads current location from browser geolocation API
   * @returns {Promise<object>} user lat,long position or an error object.
   */
  getLocation() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        resp => {
          const pos = { lng: resp.coords.longitude, lat: resp.coords.latitude };
          registerGeolocation(pos);
          resolve(pos);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  /**
   * Calculates distance in miles between point A and point B
   *
   * @param {object} point1 lat,lng of point A
   * @param {object} point2 lat,lng of point B
   *
   * @returns {number} Distance in miles
   */
  calcDistance(point1: any, point2: any) {
    const { lat: lat1, lng: lng1 } = point1;
    const { lat: lat2, lng: lng2 } = point2;

    if (lat1 === lat2 && lng1 === lng2) {
      return 0;
    } else {
      const radlat1 = (Math.PI * lat1) / 180;
      const radlat2 = (Math.PI * lat2) / 180;
      const theta = lng1 - lng2;
      const radtheta = (Math.PI * theta) / 180;

      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);

      if (dist > 1) {
        dist = 1;
      }

      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;

      return dist;
    }
  }

  /**
   * Uses GMaps API to geocode address into lat,long position and a formatted address string.
   * @param {string} address user address query
   *
   * @returns {Promise<object>} position and formatted address strings.
   */
  async geocode(address: string) {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyA11tkFlK5mF6mFXi_mNsnpN4DbmP55lXA&address=${address}`;
    const res = await fetch(url);
    const data = await res.json();

    if (data.status !== 'OK') {
      throw new Error('response status was invalid');
    }

    // check if in Chicago, IL
    const { formatted_address } = data.results[0];

    if (!formatted_address.includes('Chicago, IL')) {
      throw new Error('address not from Chicago');
    }

    const pos = data.results[0].geometry.location;

    registerGeolocation(pos, formatted_address);

    return {
      pos,
      formatted_address,
      short_address: formatted_address.split(',')[0],
    };
  }

  groupName(group: string, qty: number) {
    if (undefined === qty) {
      qty = 1;
    }

    const qtyKey = 1 === qty ? 'singular' : 'plural';
    return _.get(Constants.groupTextMap, [group, qtyKey]);
  }

  getGroups() {
    return _.keys(Constants.groupTextMap);
  }

  /**
   * Generate an appropriate 'key' like string from passed in string
   *
   * @param {string} key
   * @returns {string}
   */
  createObjectKey(key: string) {
    return key.toLowerCase().replace(/\W+/g, '-');
  }

  /**
   * From an array of schools, find a given school based on the passed in School ID.
   * Optionally accepts a "prop" argument to get a specific value from the school (eg, "SQRP Rating")
   * NOTE: passing in a prop of "name" will guarantee a name is returned (tries for "School Name", but falls back to "long_name")
   *
   * @param {number} schoolID
   * @param {array} schools
   * @param {string=} prop
   *
   * @return {string}
   */
  findSchool(schoolID: string, schools: any[], prop?: string) {
    let output = '';

    schools.some(school => {
      const school_id = _.get(school, 'school_id');

      if (school_id === schoolID) {
        output = school;
        return true;
      }

      return false;
    });

    if (prop) {
      if ('name' === prop) {
        output = _.get(output, 'school_name', _.get(output, 'long_name'));
      } else {
        output = _.get(output, prop);
      }
    }

    return output;
  }

  /**
   * Creates and returns an accessor function for a specific field found in a school object
   *
   * @param {object} school
   * @param {string} key
   *
   * @returns {function(*=, *=) : *}
   */
  getAccessor(school: School, key: string) {
    return (school: School, defaultValue: any = '') => {
      return _.get(school, key, defaultValue);
    };
  }

  /**
   * Get a school's `school_latitude` field from school object
   *
   * @param school
   * @returns {*}
   */
  getSchoolLatitude(school: any) {
    return parseFloat(
      this.getAccessor(school, 'school_latitude')(school, _.get(school, 'lat'))
    );
  }

  getMostRecentSQRPRating(school: any) {
    return _.get(
      _.last(this.getAccessor(school, 'sqrp_rating')(school, {})),
      'value'
    );
  }

  /**
   * Get 'school_latitude` field from school object
   *
   * @param school
   * @returns {*}
   */
  getSchoolLongitude(school: any) {
    return parseFloat(
      this.getAccessor(school, 'school_longitude')(
        school,
        _.get(school, 'long')
      )
    );
  }

  /**
   * Retrieved the grades served by a school
   *
   * @param school
   * @returns {*}
   */
  getGradesServed(school: any) {
    return this.getAccessor(school, 'grades_offered')(
      school,
      _.get(school, 'grades_served')
    );
  }

  /**
   * Retrieve the number of students enrolled in a school
   *
   * @param school
   * @returns {*}
   */
  getStudentTotal(school: any) {
    return this.getAccessor(school, 'student_count_total')(
      school,
      _.get(school, 'enrollment')
    );
  }

  /**
   * Retrieve a school's accountability status field array
   *
   * @param school
   * @returns {*}
   */
  getAccountabilityStatus(school: any) {
    return this.getAccessor(school, 'accountability_status')(school, []);
  }

  /**
   *
   * @param filters
   * @param type
   * @returns {*}
   */
  getFilterType<T = any>(filters: any, type: keyof Filters): T {
    return this.getAccessor(filters, type)(filters, {});
  }

  /**
   * This function return a function to be passed in [].reduce()
   * that derives the TOTAL number of active sub-filters provided a filter type
   *
   * @param filterType
   * @returns {function(...[*]=)}
   */
  getFilterCountReducer(filterType: any) {
    return (count: number, filterKey: string) => {
      const filter = _.get(filterType, filterKey, {});
      const children = _.get(filter, 'children', []);

      if (_.isEmpty(children)) {
        return filter.isChecked ? count + 1 : count;
      }

      return (
        count +
        children.reduce(
          (count: number, child: any) => (child.isChecked ? count + 1 : count),
          0
        )
      );
    };
  }

  /**
   * This functions derives the TOTAL count of active filters
   *
   * @param filters
   * @returns {number}
   */
  getActiveFilterCount(filters: any) {
    const filterTypes = [
      'communityAreas',
      'ctePrograms',
      'grades',
      'programs',
      'scores',
      'schoolTypes',
    ];

    return Object.keys(filters)
      .filter(filterType => filterTypes.includes(filterType))
      .reduce((count, filterTypeKey) => {
        const filterType = _.get(filters, filterTypeKey, {});

        return (
          count +
          Object.keys(filterType).reduce(
            this.getFilterCountReducer(filterType),
            0
          )
        );
      }, 0);
  }
}

export default new UtilityService();
