import React, { FC } from 'react';
import PropTypes from 'prop-types';

// Context
import { useSchoolContext } from '../../../context/SchoolContext';

// Utils
import _ from 'lodash';
import metricGroups from '../metric-groups';
import utilities from '../../../services/utilities';

// Styles
import styles from '../full-report.module.scss';

interface GroupBreakdownProps {
  groupKey: string;
}

interface MetricRecord {
  data: {
    score: string;
    points: string;
    weight: string;
  };
  key: string;
}

const GroupBreakdown: FC<GroupBreakdownProps> = props => {
  const schoolData = useSchoolContext();

  const { groupKey } = props;

  const groupParams = _.get(metricGroups, groupKey);

  if (!_.has(groupParams, 'name')) {
    console.error('Group Metrics does not contain the key', groupKey);
    return <> </>;
  }

  const { name, description, metrics } = groupParams as {
    name: string;
    description: string;
    metrics: string[];
  };

  const metricRecords: MetricRecord[] = metrics
    .map(metric => ({
      key: metric,
      data: _.get(schoolData, metric, [{ score: '' }]),
    }))
    .map(rec => ({ ...rec, data: rec.data[rec.data.length - 1] }))
    .filter(rec => rec.data.score !== '')
    // sorts metric records by weight
    .sort((a, b) => parseFloat(b.data.weight) - parseFloat(a.data.weight));

  const totalWeight = metricRecords.reduce(
    (total, rec) => (total += parseFloat(rec.data.weight)),
    0
  );

  return (
    <div className={styles.detailCard}>
      <h3 className={''}>{name}</h3>
      <svg className="mb-5em mt-5em" width="100%" height="25px">
        {groupTotalRect(metricRecords, totalWeight)}
      </svg>
      <small>{description}</small>
      {makeDetail(metricRecords, totalWeight)}
    </div>
  );
};

const makeDetail = (metricRecords: MetricRecord[], totalWeight: number) => {
  return metricRecords.map(metric => {
    const score = metric.data.score;
    const scoreColor = utilities.pointToColor(metric.data.points);
    const rectColor =
      'fill-lighten-' + utilities.pointToColor(metric.data.points);

    return (
      <React.Fragment key={metric.key}>
        <h4 key={metric.key}>{metric.key}</h4>
        <div className={styles.detailBarContainer}>
          <svg
            className="mb-5em mt-5em"
            width={
              ((parseFloat(metric.data.weight) / totalWeight) * 100).toFixed(
                2
              ) + '%'
            }
            height="25px">
            <rect
              y="0"
              x="0"
              width="100%"
              height="100%"
              className={rectColor}
            />
          </svg>
          <strong className={`ml-25em ${scoreColor}`}>{score}</strong>
        </div>
      </React.Fragment>
    );
  });
};

const groupTotalRect = (metricRecords: MetricRecord[], totalWeight: number) => {
  let startAt = 0;
  const padding = 0.5;

  return metricRecords.map(({ data: metric }, ix) => {
    const x = startAt;
    const width = (parseFloat(metric.weight) / totalWeight) * 100 - padding;
    const color = 'fill-lighten-' + utilities.pointToColor(metric.points);

    startAt += width + 0.5;

    return (
      <rect
        x={`${x}%`}
        y="0"
        width={`${width}%`}
        height="100%"
        className={color}
        key={ix}
      />
    );
  });
};

GroupBreakdown.propTypes = {
  groupKey: PropTypes.string.isRequired,
};

export default React.memo(GroupBreakdown);
