export const highSchoolMetrics = [
  {
    metric: 'sat11_cohort_growth_percentile',
    priority: false,
    langKey: 'satCohortGrowthPercentile',
  },
  {
    metric: 'sat11_african_american_cohort_growth_percentile',
    priority: true,
    langKey: 'africanAmericanCohortGrowthPercentile',
  },
  {
    metric: 'sat11_hispanic_cohort_growth_percentile',
    priority: true,
    langKey: 'hispanicCohortGrowthPercentile',
  },
  {
    metric: 'sat11_english_learner_cohort_growth_percentile',
    priority: true,
    langKey: 'englishLearnerGrowthPercentileMathTitle',
  },
  {
    metric: 'sat11_diverse_learner_cohort_growth_percentile',
    priority: true,
    langKey: 'diverseLearnerGrowthPercentileReadingTitle',
  },
  {
    metric: 'sat11_ebrw_annual_growth_percentile',
    priority: false,
    langKey: 'SATAnnualGrowthPercentileReading',
  },
  {
    metric: 'sat11_math_annual_growth_percentile',
    priority: false,
    langKey: 'SATAnnualGrowthPercentileMath',
  },
  {
    metric: 'psat09_cohort_growth_percentile',
    priority: false,
    langKey: 'PSAT09CohortGrowthPercentile',
  },
  {
    metric: 'psat10_ebrw_annual_growth_percentile',
    priority: false,
    langKey: 'PSAT10AnnualGrowthPercentileReading',
  },
  {
    metric: 'psat10_math_annual_growth_percentile',
    priority: false,
    langKey: 'PSAT10AnnualGrowthPercentileMath',
  },
  {
    metric: 'percent_meeting_college_readiness_benchmarks',
    priority: false,
    langKey: 'pwrcentOfStudentMeetingCollegeReadinessBenchmarks',
  },
  {
    metric: 'freshmen_on_track_rate',
    priority: false,
    langKey: 'freshmenOnTrackRateTitle',
  },
  {
    metric: '4_year_cohort_graduation_rate',
    priority: false,
    langKey: '4YearCohortGraduationRate',
  },
  {
    metric: '1_year_dropout_rate',
    priority: false,
    langKey: '1YearDropoutRate',
  },
  {
    metric: 'college_enrollment_rate',
    priority: false,
    langKey: 'collegeEnrollmentRateTitle',
  },
  {
    metric: 'college_persistence_rate',
    priority: false,
    langKey: 'collegePersistenceRateTitle',
  },
  {
    metric: '%_earning_early_college_and_career_credentials',
    priority: false,
    langKey: 'percentOfGraduatesEarningEarlyCollegeAndCollegeCredentials',
  },
  {
    metric: 'average_daily_attendance_rate',
    priority: false,
    langKey: 'averageDailyAttendanceRateTitle',
  },
  // TODO: Duplicates
  {
    metric: 'my_voice_my_school_5_essentials_survey',
    priority: false,
    langKey: 'myVoiceMySchool5EssentialsSurvey',
  },
  {
    metric: 'data_quality_index_score',
    priority: false,
    langKey: 'dataQualityIndexScore',
  },
];

export const elementarySchoolMetrics = [
  {
    metric: 'national_school_growth_percentile_reading',
    priority: false,
    langKey: 'nationalGrowthPercentileReadingTitle',
  },
  {
    metric: 'national_school_growth_percentile_math',
    priority: false,
    langKey: 'nationalGrowthPercentileMathTitle',
  },
  {
    metric:
      'percent_of_students_meetingexceeding_national_average_growth_norms',
    priority: false,
    langKey: 'percentOfStudentsMeetingNationalAverage',
  },
  {
    metric: 'african_american_growth_percentile_reading',
    priority: true,
    langKey: 'africanAmericanGrowthPercentileReading',
  },
  {
    metric: 'hispanic_growth_percentile_reading',
    priority: true,
    langKey: 'hispanicGrowthPercentileMath',
  },
  {
    metric: 'english_learner_growth_percentile_reading',
    priority: true,
    langKey: 'englishLearnerGrowthPercentileReading',
  },
  {
    metric: 'diverse_learner_growth_percentile_reading',
    priority: true,
    langKey: 'diverseLearnerGrowthPercentileReading',
  },
  {
    metric: 'african_american_growth_percentile_math',
    priority: true,
    langKey: 'africanAmericanGrowthPercentileMath',
  },
  {
    metric: 'hispanic_growth_percentile_math',
    priority: true,
    langKey: 'hispanicGrowthPercentileMath',
  },
  {
    metric: 'english_learner_growth_percentile_math',
    priority: true,
    langKey: 'englishLearnerGrowthPercentileMath',
  },
  {
    metric: 'diverse_learner_growth_percentile_math',
    priority: true,
    langKey: 'diverseLearnerGrowthPercentileMath',
  },
  {
    metric: 'national_school_attainment_%ile_reading_grades_3_8',
    priority: false,
    langKey: 'nationalSchoolAttainmentPercentileReadingGrades38',
  },
  {
    metric: 'national_school_attainment_%ile_math_grades_3_8',
    priority: false,
    langKey: 'nationalSchoolAttainmentPercentileMathGrades38',
  },
  {
    metric: 'national_school_attainment_%ile_reading_grade_2',
    priority: false,
    langKey: 'nationalSchoolAttainmentPercentileReadingGrade2',
  },
  {
    metric: 'national_school_attainment_%ile_math_grade_2',
    priority: false,
    langKey: 'nationalSchoolAttainmentPercentileMathGrade2',
  },
  {
    metric: 'average_daily_attendance_rate_grades_k_8',
    priority: false,
    langKey: 'averageDailyAttendanceRateGradesK8',
  },
  {
    metric: '%_of_students_making_sufficient_annual_progress_on_access',
    priority: false,
    langKey: 'percentageOfStudentsMakingSufficientAnnualProgressOnAccess',
  },
  // TODO: Duplicates
  {
    metric: 'my_voice_my_school_5_essentials_survey',
    priority: false,
    langKey: 'myVoiceMySchool5EssentialsSurvey',
  },
  {
    metric: 'data_quality_index_score',
    priority: false,
    langKey: 'dataQualityIndexScore',
  },
];
