import React, { FC } from 'react';
import { Link } from 'react-router-dom';

// Components
import NavBar from '../../components/NavBar';
import QuickLinks from '../../components/QuickLinks';
import Footer from '../../components/Footer';
import Row from '../../components/Row';
import Column from '../../components/Row/Column';
import Block from '../../components/Block';
import MainContent from '../../components/Block/MainContent';
import FooterContent from '../../components/Block/FooterContent';
import Lang from '../../components/Lang';

// Custom Hooks
import useTrackPageVisit from '../../hooks/useTrackPageVisit';
import useReadableLanguage from '../../hooks/useReadableLanguage';

// Mixpanel
import { trackCustomEvent } from '../../services/mixpanelTracking';

// Styles
import styles from './dataTransparency.module.scss';
import schoolProfileStyles from '../Profile/profile.module.scss';

const DataTransparency: FC = props => {
  const language = useReadableLanguage();

  useTrackPageVisit('Data Transparency');

  return (
    <>
      <NavBar />
      <QuickLinks />
      <Row className={schoolProfileStyles.basicInfoContainer}>
        <Column>
          <h2>
            <Lang textKey="dataTransparencyTitle" />
          </h2>
        </Column>
      </Row>
      <Row className={`grid-row ${schoolProfileStyles.profileRow}`}>
        <Column className="col-4">
          <Block>
            <MainContent>
              <p>
                <Lang textKey="aboutTitle" />
              </p>
              <p>
                <Lang textKey="aboutDescription" />
              </p>
            </MainContent>
            <FooterContent isFooterLink>
              <a
                href="https://kidsfirstchicago.org/sqrp"
                target="_blank"
                rel="noreferrer noopener">
                <Lang textKey="viewProfile" />
              </a>
            </FooterContent>
          </Block>
          <Block>
            <MainContent>
              <p>
                <Lang textKey="contactUsButton" />
              </p>
              <h3>Kids First Chicago</h3>
              <h4>(312) 853-1212</h4>
              <h4>
                <a
                  href="https://kidsfirstchicago.org/"
                  rel="noreferrer noopener"
                  target="_blank">
                  <Lang textKey="website" />
                </a>
              </h4>
            </MainContent>
          </Block>
          <Block>
            <MainContent>
              <p>
                <Lang textKey="tourTheToolTitle" />
              </p>
              <p>
                <Lang textKey="tourTheToolDescription" />
              </p>
            </MainContent>
            <FooterContent isFooterLink>
              <a
                href="https://kidsfirstchicago.org/"
                rel="noreferrer noopener"
                target="_blank">
                <Lang textKey="viewProfile" />
              </a>
            </FooterContent>
          </Block>
          <Block>
            <FooterContent className={styles.blueFooterLink} isFooterLink>
              <Link
                onClick={() => {
                  trackCustomEvent('Navigated To Search', {
                    action: 'click',
                    trigger: 'Search for schools',
                    language,
                    description: 'Sidebar link',
                    location: 'Sidebar card',
                  });
                }}
                to="/map">
                <Lang textKey="searchForSchools" />
              </Link>
            </FooterContent>
          </Block>
        </Column>
        <Column className="col-8">
          <div>
            <h2 className="text-uppercase">
              <Lang textKey="dataTransparencyStatementTitle" />
            </h2>
            <p>
              <Lang textKey="dataTransparencyStatementDescription" />
            </p>
          </div>
          <div className="pt-2em spacer" />
          <div className="pt-2em">
            <h2 className="text-uppercase">
              <Lang textKey="dataTransparencySchoolDataTitle" />
            </h2>
            <p>
              <Lang textKey="dataTransparencySchoolDataDescription" />
            </p>
            <ul className="mb-1em">
              <li>
                <Lang textKey="dataTransparencyListItemOne" />
              </li>
              <li>
                <Lang textKey="dataTransparencyListItemTwo" />
              </li>
            </ul>
            <p className="mt-2em">
              <a
                href="#"
                rel="noreferrer noopener nofollow"
                className={styles.roundButton}>
                <Lang textKey="visitDataPortal" />
              </a>
            </p>
          </div>
          <div className="pt-2em spacer" />
          <div className="pt-2em">
            <h2 className="text-uppercase">
              <Lang textKey="dataTransparencyStatementTitle" />
            </h2>
            <p>
              <Lang textKey="dataTransparencySchoolDataDescription" />
            </p>
            <p>
              <Lang textKey="name" />
              <br />
              <Lang textKey="addressLocation" />
              <br />
              <Lang textKey="startEndTime" />
              <br />
              <Lang textKey="schoolWebsite" />
              <br />
              <Lang textKey="enrollmentInformation" />
            </p>
            <p className="mt-2em">
              <a
                href="#"
                rel="noreferrer noopener nofollow"
                className={styles.roundButton}>
                <Lang textKey="visitDataPortal" />
              </a>
            </p>
          </div>
        </Column>
      </Row>
      <Footer />
    </>
  );
};

export default DataTransparency;
