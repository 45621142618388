// Utilities
import { isEmpty } from 'lodash';

// Helper
import getFiltersFromFilterGroup from './getFiltersFromFilterGroup';
import { FilterGroup } from '../../types';

/**
 * This is a utility function for which returns a boolean value of
 * whether ALL or NONE filters of `filterGroup` have been checked by the user
 *
 * @param filterGroup
 * @returns {boolean}
 */
const isFilterGroupChecked = (filterGroup: FilterGroup): boolean => {
  const allFilters = getFiltersFromFilterGroup(filterGroup);
  const checkedFilters = getFiltersFromFilterGroup(filterGroup, true);

  // For all filter type, having ALL or NONE should return the same result
  return isEmpty(checkedFilters) || allFilters.length === checkedFilters.length;
};

export default isFilterGroupChecked;
