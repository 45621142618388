import React, { FC } from 'react';

// Context
import { useSchoolContext } from '../../../context/SchoolContext';

// Component
import Lang from '../../../components/Lang';
import CompareCheckBox from '../../../components/CompareCheckBox';

import { get } from 'lodash';

// Utilities
import utilities from '../../../services/utilities';

// Styles
import styles from '../profile.module.scss';

const SchoolMainInfo: FC = props => {
  const schoolData = useSchoolContext();

  const schoolID = get(schoolData, 'school_id');
  const group = get(schoolData, 'group');
  const grades_served = utilities.getGradesServed(schoolData);
  const enrollment = utilities.getStudentTotal(schoolData);

  const name = get(schoolData, 'school_name', get(schoolData, 'long_name'));

  return (
    <>
      <div className={styles.basicInfoContainer}>
        <div>
          <h2 className={'mt-0 mb-0'}>
            <strong>{name}</strong>
          </h2>
          <p>
            <Lang textKey="gradesServed" /> {grades_served} | {enrollment}{' '}
            <Lang textKey="students" />
          </p>
        </div>
        <div>
          <CompareCheckBox
            className={styles.compareCheckBox}
            schoolID={schoolID}
            schoolGroup={group}
          />
        </div>
      </div>
    </>
  );
};

export default SchoolMainInfo;
