import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';

// Components
import Lang from '../Lang';
import ToggleLang from '../ToggleLang';

// Custom hooks
import useOpenNavBar from './hooks/useOpenNavBar';
import useListenToRouterHistory from './hooks/useListenToRouterHistory';
import useReadableLanguage from '../../hooks/useReadableLanguage';

// Mixpanel
import { trackCustomEvent } from '../../services/mixpanelTracking';

// Styles
import styles from './navbar.module.scss';

const NavBar: FC<{ className?: string }> = ({ className = '' }) => {
  const [open, setOpen] = useState(false);
  const language = useReadableLanguage();

  useOpenNavBar(open);
  useListenToRouterHistory(setOpen);

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <nav role="navigation">
        {open && <div className={styles.disableTouch} />}
        <div className={styles.menuToggle}>
          <input
            type="checkbox"
            checked={open}
            onChange={e => setOpen(e.target.checked)}
          />
          <svg
            className={`${styles.ham} ${styles.hamRotate} ${
              open ? styles.active : ''
            }`}
            viewBox="0 0 100 100"
            width="55"
            onClick={() => setOpen(!open)}>
            <path
              className={styles.line + ' ' + styles.top}
              d="m 30,33 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40"
            />
            <path
              className={styles.line + ' ' + styles.middle}
              d="m 30,50 h 40"
            />
            <path
              className={styles.line + ' ' + styles.bottom}
              d="m 30,67 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40"
            />
          </svg>
          <ul className={styles.menu}>
            <li className={styles.imageFlex}>
              <img
                src="/assets/images/smaller_logo.png"
                alt="logo"
                width="100px"
                height="100px"
              />
              <p>
                <Lang textKey="menuHeader" />
              </p>
            </li>

            <Link to="/">
              <li>
                <Lang textKey="homeLink" />
              </li>
            </Link>
            <Link
              onClick={() => {
                trackCustomEvent('Navigated To Search', {
                  action: 'click',
                  trigger: 'School Search',
                  language,
                  description: 'Flyout menu link',
                  location: 'Flyout menu',
                });
              }}
              to="/map">
              <li>
                <Lang textKey="searchLink" />
              </li>
            </Link>
            <Link to="/data-transparency">
              <li>
                <Lang textKey="aboutTheDataLink" />
              </li>
            </Link>
            <li>
              <ToggleLang>
                {({ handleClick }: { handleClick: () => void }) => {
                  return (
                    <span onClick={handleClick} style={{ cursor: 'pointer' }}>
                      <Lang textKey="toggleLanguage" />
                    </span>
                  );
                }}
              </ToggleLang>
            </li>
            <br />
            <Link
              onClick={() => {
                trackCustomEvent('Navigated To Search', {
                  action: 'click',
                  trigger: 'Take the Tour',
                  language,
                  description: 'Flyout menu link',
                  location: 'Flyout menu',
                });
              }}
              to={{ pathname: '/map', hash: '#tour' }}>
              <li>
                <Lang textKey="tourLink" />
              </li>
            </Link>
            <a
              href="https://guide.kidsfirstchicago.org/"
              target="_blank"
              rel="noreferrer noopener nofollow">
              <li>
                <Lang textKey="schoolGuide" />
              </li>
            </a>
          </ul>
        </div>
      </nav>
      <div className={styles.logoWrapper}>
        <Link to="/">
          <img src="/assets/images/smaller_logo.png" alt="" />
          <h3>
            <Lang textKey="navHeader" />
          </h3>
        </Link>
      </div>
    </div>
  );
};

export default NavBar;
