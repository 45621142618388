import { Bounds, LatLng } from '../../types';

/**
 * Determine whether object's `lat` and `long`
 * fit within a provided Google Maps bounds
 *
 * @param {Object} objLatLng
 * @param {Object} bounds
 *
 * @returns bool
 */
const isWithinBounds = (objLatLng: LatLng, bounds: Bounds) => {
  const { northEastBounds, southWestBounds } = bounds;
  const { lat, lng } = objLatLng;

  const withinLatBounds =
    lat <= northEastBounds.lat && lat >= southWestBounds.lat;
  const withinLngBounds =
    lng <= northEastBounds.lng && lng >= southWestBounds.lng;

  return withinLatBounds && withinLngBounds;
};

export default isWithinBounds;
