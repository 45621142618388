import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

// Utilities
import { get } from 'lodash';
import utilities from '../../../services/utilities';

interface GroupLabelProps {
  quantity: number;
}

const GroupLabel: FC<GroupLabelProps> = props => {
  const { group } = useParams();

  return <>{utilities.groupName(group, get(props, 'quantity', 1))}</>;
};

export default GroupLabel;
