import React, { FC } from 'react';

// Context
import { useSchoolContext } from '../../../context/SchoolContext';

// Utils
import metricGroups from '../metric-groups';
import utilities from '../../../services/utilities';

// Styles
import styles from '../full-report.module.scss';

interface GroupItemProps {
  icon: string;
  text: string;
  colorFromGroup: string;
  onClick?: () => void;
  active: boolean;
  color?: string;
}

const GroupItem: FC<GroupItemProps> = props => {
  const schoolData = useSchoolContext();
  const { colorFromGroup } = props;

  const color = utilities.colorMetricGroup(
    colorFromGroup,
    metricGroups,
    schoolData
  );

  return (
    <div
      onClick={props.onClick}
      className={
        styles.groupWrapper + ' ' + (props.active ? '' : styles.inactive)
      }>
      <span className={styles.circleLogo}>
        <i className={`fa ${props.icon} ${color}`} />
      </span>
      <strong className="ml-5em">{props.text}</strong>
    </div>
  );
};

GroupItem.defaultProps = {
  color: 'black',
  onClick: () => {},
  text: '',
  active: false,
};

export default React.memo(
  GroupItem,
  (prev, next) => prev.active === next.active
);
