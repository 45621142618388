import { Chart, ChartData, ChartDataSets } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import _ from 'lodash';

interface DataSet {
  schoolID: string;
  label: string;
  data: number;
  sqrpColor: string;
}

function createData(datasets: DataSet[]): ChartData {
  // @ts-ignore
  const dataSets: ChartDataSets[] = _.map(datasets, score => {
    return {
      label: score.label,
      data: [score.data],
      backgroundColor: ['#002d5b'],
      borderColor: score.sqrpColor,
      borderWidth: 3,
      borderSkipped: false,
    };
  });

  return { datasets: dataSets };
}

export default function makeComparisonChart(
  metric: string,
  schoolData: DataSet[],
  ctx: CanvasRenderingContext2D
) {
  return new Chart(ctx, {
    type: 'horizontalBar',
    data: createData(schoolData),
    plugins: [ChartDataLabels],
    options: {
      plugins: {
        datalabels: {
          font: {
            size: 14,
            weight: 'bold',
          },
          color: function(context) {
            //@ts-ignore
            const maxValue = context.chart.scales['x-axis-0'].end;
            const value = (context.dataset.data as number[])[context.dataIndex];
            const percentage = _.divide(value, maxValue);

            if (percentage > 0.5) {
              return 'white';
            } else if (percentage > 0.3 || percentage < 0.01) {
              return '#aaaaaa';
            }

            return '#888888';
          },
          anchor: 'start',
          align: 'right',
          offset: 15,
          formatter(value, context) {
            if (!+value) {
              value = 'N/A';
            } else {
              value += '%';
            }

            return `${context.dataset.label} - ${value}`;
          },
        },
      },
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            display: false,
          },
        ],
        xAxes: [
          {
            ticks: {
              suggestedMin: 0,
              suggestedMax: 100,
            },
          },
        ],
      },
    },
  });
}
