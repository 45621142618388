import { GridTabReducerAction, GridTabState } from './types';

export const OPEN_GRID_TAB = 'OPEN_GRID_TAB';
export const TOGGLE_GRID_TAB = 'TOGGLE_GRID_TAB';

const activeTabReducer = (
  oldOpenedTab: GridTabState,
  action: GridTabReducerAction
) => {
  const { type } = action;

  switch (type) {
    case OPEN_GRID_TAB: {
      const {
        payload: { tabClicked },
      } = action;

      return tabClicked;
    }
    case TOGGLE_GRID_TAB: {
      const {
        payload: { tabClicked },
      } = action;

      return oldOpenedTab === tabClicked ? '' : tabClicked;
    }
    default: {
      return oldOpenedTab;
    }
  }
};

export default activeTabReducer;
