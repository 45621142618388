import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface RowProps {
  className?: string;
}

const Row: FC<RowProps> = ({ children, className = '' }) => (
  <div className={`row ${className}`}>{children}</div>
);

Row.propTypes = {
  className: PropTypes.string,
};

Row.defaultProps = {
  className: '',
};

export default Row;
