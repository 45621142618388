import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';

// Store
import { useStoreValue } from '../../../context/StoreContext';

// Utilities
import { get, last, map, uniqBy, orderBy, size } from 'lodash';
import utility from '../../../services/utilities';

// Components
import Loading from '../../../components/Loading';
import CircleRating from '../../../components/CircleRating';
import Lang from '../../../components/Lang';
import CompareChart from './CompareChart';

// Custom Hooks
import useMultiSchoolData from '../hooks/useMultiSchoolData';
import useReadableLanguage from '../../../hooks/useReadableLanguage';

// Reducers
import { types } from '../../../reducers/compare';

// Mixpanel
import { trackCustomEvent } from '../../../services/mixpanelTracking';

// Styles
import styles from '../compare.module.scss';

interface CompareDetailsProps {
  group: string;
}

const CompareDetails: FC<CompareDetailsProps> = props => {
  // TODO: TRANSLATE: the following object needs to be refactored to allow translation
  // TODO: Use `metricDetails.js` for this instead
  let options = {
    'high-school': [
      { key: 'sat11_cohort_growth_percentile', label: 'SAT Growth' },

      // TODO: Cale below is the 'Average SAT' metric
      // {key: 'sat_test.average_composite_score', label: 'Average SAT'},

      {
        key: 'percent_meeting_college_readiness_benchmarks',
        label: 'College Readiness Benchmarks',
      },
      { key: '4_year_cohort_graduation_rate', label: '4 Year Graduation Rate' },
      { key: 'freshmen_on_track_rate', label: 'Freshman On Track Rate' },
      {
        key: 'average_daily_attendance_rate',
        label: 'Average Daily Attendance',
      },
      { key: 'college_enrollment_rate', label: 'College Enrollment' },
      { key: 'college_persistence_rate', label: 'College Persistence' },
      {
        key: '%_earning_early_college_and_career_credentials',
        label: 'Early College Credentials',
      },
    ],
    'elementary-school': [
      {
        key: 'national_school_growth_percentile_reading',
        label: 'Reading Growth',
      },
      {
        key: 'national_school_attainment_%ile_reading_grades_3_8',
        label: 'Reading Attainment',
      },
      { key: 'national_school_growth_percentile_math', label: 'Math Growth' },
      {
        key: 'national_school_attainment_%ile_math_grades_3_8',
        label: 'Math Attainment',
      },
      {
        key:
          'percent_of_students_meetingexceeding_national_average_growth_norms',
        label: '% of Students Meeting/Exceeding Average Growth',
      },
      {
        key: 'average_daily_attendance_rate_grades_k_8',
        label: 'Average Daily Attendance',
      },
    ],
  };

  const { group } = props;
  const { compare } = useStoreValue();
  const [compareState, dispatchCompare] = compare;

  const schools = get(compareState, group, []);

  const groupOptions = get(options, group);

  const [compareMetric, setCompareMetric] = useState(groupOptions[0].key);

  let schoolData = useMultiSchoolData(schools);
  schoolData = uniqBy(schoolData, 'school_id');

  const language = useReadableLanguage();

  if (!schoolData) return <Loading fixedCenter />;

  let addButton;
  if (size(schoolData) < 5) {
    addButton = (
      <Link
        onClick={() => {
          trackCustomEvent('Navigated To Search', {
            action: 'click',
            trigger: 'Add another school',
            language,
            description: 'Navigates back to school search',
            location: 'Compare details screen',
          });
        }}
        to="/map"
        className={`${styles.resultItem} ${styles.addAnotherSchool} __compare_details_1__`}>
        <div>
          <i className={styles.fa + ' fa fa-plus'} />
        </div>
        <div>Add Another School</div>
        <div />
      </Link>
    );
  }

  let schoolWithMetrics = map(schoolData, school => {
    const sqrpColor = get(last(get(school, 'sqrp_rating')), 'value') as string;

    return {
      schoolID: get(school, 'school_id'),
      label: get(school, 'long_name', get(school, 'school_name')),
      data: +get(last(get(school, compareMetric)), 'score'),
      sqrpColor: utility.levelToColorValue(sqrpColor),
    };
  });

  schoolWithMetrics = orderBy(schoolWithMetrics, 'data', 'desc');

  return (
    <>
      {schoolData
        .filter(school => 'combo' !== school.group)
        .map(school => {
          const {
            grades_served,
            enrollment,
            address,
            relativeDist,
            school_id: schoolID,
          } = school;

          const group = get(school, 'group') as
            | 'elementary-school'
            | 'high-school';

          const levelRating = get(
            last(get(school, 'sqrp_rating')),
            'value'
          ) as string;
          const totalScore = get(school, [
            'sqrp_total_points_earned',
            0,
            'value',
          ]);
          const name = get(school, 'long_name', get(school, 'school_name'));
          const scoreColor = utility.levelToColor(levelRating);

          return (
            <div className={styles.resultItem} key={schoolID}>
              <div className={styles.leftContentWrapper}>
                <CircleRating levelRating={levelRating} />
              </div>
              <div>
                <Link
                  to={`/${group}/${schoolID}`}
                  className={styles.removeBasicLinkStyles}>
                  <h3 className="mt-0 mb-25em">{name}</h3>
                  <p className={styles.schoolDetail}>
                    <Lang textKey="gradesServed" />: {grades_served}
                  </p>
                  <p className={styles.schoolDetail}>
                    {enrollment} <Lang textKey="students" />
                  </p>
                  <p className={styles.schoolDetail}>{address}</p>
                  {relativeDist && (
                    <p className={styles.schoolDetail}>
                      <strong>{relativeDist.toFixed(2)}</strong>{' '}
                      <Lang textKey="milesAway" />
                    </p>
                  )}
                </Link>
              </div>
              <div className={styles.totalScore}>
                <p>
                  <span className={scoreColor}>{totalScore}</span>
                  <span> / 5.0</span>
                </p>
                <p
                  className={styles.remove}
                  onClick={() => {
                    dispatchCompare({
                      type: types.REMOVE_SCHOOL,
                      payload: { schoolID, schoolGroup: group },
                    });
                  }}>
                  <i className="fa fa-trash" />
                  <label>REMOVE</label>
                </p>
              </div>
            </div>
          );
        })}
      {addButton}
      <div className="__compare_details_2__" style={{ background: 'white' }}>
        <div className={styles.controls}>
          <label>Compare:</label>
          <select
            onChange={e => {
              setCompareMetric(e.target.value);
            }}
            value={compareMetric}>
            {groupOptions.map((option: { key: string; label: string }) => {
              return (
                <option value={option.key} key={option.key}>
                  {option.label}
                </option>
              );
            })}
          </select>
        </div>
        <div className={styles.comparison}>
          <CompareChart metric={compareMetric} schoolData={schoolWithMetrics} />
        </div>
      </div>
    </>
  );
};

export default CompareDetails;
