// Filters
import { defaultFilters } from './index';

// Utilities
import { isEmpty } from 'lodash';
import utilities from '../../../services/utilities';
import isFilterGroupChecked from './utils/isFilterGroupChecked';
import getFiltersFromFilterGroup from './utils/getFiltersFromFilterGroup';
import { MapSchool } from '../types';

const communityArea = (schools: MapSchool[] = [], filters = defaultFilters) => {
  let schoolsUpdated = [...schools];
  const { communityAreas } = filters;

  // Only mutate schools IFF 'Community Area' filters are set.
  // This is merely precautionary since 99.99999% of the time they WILL be.
  if (isEmpty(communityAreas)) {
    return schoolsUpdated;
  }

  const filtersPassedCheck = isFilterGroupChecked(communityAreas);

  // For 'Community Area' filter type, having ALL or NONE should return the same result
  // In this case, just return schools unfiltered
  if (filtersPassedCheck) {
    return schoolsUpdated;
  }

  const communityAreaFilters = getFiltersFromFilterGroup(communityAreas, true);

  schoolsUpdated = schoolsUpdated.filter(school => {
    const { community: communityArea = '' } = school;

    if (isEmpty(communityArea)) {
      return false;
    }

    const communityAreaKey = utilities.createObjectKey(communityArea);

    return communityAreaFilters.includes(communityAreaKey);
  });

  return schoolsUpdated;
};

export default communityArea;
