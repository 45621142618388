import React, { FC } from 'react';
import ReactDOM from 'react-dom';

// Types
import { ModalProps } from './types';

const Modal: FC<ModalProps> = props => {
  const { show, children, wrapperClass, contentClass, onClose } = props;

  const modalParentDiv = document.querySelector('#modal') as Element;

  const renderPopUp = () => {
    return (
      <div className={`modal-container ${wrapperClass}`}>
        <div className="close">
          <i onClick={onClose} className="fa fa-2x fa-close" />
        </div>
        <div className={`modal-content ${contentClass}`}>{children}</div>
      </div>
    );
  };

  return show ? ReactDOM.createPortal(renderPopUp(), modalParentDiv) : null;
};

Modal.defaultProps = {
  show: false,
  wrapperClass: '',
  contentClass: '',
  onClose: () => {},
};

export default Modal;
