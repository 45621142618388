import React, { FC, useCallback, useMemo, useReducer } from 'react';
import { useParams } from 'react-router-dom';

// Context
import SchoolContext from '../../context/SchoolContext';
import ActiveGridContext from '../../context/GridContext';

// Reducers
import activeTabReducer, { OPEN_GRID_TAB } from '../../reducers/gridTab';
import {
  accordionsReducer,
  elementaryAccordionsDefaultState,
  highSchoolAccordionsDefaultState,
  OPEN_ACCORDION,
} from '../../reducers/accordions';

// Components
import Loading from '../../components/Loading';
import QuickLinks from '../../components/QuickLinks';
import Footer from '../../components/Footer';
import Nav from './components/ProfileNav';
import Tour from '../../components/Tour';
import Row from '../../components/Row';
import Column from '../../components/Row/Column';
import SchoolScore from './components/SchoolScore';
import SchoolMainInfo from './components/SchoolMainInfo';
import SchoolSidebar from './components/SchoolSidebar';
import Tab from '../../components/Tabs/Tab';
import Lang from '../../components/Lang';
import TotalScoreHistory from './components/TotalScoreHistory';
import SummaryMetrics from './components/SummaryMetrics';
import ElementarySchoolMetrics from './components/ElementarySchoolMetrics';
import HighSchoolMetrics from './components/HighSchoolMetrics';
import Tabs from '../../components/Tabs';

// Custom Hooks
import useSchoolData, { School } from '../../hooks/useSchoolData';
import useDocumentMetaData from '../../hooks/useDocumentMetaData';
import useRatingSchema from '../../hooks/useRatingSchema';

// Utilities
import { isEmpty } from 'lodash';

// Styles
import styles from './profile.module.scss';

// Types
import { TourStep, TourStepTransition } from '../../components/Tour/types';
import { AccordionDispatchAction } from '../../reducers/accordions/types';
import { AccordionsContextProvider } from '../../context/AccordionsContext';

// Tour Steps
import tourSteps, { tourStepTargetKeys } from './tourSteps';
import useTrackPageVisit from '../../hooks/useTrackPageVisit';

interface SchoolProfileProps {
  steps?: TourStep[];
}

interface TabState {
  activeTab: string;
}

interface TabDispatchAction {
  type: string;
  payload: string;
}

const TAB_CLICKED = 'TAB_CLICKED';

const tabReducer = (state: TabState, action: TabDispatchAction) => {
  if (TAB_CLICKED === action.type) {
    const { activeTab } = state;
    const { payload: tabClicked } = action;

    // Don't trigger unnecessary state updates
    if (tabClicked === activeTab) {
      return state;
    }

    return { activeTab: action.payload };
  }

  return state;
};

const SchoolProfile: FC<SchoolProfileProps> = props => {
  const { schoolID } = useParams<{ schoolID: string }>();

  const schoolData = useSchoolData(schoolID);
  useDocumentMetaData(schoolData);
  useRatingSchema(schoolData);
  useTrackPageVisit('School Profile');

  const [openedElement, dispatchGridClick] = useReducer(activeTabReducer, '');
  const activeGridContext = { openedElement, dispatchGridClick };

  // Holds current state for active Tab
  const [tabState, dispatchTab] = useReducer(
    tabReducer,
    { activeTab: '' },
    () => ({
      activeTab: 'summary',
    })
  );

  const { activeTab } = tabState;

  const onTabClickHandler = useCallback(
    (tabKey: string) => {
      dispatchTab({ type: TAB_CLICKED, payload: tabKey });
    },
    [dispatchTab]
  );

  // Holds state for accordions used in ElementaryMetrics component
  const [
    elementarySchoolAccordions,
    dispatchElementarySchoolAccordions,
  ] = useReducer(accordionsReducer, elementaryAccordionsDefaultState);

  const elementaryAccordionsContextValue = {
    state: elementarySchoolAccordions,
    dispatch: dispatchElementarySchoolAccordions,
  };

  // Holds state for the accordions used in HighSchoolMetrics component
  const [highSchoolAccordions, dispatchHighSchoolAccordions] = useReducer(
    accordionsReducer,
    highSchoolAccordionsDefaultState
  );

  const highSchoolAccordionsContextValue = {
    state: highSchoolAccordions,
    dispatch: dispatchHighSchoolAccordions,
  };

  // TODO: translate schoolData related code to Typescript
  const { group = '' } = schoolData as School;

  const steps = useMemo<TourStep[]>(() => {
    return tourSteps.map(step => {
      const { target } = step;
      const { stepTwo, stepFour, stepFive } = tourStepTargetKeys;
      const isElementaryOrCombo =
        'elementary-school' === group || 'combo' === group;

      // This ensures the 'Summary' tab is selected and children JSX is rendered
      const summaryTabAction: TabDispatchAction = {
        type: TAB_CLICKED,
        payload: 'summary',
      };

      if (stepTwo === target) {
        // This ensures that the 'Summary' tab is selected before Entering Step 3
        const beforeNext: TourStepTransition = [
          { dispatcher: dispatchTab, action: summaryTabAction },
        ];

        return { ...step, beforeNext };
      }

      if (stepFour === target) {
        // Ensure that the correct Tab is selected/rendered
        // For Elementary/Combo schools the 'elementary' tab will be selected
        const tabActionStepFour: TabDispatchAction = {
          type: TAB_CLICKED,
          payload: isElementaryOrCombo ? 'elementary' : 'highSchool',
        };

        // Run state updates before the Tour enters Step 5.
        const beforeNext: TourStepTransition = [
          { dispatcher: dispatchTab, action: tabActionStepFour },
        ];

        return { ...step, beforeNext };
      }

      if (stepFive === target) {
        const gridTabStepAction = {
          type: OPEN_GRID_TAB,
          payload: {
            tabClicked: isElementaryOrCombo
              ? 'african_american_growth_percentile_math'
              : 'freshmen_on_track_rate',
          },
        };

        // Update accordion state
        const accordionActionStepFour: AccordionDispatchAction = {
          type: OPEN_ACCORDION,
          payload: isElementaryOrCombo
            ? 'priorityGroups'
            : 'progressTowardsGraduation',
        };

        // Run state update(s) to select 'Summary' tab before stepping back one step (to Step 4)
        const beforePrev: TourStepTransition = [
          { dispatcher: dispatchTab, action: summaryTabAction },
        ];
        const beforeNext: TourStepTransition = [
          { dispatcher: dispatchGridClick, action: gridTabStepAction },
          {
            dispatcher: isElementaryOrCombo
              ? dispatchElementarySchoolAccordions
              : dispatchHighSchoolAccordions,
            action: accordionActionStepFour,
          },
        ];

        return { ...step, beforeNext, beforePrev };
      }

      return step;
    });
  }, [
    group,
    dispatchElementarySchoolAccordions,
    dispatchHighSchoolAccordions,
    dispatchTab,
    dispatchGridClick,
  ]);

  if (isEmpty(schoolData)) return <Loading fixedCenter />;

  return (
    <>
      <Tour dispatch={dispatchGridClick} steps={steps} />
      <SchoolContext.Provider value={schoolData}>
        <Nav />
        <QuickLinks />
        <Row>
          <Column>
            <SchoolMainInfo />
          </Column>
        </Row>
        <Row className={`grid-row ${styles.profileRow}`}>
          <Column className={`col-4 __school_profile_7__ ${styles.tourStep}`}>
            <SchoolSidebar />
          </Column>
          <Column className={`col-8 ${styles.borderedBlock}`}>
            <div
              className={`pl-75em pr-75em ${styles.mainContainer} ${styles.tourStep} __school_profile_1__`}>
              <SchoolScore />
            </div>
            <ActiveGridContext.Provider value={activeGridContext}>
              <Tabs
                defaultTab="summary"
                activeTab={activeTab}
                onTabClick={onTabClickHandler}
                tabsClassName={`${styles.tourStep} __school_profile_2__`}>
                <Tab tabKey="summary" translationKey="summaryTabLabel">
                  <div className="pt-1em">
                    <div className="__tour2__ ">
                      <h2 className="mb-0 mt-0 text-uppercase">
                        <Lang textKey="schoolOverTime" />
                      </h2>
                      <div className="pt-1em pb-1em">
                        <p className={styles.historyDescription}>
                          <Lang textKey="scoreHistoryDescription" />
                        </p>
                      </div>
                      <div
                        className={`${styles.tourStep} __school_profile_3__`}>
                        <TotalScoreHistory />
                      </div>
                    </div>
                    <SummaryMetrics
                      className={`mt-1em ${styles.tourStep} __school_profile_4__`}
                    />
                  </div>
                </Tab>
                {('elementary-school' === group || 'combo' === group) && (
                  <Tab tabKey="elementary" translationKey="elementaryTabLabel">
                    <AccordionsContextProvider
                      value={elementaryAccordionsContextValue}>
                      <ElementarySchoolMetrics />
                    </AccordionsContextProvider>
                  </Tab>
                )}
                {('high-school' === group || 'combo' === group) && (
                  <Tab tabKey="highSchool" translationKey="highSchoolTabLabel">
                    <AccordionsContextProvider
                      value={highSchoolAccordionsContextValue}>
                      <HighSchoolMetrics />
                    </AccordionsContextProvider>
                  </Tab>
                )}
              </Tabs>
            </ActiveGridContext.Provider>
          </Column>
        </Row>
      </SchoolContext.Provider>
      <Footer />
    </>
  );
};

//@ts-ignore
SchoolProfile.whyDidYouRender = true;

export default SchoolProfile;
