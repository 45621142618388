import React, { FC } from 'react';
import PropTypes from 'prop-types';

// Context
import { useLanguageContext } from '../../context/LanguageContext';

// Services
import { langKeyMap } from '../LangContextProvider';

// Utilities
import { get } from 'lodash';

interface OptionWithLangProps {
  value: string | number;
  textKey: string;
  interpolate?: (value: string) => React.ReactNode;
}

/**
 * Edge case for embedding translated text in <option> elements
 */
const OptionWithLang: FC<OptionWithLangProps> = ({
  value,
  textKey,
  interpolate,
}) => {
  const { lang, translationValues } = useLanguageContext();
  const langKey = get(langKeyMap, lang);

  return (
    <option value={value}>
      {typeof interpolate === 'function'
        ? interpolate(get(translationValues, [textKey, langKey]))
        : get(translationValues, [textKey, langKey])}
    </option>
  );
};

OptionWithLang.propTypes = {
  value: PropTypes.string.isRequired,
  textKey: PropTypes.string.isRequired,
  interpolate: PropTypes.func,
};

export default OptionWithLang;
