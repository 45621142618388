import { useCallback } from 'react';
import { ACTIONS, CallBackProps, EVENTS, LIFECYCLE } from 'react-joyride';

// Utilities
import { isArray } from 'lodash';

// Types
import { TourCallBackDependencies, TourStep } from '../types';

const useTourCallback = (deps: TourCallBackDependencies) => {
  const {
    setRunTour,
    dispatch,
    setStepIndex,
    onFinish = () => {},
    onStart = () => {},
    onInit = () => {},
  } = deps;

  return useCallback(
    (props: CallBackProps) => {
      const { action, lifecycle, index, type } = props;
      const step = props.step as TourStep;

      switch (action) {
        case ACTIONS.INIT:
          onInit();
          break;
        case ACTIONS.START:
          onStart();
          break;
        case ACTIONS.CLOSE:
          // On 'CLOSE' is purposely the same as on 'TOUR_END'
          onFinish();
          break;
        case ACTIONS.NEXT:
          if (lifecycle === LIFECYCLE.COMPLETE) {
            // dispatch action to change props for next tour step
            // action defaults to null
            const { beforeNext } = step;

            if (beforeNext) {
              if (isArray(beforeNext)) {
                beforeNext.forEach(dispatch => {
                  const { dispatcher, action } = dispatch;

                  dispatcher(action);
                });
              } else {
                dispatch(beforeNext);
              }
            }

            // increment the tour step index
            setStepIndex(index + 1);
          }

          // allow page to scroll, then start tour again
          if (type === EVENTS.STEP_BEFORE) {
            setRunTour(false);
            const { scrollDebounce } = step;

            setTimeout(() => setRunTour(true), scrollDebounce);
          } else if (type === EVENTS.TOUR_END) {
            // check if tour is over, then fire onFinish prop function
            onFinish();
          }

          break;
        case ACTIONS.PREV:
          if (lifecycle === LIFECYCLE.COMPLETE) {
            // dispatch action to change props for the prev tour step
            // action defaults to null
            const { beforePrev } = step;

            if (beforePrev) {
              if (isArray(beforePrev)) {
                beforePrev.forEach(dispatch => {
                  const { dispatcher, action } = dispatch;

                  dispatcher(action);
                });
              } else {
                dispatch(beforePrev);
              }
            }

            // decrement the tour step index
            setStepIndex(index - 1);
          }
          break;
        case ACTIONS.STOP:
          // stop for debounce
          break;
        default:
        // console.warn('unhandled joyride action');
      }
    },
    [setRunTour, dispatch, setStepIndex, onFinish, onInit, onStart]
  );
};

export default useTourCallback;
