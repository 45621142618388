import { useState, useEffect } from 'react';

// Utilities
import { isEmpty } from 'lodash';
import { GoogleMapsMap, Options } from './types';

const useGoogleMap = (options: Options) => {
  const [map, setMap] = useState<GoogleMapsMap | {}>({});

  useEffect(() => {
    if (!isEmpty(window.google)) {
      const initializeMap = (options: Options) => {
        const { containerID, ...restOfOptions } = options;
        const google = window.google;
        const mapContainer = document.getElementById(
          containerID
        ) as HTMLElement;

        return new google.maps.Map(mapContainer, restOfOptions);
      };

      const map = initializeMap(options);

      setMap(map);
    }
  }, [setMap, options]);

  return map;
};

export default useGoogleMap;
