import React from 'react';

// Components
import Lang from '../../components/Lang';
import ToggleLang from '../../components/ToggleLang';

// Types
import { TourStep } from '../../components/Tour/types';

// Utilities
import { createTourSteps } from '../../services/tourSteps';

// The following are the Tour steps for the `/compare` route
const compareTourSteps: TourStep[] = [
  {
    title: <Lang textKey="compareTourStepOneTitle" />,
    content: (
      <div>
        <ToggleLang className="rounded-button">
          <Lang textKey="toggleLanguage" />
        </ToggleLang>
        <p>
          <Lang textKey="compareTourStepOneDescription" />
        </p>
      </div>
    ),
    target: '.__compare_1__',
  },
];

// The following are the Tour steps for the `/compare/:group?` route
const compareDetailsTourSteps: TourStep[] = [
  {
    title: <Lang textKey="compareDetailsTourStepOneTitle" />,
    content: (
      <div>
        <ToggleLang className="rounded-button">
          <Lang textKey="toggleLanguage" />
        </ToggleLang>
        <p>
          <Lang textKey="compareDetailsTourStepOneDescription" />
        </p>
      </div>
    ),
    target: '.__compare_details_1__',
    placement: 'top',
  },
  {
    title: <Lang textKey="compareDetailsTourStepTwoTitle" />,
    content: <Lang textKey="compareDetailsTourStepTwoDescription" />,
    target: '.__compare_details_2__',
    placement: 'top',
  },
];

export default {
  compareTourSteps: createTourSteps(compareTourSteps),
  compareDetailsTourSteps: createTourSteps(compareDetailsTourSteps),
};
