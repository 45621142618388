/*
function calls to mixpanel library for logging events
*/
import mixpanel from 'mixpanel-browser';
import { kebabCase, startCase } from 'lodash';

mixpanel.init('22aea8156da99b226e9f2f9d66c828cb');

interface MixpanelProperties {
  action: 'click' | 'selection' | 'page visit' | 'scroll';
  trigger: string;
  language: string;
  description: string;
  location: string;
}

/**
 * This function will be used to register/track any custom events
 */
export const trackCustomEvent = (
  name: string,
  properties: MixpanelProperties
) => {
  mixpanel.track(name, properties);
};

export const trackAdvancedFilterUse = (
  filterKey: string,
  trigger: string,
  language: string,
  parentKey?: string
) => {
  const eventKey = startCase(kebabCase(filterKey).replace('-', ' ')).replace(
    'Cte',
    'CTE'
  );
  const eventName = eventKey + ' Filter Used';
  let eventTrigger = trigger;

  if (parentKey) {
    eventTrigger =
      startCase(parentKey).replace('Cte', 'CTE') + ': ' + eventTrigger;
  }

  trackCustomEvent(eventName, {
    action: 'click',
    trigger: eventTrigger,
    language,
    description: `${eventTrigger} was selected`,
    location: 'Map advanced filters',
  });
};

export function trackGridOpen(metric: string) {
  mixpanel.track('metric card opened', {
    cellClicked: metric,
  });
}

export function trackFinishedSearch(formState: any, searchResultClicked: any) {
  mixpanel.track('search finished', {
    ...formState,
    searchResultClicked,
  });
}

export function trackHomeMenuOpen(cardClicked: string) {
  mixpanel.track('homepage section opened', {
    cardClicked,
  });
}

export function registerGeolocation(pos: any, address?: string) {
  const opts = {
    ...pos,
    address: address || '',
  };

  mixpanel.register(opts);
}

export function trackAddToCompare(schoolIDAdded: string) {
  mixpanel.track('Added School To Compare', {
    schoolAdded: schoolIDAdded,
  });
}

export const trackAccordionOpened = (accordion: string) => {
  mixpanel.track('Accordion Opened', {
    accordion,
  });
};
