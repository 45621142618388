import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface ScrollToTopProps {}

/**
 * This component resolves react-router not inherently scrolling to the top of the page
 * when visiting a new route
 *
 * @see: https://reacttraining.com/react-router/web/guides/scroll-restoration
 */
const ScrollToTop: FC<ScrollToTopProps> = props => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{props.children}</>;
};

export default ScrollToTop;
