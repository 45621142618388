import { useEffect } from 'react';

// context
import { useStoreValue } from '../../../../../context/StoreContext';

// Utilities
import { isEmpty } from 'lodash';

// Types
import { GoogleMapsMap } from '../../../../../components/GoogleMap/types';
import { SearchLocationProps } from '../types';

const useSearchLocation = (props: SearchLocationProps) => {
  const { map: mapState } = useStoreValue();
  const [state] = mapState;
  const map = state.map as GoogleMapsMap;
  const google = window.google || {};

  useEffect(() => {
    let autocompleteListener: google.maps.MapsEventListener,
      searchBox: google.maps.places.SearchBox;

    if (!isEmpty(map)) {
      let northEastBounds, southWestBounds;
      const input = document.getElementById(props.id) as HTMLInputElement;

      // Create the search box and link it to the UI element.
      searchBox = new google.maps.places.SearchBox(input);
      const mapBounds = (map as GoogleMapsMap).getBounds() as google.maps.LatLngBounds;

      // Map may NOT YET be fully initialized
      // Map's bounds are `null` or `undefined` when not yet initialized
      if (isEmpty(mapBounds)) {
        const mapBounds = {
          southWest: { lat: 41.4413491, lng: -88.33188 },
          northEast: { lat: 42.262796, lng: -87.232802 },
        };

        southWestBounds = mapBounds.southWest;
        northEastBounds = mapBounds.northEast;
      } else {
        northEastBounds = (mapBounds as google.maps.LatLngBounds)
          .getNorthEast()
          .toJSON();
        southWestBounds = (mapBounds as google.maps.LatLngBounds)
          .getSouthWest()
          .toJSON();
      }

      const bounds = new google.maps.LatLngBounds(
        southWestBounds,
        northEastBounds
      );

      searchBox.setBounds(bounds);

      // `places_changed` gets fired when a user selects a location from the dropdown in the UI
      autocompleteListener = searchBox.addListener('places_changed', () => {
        const place = searchBox.getPlaces()[0];
        const { geometry, formatted_address } = place;

        if ('undefined' === typeof geometry) {
          return;
        }

        if ('undefined' === typeof formatted_address) {
          return;
        }

        const mapInstance = map as GoogleMapsMap;

        const { location } = geometry;
        const pos = { lat: location.lat(), lng: location.lng() };

        props.onPlacesChanged({
          pos,
          short_address: formatted_address.split(',')[0],
          map: mapInstance,
        });
      });
    }

    return () => {
      // clean up objects and listeners from autocomplete

      if (!isEmpty(searchBox) && !isEmpty(autocompleteListener)) {
        google.maps.event.clearInstanceListeners(searchBox);
      }
    };
  }, [props, map, google]);
};

export default useSearchLocation;
