import React, { FC, SyntheticEvent } from 'react';

// Utils
import { get, isFunction, uniqueId } from 'lodash';

// Styles
import styles from '../../map.module.scss';

// Types
import { SearchProps } from './types';

const Search: FC<SearchProps> = props => {
  const {
    onSearchChanged,
    searchQuery = '',
    className,
    onFocus = () => {},
  } = props;
  const elementId = uniqueId('search-field');

  const onNameSearch = (event: SyntheticEvent) => {
    const searchQuery = get(event.target, 'value', '');

    if (isFunction(onSearchChanged)) {
      onSearchChanged(event, searchQuery);
    }
  };

  return (
    <div className={`${styles.inputWrapper} ${className}`}>
      <label htmlFor={elementId}>
        <i className="fa fa-fw fa-search mr-5em" aria-hidden="true" />
      </label>
      <input
        id={elementId}
        placeholder="Type a School Name"
        onChange={onNameSearch}
        value={searchQuery}
        onFocus={onFocus}
      />
    </div>
  );
};

Search.defaultProps = {
  onSearchChanged: () => {},
  searchQuery: '',
  className: '',
};

export default Search;
