import { useCallback } from 'react';
import { get } from 'lodash';
import { useLanguageContext } from '../context/LanguageContext';

const useGetTranslationFunc = () => {
  const { translationValues } = useLanguageContext();

  return useCallback(
    (langKey: string, language = 'en') => {
      return get(translationValues, [langKey, language], '');
    },
    [] //eslint-disable-line
  );
};

export default useGetTranslationFunc;
