import { createContext, useContext } from 'react';

// Types
import { Translations } from '../hooks/useCSVTranslations';
import { SetStateFunction } from '../types';

export interface LangContextValue {
  lang: string;
  setLang: SetStateFunction<string>;
  translationValues: Translations;
}

const LangContext = createContext<LangContextValue>({
  lang: 'en-US',
  setLang: () => {},
  translationValues: {},
});

export const useLanguageContext = () => useContext(LangContext);

export default LangContext;
