import React, { useState, FC } from 'react';
import ReactDOMServer from 'react-dom/server';

// Context
import { useSchoolContext } from '../../../context/SchoolContext';

// Components
import GoogleMap from '../../../components/GoogleMap';
import Marker from '../../../components/GoogleMap/Marker';
import InfoWindow from '../../../components/GoogleMap/InfoWindow';
import Block from '../../../components/Block';
import MainContent from '../../../components/Block/MainContent';
import Lang from '../../../components/Lang';
import FooterContent from '../../../components/Block/FooterContent';
import LinkWithTracking from '../../../components/LinkWithTracking';

// Utilities
import { get, isEmpty, last } from 'lodash';
import getRatingLogo from '../../../components/GoogleMap/utils/getRatingLogo';
import utilities from '../../../services/utilities';

// Styles
import profileStyles from '../profile.module.scss';
import mapStyles from '../../Map/map.module.scss';

const trackEventProps = {
  mixpanelEventName: 'School Profile Sidebar Link Clicked',
  description: 'User clicked a link inside of the sidebar of a school profile',
  location: 'School profile sidebar',
};

const SchoolSidebar: FC = props => {
  const schoolData = useSchoolContext();
  const [showInfoWindow, setShowInfoWindow] = useState(false);

  const schoolID = get(schoolData, 'school_id');
  const long_name = get(schoolData, 'long_name');
  const grades_served = get(schoolData, 'grades_served');
  const enrollment = get(schoolData, 'enrollment');
  const address = get(schoolData, 'address');
  const group = get(schoolData, 'group');
  const community = get(schoolData, 'community', '');
  const community_group = get(schoolData, 'community_group', '');
  const cps_school_profile = get(schoolData, 'cps_school_profile', '');
  const website = get(schoolData, 'website', '');
  const school_hours = get(schoolData, 'school_data');
  const programs = get(schoolData, 'program', []) as {
    program: string;
    program_specialty: string;
  }[];
  const ctePrograms = get(schoolData, 'cte_program', []) as {
    cte_program: string;
    program_specialty: string;
  }[];

  const lat = utilities.getSchoolLatitude(schoolData);
  const lng = utilities.getSchoolLongitude(schoolData);
  const accountability_status = utilities.getAccountabilityStatus(schoolData);

  const name = get(schoolData, 'school_name', get(schoolData, 'long_name'));
  const status = get(last(accountability_status), 'value', '');

  const allGeneralPrograms = programs
    .reduce<string[]>((acc, program) => {
      let programGroup = get(program, 'program', '');
      const programSpecialty = get(program, 'program_specialty', '');

      if (!isEmpty(programSpecialty)) {
        programGroup = `${programGroup} - ${programSpecialty}`;
      }

      return [...acc, programGroup];
    }, [])
    .filter((program, index, programs) => index === programs.indexOf(program));

  const allCTEPrograms = ctePrograms.reduce<string[]>((acc, program) => {
    let programGroup = get(program, 'cte_program', '').replace('CTE: ', '');
    const programSpecialty = get(program, 'program_specialty', '');

    if (!isEmpty(programSpecialty)) {
      programGroup = `${programGroup} - ${programSpecialty}`;
    }

    return [...acc, programGroup];
  }, []);

  const markerIcon = {
    url: getRatingLogo(utilities.getMostRecentSQRPRating(schoolData)),
    scaledSize: new window.google.maps.Size(35, 39),
  };

  const schoolLink = (
    <a
      className={mapStyles.removeBasicLinkStyles}
      href={`/${group}/${schoolID}`}>
      {name}
    </a>
  );

  const renderStatus = (status: string) => {
    if (isEmpty(status)) {
      return;
    }

    if (
      'Intensive Support' !== status &&
      'Provisional Support' !== status &&
      'Charter Warning List' !== status
    ) {
      return;
    }

    return (
      <p style={{ color: '#F8000C' }}>
        <i className="fa fa-exclamation-triangle" />{' '}
        <Lang textKey="accountabilityStatus" values={[status]} />{' '}
      </p>
    );
  };

  let infoWindowContent = `<div>
              <h3 class="mt-0 mb-25em">${ReactDOMServer.renderToString(
                schoolLink
              )}</h3>
          <p class="${
            mapStyles.schoolDetail
          }">Grades Served: ${grades_served}</p>
          <p class="${mapStyles.schoolDetail}">${enrollment} Students</p>
          <p class="${mapStyles.schoolDetail}">${address}</p>
          </div>`;

  const mapCenter = { lat, lng };

  return (
    <div className="schoolSidebar">
      <div>
        <GoogleMap center={mapCenter} mapHeight={250}>
          <Marker
            {...{
              lat,
              lng,
              icon: markerIcon,
              onClick: () => setShowInfoWindow(show => !show),
            }}>
            <InfoWindow
              showInfoWindow={showInfoWindow}
              infoWindowContent={infoWindowContent}
              {...{
                onCloseclick: () => setShowInfoWindow(show => !show),
              }}
            />
          </Marker>
        </GoogleMap>
      </div>
      <Block>
        <MainContent>
          <h2>{name}</h2>
          <h3>{long_name}</h3>
          <h4>{address}</h4>
          <h4>
            <LinkWithTracking
              href={website}
              langKey="schoolWebsite"
              mixpanelEventName={trackEventProps.mixpanelEventName}
              description={trackEventProps.description}
              location={trackEventProps.location}
              target="_blank"
            />
          </h4>
          <h4>
            <LinkWithTracking
              href={`https://www.google.com/maps/dir//${lat},${lng}`}
              langKey="getDirections"
              mixpanelEventName={trackEventProps.mixpanelEventName}
              description={trackEventProps.description}
              location={trackEventProps.location}
              target="_blank"
            />
          </h4>
          {renderStatus(status)}
        </MainContent>
      </Block>
      <Block>
        <MainContent>
          <h4>
            <Lang textKey="whatIsSQRP" />
          </h4>
          <p>
            <Lang textKey="whatIsSQRPDescription" />
          </p>
        </MainContent>
        <FooterContent isFooterLink>
          <LinkWithTracking
            href="https://kidsfirstchicago.org/sqrp"
            langKey="viewProfile"
            mixpanelEventName="School Profile Sidebar Link Clicked"
            description="User clicked a link inside of the sidebar of a school profile"
            location="School profile sidebar"
            target="_blank"
          />
        </FooterContent>
      </Block>
      <Block>
        <MainContent>
          <h4>
            <Lang textKey="communityFilter" />
          </h4>
          <p>
            {community}
            <br />({community_group})
          </p>
        </MainContent>
      </Block>
      <Block>
        <MainContent>
          <h4>
            <Lang textKey="schoolTime" />
          </h4>
          <p>{school_hours}</p>
        </MainContent>
        <FooterContent>
          <p>
            <Lang textKey="schoolTimeFooterText" />
          </p>
        </FooterContent>
      </Block>
      <Block>
        <MainContent>
          <h4>
            <Lang textKey="programsFilter" />
          </h4>
          <p>
            <Lang textKey="schoolProfileProgramsDescription" />
          </p>
          {!isEmpty(allGeneralPrograms) && (
            <>
              <br />
              <h4>
                <strong>General Programs</strong>
              </h4>
              <p>
                {allGeneralPrograms.map((program, index) => (
                  <span key={index} className={profileStyles.program}>
                    {program}
                  </span>
                ))}
              </p>
            </>
          )}
          {!isEmpty(allCTEPrograms) && (
            <>
              <br />
              <h4>
                <strong>CTE Programs (HS)</strong>
              </h4>
              <p>
                {allCTEPrograms.map((program, index) => (
                  <span key={index} className={profileStyles.program}>
                    {program}
                  </span>
                ))}
              </p>
            </>
          )}
        </MainContent>
      </Block>
      <Block>
        <MainContent>
          <h4>
            <Lang textKey="howCanIEnroll" />
          </h4>
          <p>
            <Lang textKey="howCanIEnrollDescription" />
          </p>
        </MainContent>
        <FooterContent isFooterLink>
          <LinkWithTracking
            href="https://guide.kidsfirstchicago.org/enrollment_resources"
            langKey="learnMore"
            mixpanelEventName={trackEventProps.mixpanelEventName}
            description={trackEventProps.description}
            location={trackEventProps.location}
            target="_blank"
          />
        </FooterContent>
      </Block>
      <Block>
        <MainContent>
          <h4>
            <Lang textKey="cpsProfile" />
          </h4>
          <p>
            <Lang textKey="cpsProfileDescription" />
          </p>
        </MainContent>
        <FooterContent isFooterLink>
          <LinkWithTracking
            href={`https://www.cps.edu/schools/schoolprofiles/${schoolID}`}
            langKey="viewProfile"
            mixpanelEventName={trackEventProps.mixpanelEventName}
            description={trackEventProps.description}
            location={trackEventProps.location}
            target="_blank"
          />
        </FooterContent>
      </Block>
    </div>
  );
};

export default SchoolSidebar;
