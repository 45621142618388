import React from 'react';

// Context
import { useAccordionsContext } from '../../../context/AccordionsContext';

// Reducers
import {
  CLOSE_ALL,
  EXPAND_ALL,
  TOGGLE_ACCORDION,
} from '../../../reducers/accordions';

// Components
import Lang from '../../../components/Lang';
import AccordionTab from '../../../components/AccordionTab';
import {
  MetricElement,
  MetricRow,
  MetricGrowthAttainmentElement,
} from './MetricGrid';

// Styles
import styles from '../profile.module.scss';

const ElementarySchoolMetrics = () => {
  const { state, dispatch } = useAccordionsContext();

  const { accordions } = state;

  const areAnyAccordionsExpanded =
    Object.keys(accordions).filter(accordion => accordions[accordion]).length >
    0;

  const onAccordionsToggle = (areAccordionsExpanded: boolean) => () => {
    const actionType = areAccordionsExpanded ? CLOSE_ALL : EXPAND_ALL;

    dispatch({ type: actionType });
  };

  return (
    <div>
      <h2 className="text-uppercase">
        <Lang textKey="elementaryMetricsTitle" />
      </h2>
      <p>
        <Lang textKey="elementaryMetricsDescription" />
      </p>
      <div className={styles.accordionsToggler}>
        <span onClick={onAccordionsToggle(areAnyAccordionsExpanded)}>
          <span>
            {areAnyAccordionsExpanded ? (
              <Lang textKey="closeAccordions" />
            ) : (
              <Lang textKey="expandAccordions" />
            )}
          </span>
          <i
            className={`fa fa-fw fa-chevron-${
              areAnyAccordionsExpanded ? 'up' : 'down'
            }`}
          />
        </span>
      </div>
      <AccordionTab
        className={styles.schoolProfileAccordion}
        headerTextKey="studentGrowthVersusAttainment"
        headerWrapperClassName={styles.accordionWrapper}
        headerClassName={styles.accordionHeader}
        overlayClassName={styles.overlayBackground}
        showCloseButton
        handleClick={() =>
          dispatch({
            type: TOGGLE_ACCORDION,
            payload: 'growthVersusAttainment',
          })
        }
        isOpen={accordions['growthVersusAttainment']}>
        <p>
          <em>
            <Lang textKey="schoolAttainmentGrowthMetricDescIntro" />
          </em>
        </p>
        <p>
          <em>
            <Lang textKey="schoolAttainmentGrowthMetricDescOutro" />
          </em>
        </p>
        <MetricRow>
          <MetricGrowthAttainmentElement
            langKey="elementaryReadingGrowthTitle"
            left
            dataKey="national_school_growth_percentile_reading">
            {({ score, colorClass, isSelected }) => (
              <>
                <div className={styles.elementHighlight}>
                  <h5>
                    <Lang textKey="elementaryReadingGrowthTitle" />
                  </h5>
                  <p className={colorClass}>{score}</p>
                </div>
                <div className={styles.detailArrowWrapper}>
                  <span>
                    <Lang textKey="details" />
                  </span>
                  <i
                    className={'fa fa-chevron-' + (isSelected ? 'up' : 'down')}
                  />
                </div>
              </>
            )}
          </MetricGrowthAttainmentElement>
          <MetricGrowthAttainmentElement
            langKey="elementaryReadingAttainmentTitle"
            dataKey="national_school_attainment_%ile_reading_grades_3_8"
            metricType="attainment">
            {({ score, colorClass, isSelected }) => (
              <>
                <div className={styles.elementHighlight}>
                  <h5>
                    <Lang textKey="elementaryReadingAttainmentTitle" />
                  </h5>
                  <p className={colorClass}>{score}</p>
                </div>
                <div className={styles.detailArrowWrapper}>
                  <span>
                    <Lang textKey="details" />
                  </span>
                  <i
                    className={'fa fa-chevron-' + (isSelected ? 'up' : 'down')}
                  />
                </div>
              </>
            )}
          </MetricGrowthAttainmentElement>
        </MetricRow>
        <MetricRow>
          <MetricGrowthAttainmentElement
            langKey="elementaryMathGrowthTitle"
            left
            dataKey="national_school_growth_percentile_math">
            {({ score, colorClass, isSelected }) => (
              <>
                <div className={styles.elementHighlight}>
                  <h5>
                    <Lang textKey="elementaryMathGrowthTitle" />
                  </h5>
                  <p className={colorClass}>{score}</p>
                </div>
                <div className={styles.detailArrowWrapper}>
                  <span>
                    <Lang textKey="details" />
                  </span>
                  <i
                    className={'fa fa-chevron-' + (isSelected ? 'up' : 'down')}
                  />
                </div>
              </>
            )}
          </MetricGrowthAttainmentElement>
          <MetricGrowthAttainmentElement
            langKey="elementaryMathAttainmentTitle"
            dataKey="national_school_attainment_%ile_math_grades_3_8"
            metricType="attainment">
            {({ score, colorClass, isSelected }) => (
              <>
                <div className={styles.elementHighlight}>
                  <h5>
                    <Lang textKey="elementaryMathAttainmentTitle" />
                  </h5>
                  <p className={colorClass}>{score}</p>
                </div>
                <div className={styles.detailArrowWrapper}>
                  <span>
                    <Lang textKey="details" />
                  </span>
                  <i
                    className={'fa fa-chevron-' + (isSelected ? 'up' : 'down')}
                  />
                </div>
              </>
            )}
          </MetricGrowthAttainmentElement>
        </MetricRow>
        <MetricRow>
          <MetricElement
            langKey="percentOfStudentsMeetingNationalAverage"
            dataKey="percent_of_students_meetingexceeding_national_average_growth_norms">
            <h5>
              <Lang textKey="percentOfStudentsMeetingNationalAverage" />
            </h5>
          </MetricElement>
        </MetricRow>
      </AccordionTab>
      <AccordionTab
        className={`${styles.schoolProfileAccordion} __school_profile_5__`}
        headerTextKey="studentsOnStandardizedTests"
        headerWrapperClassName={styles.accordionWrapper}
        headerClassName={styles.accordionHeader}
        overlayClassName={styles.overlayBackground}
        showCloseButton
        handleClick={() =>
          dispatch({ type: TOGGLE_ACCORDION, payload: 'priorityGroups' })
        }
        isOpen={accordions['priorityGroups']}>
        <MetricRow>
          <MetricElement
            langKey="africanAmericanGrowthPercentileMath"
            className={`__school_profile_6__`}
            left
            dataKey="african_american_growth_percentile_math">
            <h5>
              <Lang textKey="africanAmericanGrowthPercentileMath" />
            </h5>
          </MetricElement>
          <MetricElement
            langKey="africanAmericanGrowthPercentileReading"
            dataKey="african_american_growth_percentile_reading">
            <h5>
              <Lang textKey="africanAmericanGrowthPercentileReading" />
            </h5>
          </MetricElement>
        </MetricRow>
        <MetricRow>
          <MetricElement
            langKey="diverseLearnerGrowthPercentileMath"
            left
            dataKey="diverse_learner_growth_percentile_math">
            <h5>
              <Lang textKey="diverseLearnerGrowthPercentileMath" />
            </h5>
          </MetricElement>
          <MetricElement
            langKey="diverseLearnerGrowthPercentileReading"
            dataKey="diverse_learner_growth_percentile_reading">
            <h5>
              <Lang textKey="diverseLearnerGrowthPercentileReading" />
            </h5>
          </MetricElement>
        </MetricRow>
        <MetricRow>
          <MetricElement
            langKey="englishLearnerGrowthPercentileMath"
            left
            dataKey="english_learner_growth_percentile_math">
            <h5>
              <Lang textKey="englishLearnerGrowthPercentileMath" />
            </h5>
          </MetricElement>
          <MetricElement
            langKey="englishLearnerGrowthPercentileReading"
            dataKey="english_learner_growth_percentile_reading">
            <h5>
              <Lang textKey="englishLearnerGrowthPercentileReading" />
            </h5>
          </MetricElement>
        </MetricRow>
        <MetricRow>
          <MetricElement
            langKey="hispanicGrowthPercentileMath"
            left
            dataKey="hispanic_growth_percentile_math">
            <h5>
              <Lang textKey="hispanicGrowthPercentileMath" />
            </h5>
          </MetricElement>
          <MetricElement
            langKey="hispanicGrowthPercentileReading"
            dataKey="hispanic_growth_percentile_reading">
            <h5>
              <Lang textKey="hispanicGrowthPercentileReading" />
            </h5>
          </MetricElement>
        </MetricRow>
      </AccordionTab>
      <AccordionTab
        className={styles.schoolProfileAccordion}
        headerTextKey="studentsAttendance"
        headerWrapperClassName={styles.accordionWrapper}
        headerClassName={styles.accordionHeader}
        overlayClassName={styles.overlayBackground}
        showCloseButton
        handleClick={() =>
          dispatch({ type: TOGGLE_ACCORDION, payload: 'otherIndicators' })
        }
        isOpen={accordions['otherIndicators']}>
        <MetricRow>
          <MetricElement
            langKey="averageDailyAttendanceRate"
            left
            dataKey="average_daily_attendance_rate_grades_k_8">
            <h5>
              <Lang textKey="averageDailyAttendanceRate" />
            </h5>
          </MetricElement>
          <MetricElement
            langKey="myVoiceMySchoolSurvey"
            dataKey="my_voice_my_school_5_essentials_survey">
            <h5>
              <Lang textKey="myVoiceMySchoolSurvey" />
            </h5>
          </MetricElement>
        </MetricRow>
      </AccordionTab>
      <div className={styles.accordionsToggler}>
        <span onClick={onAccordionsToggle(areAnyAccordionsExpanded)}>
          <span>
            {areAnyAccordionsExpanded ? (
              <Lang textKey="closeAccordions" />
            ) : (
              <Lang textKey="expandAccordions" />
            )}
          </span>
          <i className="fa fa-fw fa-chevron-down" />
        </span>
      </div>
    </div>
  );
};

export default ElementarySchoolMetrics;
