import { useEffect, useReducer } from 'react';

// Reducers
import { mapDefaultState, schoolReducer, SET } from '../reducers/map';

// API
import axios from '../api';

// Utilities
import { get, isEmpty } from 'lodash';
import utilities from '../services/utilities';
import {
  Filter,
  FilterGroup,
  MapContext,
  MapDispatch,
  MapReducer,
  MapState,
} from '../reducers/map/types';

/**
 * Gives a functional React component a stateful schoolData record for all schools.
 *
 * @param {Function} reducer
 * @param {Object} defaultState
 *
 * @returns {null | array} school data records for all schools
 */
const useAllSchoolData = (
  { reducer, defaultState }: { reducer: MapReducer; defaultState: MapState } = {
    reducer: schoolReducer,
    defaultState: mapDefaultState,
  }
): MapContext => {
  const [schoolData, dispatch] = useReducer(reducer, defaultState);

  useEffect(() => {
    const getAllSchools = async () => {
      const { data: serverResponse } = await axios.get('/schools');
      const { success: schoolSuccess, data: schoolData } = serverResponse;

      if (!schoolSuccess) {
        console.error('Error has occurred when retrieving schools');
      }

      const data = schoolData;

      data.forEach((school: any) => {
        let lat = utilities.getSchoolLatitude(school);
        let lng = utilities.getSchoolLongitude(school);
        school['school_latitude'] = lat;
        school['school_longitude'] = lng;
      });

      const { data: listServerResponse } = await axios.get('/lists');
      const { success: listsSuccess, data: listsData } = listServerResponse;

      if (!listsSuccess) {
        console.error('Error has occurred when retrieving lists');
      }

      let { communities, types, programs, cte_programs } = listsData;

      const schoolTypes = types.reduce(
        (acc: FilterGroup, schoolType: string) => {
          const key = utilities.createObjectKey(schoolType);

          return {
            ...acc,
            [key]: {
              name: key,
              label: schoolType,
              value: key,
              isChecked: false,
            },
          };
        },
        {}
      );

      const communityAreas = communities.reduce(
        (acc: FilterGroup, community: any) => {
          const name: string = get(community, '_id', '');
          const children = get(community, 'children', []);
          const key = utilities.createObjectKey(name);

          let communityArea: Filter = {
            name: key,
            label: name,
            value: key,
            isChecked: false,
          };

          if (!isEmpty(children)) {
            const subCommunityAreas: Filter[] = children.reduce(
              (acc: Filter[], community: any) => {
                const name: string = get(community, 'child', '');
                const key = utilities.createObjectKey(name);

                return [
                  ...acc,
                  { name: key, value: key, label: name, isChecked: false },
                ];
              },
              []
            );

            communityArea = { ...communityArea, children: subCommunityAreas };
          }

          return { ...acc, [key]: communityArea };
        },
        {}
      );

      /**
       * Construct the CTE Programs filters for the frontend user
       *
       * Data received:
       *
       *    [ {_id: "CTE: Information Technology", children: [ {program_specialty: "Cisco Networking"}, ...]}, ... ]
       *
       * Object returned:
       *
       *    { 'information-technology': {..., children: [ {name: 'cisco-networking', ... }, ...] }, ...}
       *
       */
      const programsReducer = (acc: FilterGroup, programGroup: any) => {
        const name: string = get(programGroup, '_id', '');
        const children: any[] = get(programGroup, 'children', []);
        const label: string = name.replace('CTE: ', '');
        const fieldName = utilities.createObjectKey(`program-${name}`);
        const key = utilities.createObjectKey(name);

        let program: Filter = {
          name: fieldName,
          value: key,
          label: label,
          isChecked: false,
        };

        if (!isEmpty(children)) {
          const subPrograms = children
            .filter(
              (program: any) => !isEmpty(get(program, 'program_specialty', ''))
            )
            .reduce((acc: Filter[], program: any) => {
              const name: string = get(program, 'program_specialty', '');
              const fieldName = utilities.createObjectKey(`program-${name}`);
              const key = utilities.createObjectKey(name);

              return [
                ...acc,
                { name: fieldName, value: key, label: name, isChecked: false },
              ];
            }, []);

          program = { ...program, children: subPrograms };
        }

        return { ...acc, [fieldName]: program };
      };

      const ctePrograms: FilterGroup = cte_programs.reduce(programsReducer, {});
      programs = programs.reduce(programsReducer, {});

      const typedDispatch = dispatch as MapDispatch;

      typedDispatch({
        type: SET,
        payload: {
          schoolData: data,
          schoolTypes,
          communityAreas,
          ctePrograms,
          programs,
        },
      });
    };

    try {
      getAllSchools();
    } catch {
      console.error('Error fetching school data for all schools');
    }
  }, [dispatch]);

  return [schoolData, dispatch];
};

export default useAllSchoolData;
