import React, { FC } from 'react';
import { StoreContext, StoreContextValue } from '../context/StoreContext';

/**
 * Redux Store substitute using Context API
 *
 * `store` is a object of 'states' that each have their
 * [state, setState/dispatch] pairing
 *
 *
 * @param {obj} store
 * @param children
 * @returns {*}
 * @constructor
 */
export const Store: FC<{ store: StoreContextValue }> = ({
  store,
  children,
}) => <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
