import React from 'react';
import { GoogleMapsMap } from '../types';

export interface GoogleMapContextValue {
  map: GoogleMapsMap | {};
  google: any;
}

const GoogleMapContext = React.createContext<GoogleMapContextValue>({
  map: {},
  google: {}, // window.google
});

export default GoogleMapContext;
