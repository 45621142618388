import { useEffect, useState } from 'react';

// API
import axios from '../api';

// Utilities
import { MapSchool } from '../reducers/map/types';

export type School = MapSchool;

const useSchoolData = (schoolID: string): School | {} => {
  const [schoolData, setSchoolData] = useState<School | {}>({});

  useEffect(() => {
    const getSchool = async (): Promise<School> => {
      const serverResponse = await axios.get(`/schools/${schoolID}`);
      const { data } = serverResponse;
      let { success } = data;

      if (!success) {
        console.log(serverResponse);
        throw new Error(`Received an unsuccessful server response.`);
      }

      return data.data as School;
    };

    getSchool()
      .then(school => {
        setSchoolData(school);
      })
      .catch(error => {
        console.error('Error fetching school data for ' + schoolID, error);
      });
  }, [schoolID, setSchoolData]);

  return schoolData;
};

export default useSchoolData;
