import React, { ChangeEvent, FC } from 'react';

// Store
import { useStoreSelector } from '../context/StoreContext';

// Components
import Lang from './Lang';

// Reducers
import { types } from '../reducers/compare';

// Utilities
import { get } from 'lodash';

// Styles
import styles from '../views/Compare/compare.module.scss';

interface CompareCheckBoxProps {
  schoolID: string;
  schoolGroup: 'elementary-school' | 'high-school' | 'combo';
  className: string;
}

const CompareCheckBox: FC<CompareCheckBoxProps> = props => {
  const [compareState, dispatchCompare] = useStoreSelector(
    store => store.compare
  );

  const { schoolID, className } = props;
  const schoolGroup = props.schoolGroup as 'elementary-school' | 'high-school';

  const compareArray = get(compareState, schoolGroup, []) as string[];

  let isChecked = false;

  if (compareArray.includes(schoolID)) {
    isChecked = true;
  }

  const disabled = compareArray.length >= 5 && !isChecked;
  const disabledClass = disabled ? styles.disabled : '';

  const onCheckBoxChange = (event: ChangeEvent<HTMLInputElement>) => {
    switch (event.target.checked) {
      case true:
        dispatchCompare({
          type: types.ADD_SCHOOL,
          payload: { schoolID, schoolGroup },
        });
        break;
      case false:
        dispatchCompare({
          type: types.REMOVE_SCHOOL,
          payload: { schoolID, schoolGroup },
        });
        break;
      default:
        console.error('e.target.checked is not a boolean');
    }
  };

  /**
   * The input box below is intentionally NOT a controlled input
   * making this controlled would required a render cycle for each change to the context state
   * this is not necessary if the details below are considered carefully
   * NOTE: the defaultChecked prop does indicate whether it should be checked or not on its initial render
   */
  return (
    <div className={className + ' ' + disabledClass}>
      <input
        id={schoolID}
        style={{ width: '20px' }}
        type="checkbox"
        defaultChecked={isChecked}
        disabled={disabled}
        onClick={e => e.stopPropagation()}
        onChange={onCheckBoxChange}
      />
      <label
        htmlFor={schoolID}
        className={styles.checkboxLabel}
        onClick={e => e.stopPropagation()}>
        <Lang textKey="compareTab" />
      </label>
    </div>
  );
};

export default CompareCheckBox;
