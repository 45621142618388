import React, { FC } from 'react';
import { Link } from 'react-router-dom';

// Store
import { useStoreSelector } from '../context/StoreContext';

// Components
import Lang from './Lang';

// Utils
import { size } from 'lodash';
import utility from '../services/utilities';
import { types } from '../reducers/compare';

// Styles
import styles from '../views/Compare/compare.module.scss';

const groupTextMap = {
  'high-school': 'High Schools',
  'elementary-school': 'Elementary Schools',
};

const ComparePopup: FC = props => {
  // Compare state
  const [compareState, dispatchCompare] = useStoreSelector(
    store => store.compare
  );
  const { showPopup, popupError } = compareState;
  const schoolGroup = compareState.schoolGroup as
    | 'elementary-school'
    | 'high-school';

  // Map state
  const [mapState] = useStoreSelector(store => store.map);
  const { schools: allSchools } = mapState;

  const groupText = groupTextMap[schoolGroup];
  const schools = compareState[schoolGroup];

  let error: React.ReactNode = '';

  if (popupError) {
    error = (
      <div className={styles.error}>
        <Lang textKey="compareMaxError" values={[5, groupText]} />
      </div>
    );
  }

  const num = size(schools);
  let link;
  let message;

  if (num > 1) {
    link = (
      <Link
        to={`/compare/${schoolGroup}`}
        onClick={() => dispatchCompare({ type: types.CLOSE_POPUP })}
        className={`${styles.modalButton} ${styles.invert}`}>
        <Lang textKey="viewComparison" />
      </Link>
    );
  } else {
    message = (
      <p className={styles.message}>
        <Lang textKey="compareAddMessage" values={[2]} />
      </p>
    );
  }

  if (!showPopup) return <></>;

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <h3 className="mt-0 mb-5em">
          <Lang textKey="compareGroups" values={[groupText]} />
        </h3>
        <hr />
        <div className={styles.body}>
          {error}
          <Lang textKey="selectedSchools" />
          {schools.map(school => (
            <div className={styles.school} key={school}>
              <div>{utility.findSchool(school, allSchools, 'name')}</div>
              <div
                onClick={() =>
                  dispatchCompare({
                    type: types.REMOVE_SCHOOL,
                    payload: {
                      schoolID: school,
                      schoolGroup: utility.findSchool(
                        school,
                        allSchools,
                        'group'
                      ) as 'elementary-school' | 'high-school',
                    },
                  })
                }>
                <i className="fa fa-trash" style={{ cursor: 'pointer' }} />
              </div>
            </div>
          ))}
        </div>
        {message}
        <div className={styles.footer}>
          <div
            onClick={() => dispatchCompare({ type: types.CLOSE_POPUP })}
            className={styles.modalButton}>
            <Lang textKey="close" />
          </div>
          {link}
        </div>
      </div>
    </div>
  );
};

export default ComparePopup;
