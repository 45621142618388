import { useEffect } from 'react';

// Utilities
import { isEmpty } from 'lodash';

// Types
import { UserLocation } from '../../../reducers/map/types';

const useSetMarker = (userLocation: UserLocation, map: any) => {
  useEffect(() => {
    if (!isEmpty(map) && !isEmpty(userLocation.marker)) {
      userLocation.marker.setMap(map);
    }
  }, [userLocation.marker, map]);
};

export default useSetMarker;
