import { useState, useEffect } from 'react';
import eventNames from '../../EventNames/infoWindow';

// Utilities
import { isEmpty } from 'lodash';
import handleEvent from '../../utils/handleEvent';
import { InfoWindowProps } from '../index';
import { EventListeners } from '../../types';

const useEventListeners = (
  infoWindow: google.maps.InfoWindow,
  props: InfoWindowProps
) => {
  const [eventListeners, setEventListeners] = useState<EventListeners>({});

  useEffect(() => {
    const listeners = {} as EventListeners;
    let isMounted = true;

    if (!isEmpty(infoWindow) && isEmpty(eventListeners) && isMounted) {
      eventNames.forEach(eventName => {
        const handler = handleEvent(eventName, props, infoWindow);

        listeners[eventName] = {
          handler,
          listener: infoWindow.addListener(eventName, handler),
        };
      });

      setEventListeners(listeners);
    }

    return () => {
      isMounted = false;
    };
  }, [infoWindow, props]); // eslint-disable-line

  return eventListeners;
};

export default useEventListeners;
