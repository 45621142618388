import { useEffect, useState } from 'react';
import eventNames from './EventNames/map';

// Utilities
import { isEmpty } from 'lodash';
import handleEvent from './utils/handleEvent';

// Types
import { EventListeners, GoogleMapProps, GoogleMapsMap } from './types';

const useMapEventListeners = (props: GoogleMapProps, map: GoogleMapsMap) => {
  const [eventListeners, setEventListeners] = useState<EventListeners>({});

  useEffect(() => {
    let listeners = {} as EventListeners;
    const trulyMounted = !isEmpty(map);

    if (trulyMounted) {
      eventNames.forEach(eventName => {
        const handler = handleEvent(eventName, props, map);

        listeners[eventName] = {
          handler,
          listener: window.google.maps.event.addListener(
            map,
            eventName,
            handler
          ),
        };
      });

      setEventListeners(listeners);
    }

    return () => {
      if (!isEmpty(listeners)) {
        Object.keys(listeners).forEach(eventName => {
          window.google.maps.event.removeListener(
            listeners[eventName].listener
          );
        });
      }
    };
  }, [map, setEventListeners]); // eslint-disable-line

  return eventListeners;
};

export default useMapEventListeners;
