// Filters
import { defaultFilters } from './index';

// Utilities
import { get, isEmpty } from 'lodash';
import { MapSchool, ScoreKey } from '../types';

const score = (schools: MapSchool[] = [], filters = defaultFilters) => {
  let schoolMatches = [...schools];
  const { scores: scoreLevels } = filters;

  const currentScores = Object.keys(scoreLevels).filter(
    score => scoreLevels[score as ScoreKey].isChecked
  );

  // For 'SQRP Score' filter type, having ALL or NONE should return the same result.
  // In this case, just return schools unfiltered
  if (
    isEmpty(currentScores) ||
    Object.keys(scoreLevels).length === currentScores.length
  ) {
    return schoolMatches;
  }

  schoolMatches = schoolMatches.filter(school => {
    const score = get(school, ['sqrp_rating', 0, 'value'], '');

    return get(scoreLevels, [score, 'isChecked'], false);
  });

  return schoolMatches;
};

export default score;
