import { FC, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import eventNames from '../EventNames/infoWindow';

// Context
import GoogleMapContext from '../context/GoogleMapContext';

// Custom Hooks
import useInfoWindow from './hooks/useInfoWindow';
import useEventListeners from './hooks/useEventListeners';

// Utilities
import { isEmpty } from 'lodash';
import camelize from '../utils/camelize';
import { GoogleMapsMap } from '../types';

export interface InfoWindowProps {
  marker?: google.maps.Marker;
  infoWindowContent?: React.ReactNode;
  showInfoWindow?: boolean;
  position?: google.maps.LatLng | google.maps.LatLngLiteral;
}

const InfoWindow: FC<InfoWindowProps> = props => {
  const { map } = useContext(GoogleMapContext);
  const infoWindow = useInfoWindow(props) as google.maps.InfoWindow;

  useEventListeners(infoWindow, props);

  const { showInfoWindow, marker } = props;

  useEffect(() => {
    if (!isEmpty(infoWindow) && !isEmpty(marker)) {
      if (showInfoWindow) {
        infoWindow.open(map as GoogleMapsMap, marker);
      } else {
        infoWindow.close();
      }
    }
  }, [showInfoWindow]); // eslint-disable-line

  return null;
};

const eventListenerPropTypes = (eventNames: string[]) => {
  const obj: { [prop: string]: any } = {};
  eventNames.forEach(
    eventName => (obj[`on${camelize(eventName)}`] = PropTypes.func)
  );

  return obj;
};

InfoWindow.propTypes = {
  ...eventListenerPropTypes(eventNames),
};

InfoWindow.defaultProps = {
  showInfoWindow: false,
  infoWindowContent: 'Info window content goes here',
};

export default InfoWindow;
