import React from 'react';
import { Link } from 'react-router-dom';

// Component
import Lang from '../Lang';

// Custom Hooks
import useReadableLanguage from '../../hooks/useReadableLanguage';

// Mixpanel
import { trackCustomEvent } from '../../services/mixpanelTracking';

// Styles
import styles from './quickLinks.module.scss';

const QuickLinks = ({ className = '' }) => {
  const language = useReadableLanguage();

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <div className={styles.button}>
        <Link
          onClick={() => {
            trackCustomEvent('Navigated To Search', {
              action: 'click',
              trigger: 'Search',
              language,
              description: 'Secondary nav link',
              location: 'Secondary navigation bar',
            });
          }}
          to="/map">
          <i className="fa fa-search" />
          <p>
            <Lang textKey="searchTab" />
          </p>
        </Link>
      </div>
      <div className={styles.button}>
        <Link to="/compare/">
          <i className="fa fa-columns" />
          <p>
            <Lang textKey="compareTab" />
          </p>
        </Link>
      </div>
      <div className={styles.button}>
        <Link to={{ pathname: '/', state: { tab: 'whatIsSQRP' } }}>
          <i className="fa fa-pie-chart" />
          <p>
            <Lang textKey="infoTab" />
          </p>
        </Link>
      </div>
      <div className={styles.button}>
        <Link to="#tour">
          <i className="fa fa-question-circle" />
          <p>
            <Lang textKey="helpTab" />
          </p>
        </Link>
      </div>
    </div>
  );
};

export default QuickLinks;
