import React, { FC, SyntheticEvent } from 'react';

// Components
import Lang from './Lang';

// Custom Hooks
import useReadableLanguage from '../hooks/useReadableLanguage';
import useGetTranslationFunc from '../hooks/useGetTranslationFunc';

// Utils
import { trackCustomEvent } from '../services/mixpanelTracking';

interface LinkWithTrackingProps {
  href: string;
  langKey: string;
  mixpanelEventName: string;
  description: string;
  location: string;
  className?: string;
  target?: string;
}

const LinkWithTracking: FC<LinkWithTrackingProps> = props => {
  const {
    langKey,
    href,
    target,
    className,
    mixpanelEventName,
    description,
    location,
  } = props;

  const language = useReadableLanguage();
  const getTranslation = useGetTranslationFunc();
  const translation = getTranslation(langKey);

  const onClick = (event: SyntheticEvent) => {
    trackCustomEvent(mixpanelEventName, {
      action: 'click',
      trigger: translation,
      language,
      description,
      location,
    });
  };

  return (
    <a
      href={href}
      onClick={onClick}
      className={className}
      target={target}
      rel="noreferrer noopener nofollower">
      <Lang textKey={langKey} />
    </a>
  );
};

export default LinkWithTracking;
