import { useEffect, useRef, useState } from 'react';

// API
import axios from '../../../api';

// Custom Hooks
import useReadableLanguage from '../../../hooks/useReadableLanguage';

// Utilities
import { filter, get, includes } from 'lodash';
import { trackCustomEvent } from '../../../services/mixpanelTracking';

// Types
import { School } from '../../../hooks/useSchoolData';
import { MapSchool } from '../../../reducers/map/types';

const useMultiSchoolData = (schoolIDs: string[]) => {
  const schoolIDsRef = useRef(schoolIDs);
  const [schoolData, setSchoolData] = useState<MapSchool[] | null>(null);
  const language = useReadableLanguage();

  useEffect(() => {
    const getSchools = async (): Promise<School[]> => {
      trackCustomEvent('Comparing Schools', {
        action: 'selection',
        trigger: `${schoolIDsRef.current.length}`,
        language,
        description: 'User is comparing schools',
        location: 'Compare School screen',
      });

      const serverResponse = await axios.get('/schools/list', {
        params: {
          id: [...schoolIDsRef.current],
        },
      });

      const data = get(serverResponse, ['data', 'data'], []);

      return data as School[];
    };

    getSchools()
      .then(schools => {
        setSchoolData(schools);
      })
      .catch(error => {
        console.error('there was an error fetching multiple schools', error);
      });
  }, [schoolIDs, setSchoolData, language]);

  // filter based on passed-in IDs.
  // this ensures only ONE API call when the component mounts,
  // but subsequent "remove schools" only remove from the already loaded data
  return filter(schoolData, school => {
    return includes(schoolIDs, get(school, 'school_id'));
  });
};

export default useMultiSchoolData;
