import { Dispatch, SetStateAction, useCallback } from 'react';

// Reducers
import { SET_USER_LOCATION } from '../../../reducers/map';

// Utils
import { isEmpty } from 'lodash';
import { MapState, MapStateAction } from '../../../reducers/map/types';

const useSetUserLocationCallback = (
  setAddress: Dispatch<SetStateAction<string>>,
  state: MapState,
  dispatch: Dispatch<MapStateAction>
) => {
  return useCallback(
    ({
      pos: position,
      short_address,
      map,
    }: {
      pos: any;
      short_address: string;
      map: any;
    }) => {
      const { userLocation } = state;
      const userLocationMarker = isEmpty(userLocation.marker)
        ? new (window as any).google.maps.Marker({
            position,
            map,
            icon: {
              url: 'http://maps.google.com/mapfiles/kml/paddle/blu-circle.png',
            },
            scaledSize: new (window as any).google.maps.Size(40, 44),
            size: new (window as any).google.maps.Size(40, 44),
          })
        : userLocation.marker;

      userLocationMarker.setPosition(position);
      map.setCenter(position);

      dispatch({
        type: SET_USER_LOCATION,
        payload: {
          userLocation: {
            position,
            address: short_address,
            marker: userLocationMarker,
          },
        },
      });

      setAddress(short_address);
    },
    [setAddress, state, dispatch]
  );
};

export default useSetUserLocationCallback;
