import React, { FC, useCallback } from 'react';
import PropTypes from 'prop-types';

// Context
import { useStoreSelector } from '../../../../context/StoreContext';

// Reducers
import {
  FILTER_SCHOOLS,
  mapDefaultState,
  RESET,
  SORT_SCHOOLS,
  TOGGLE_SEARCH_VIEW,
} from '../../../../reducers/map';

// Components
import Lang from '../../../../components/Lang';
import OptionWithLang from '../../../../components/Lang/OptionWithLang';
import SearchLocation from '../SearchLocation';
import Search from '../SearchName';
import MobileLayout from '../../../../components/ResponsiveLayout/MobileLayout';
import DesktopLayout from '../../../../components/ResponsiveLayout/DesktopLayout';
import Row from '../../../../components/Row';
import Column from '../../../../components/Row/Column';
import AccordionTab from '../../../../components/AccordionTab';

// Custom Hooks
import useSetUserLocationCallback from '../../hooks/useSetUserLocationCallback';
import useReadableLanguage from '../../../../hooks/useReadableLanguage';

// Mixpanel
import { trackCustomEvent } from '../../../../services/mixpanelTracking';

// Utilities
import { capitalize, get, isEmpty } from 'lodash';
import createOnBlurHandler from './utils/createOnBlurHandler';
import createFilterAccordionBody from './utils/createFilterAcccordionBody';
import utilities from '../../../../services/utilities';

// Styles
import styles from './searchFiltersScreen.module.scss';
import mapStyles from '../../map.module.scss';
import { SetStateFunction } from '../../../../types';
import { GoogleMapsMap } from '../../../../components/GoogleMap/types';

interface SearchFiltersProps {
  address: string;
  searchName: string;
  setSearchName: SetStateFunction<string>;
  setAddress: SetStateFunction<string>;
  setShowPopUp: SetStateFunction<boolean>;
}

const SearchFilters: FC<SearchFiltersProps> = props => {
  const {
    address,
    searchName,
    setSearchName,
    setAddress,
    setShowPopUp,
  } = props;

  // Global "store" context map state
  const [state, dispatch] = useStoreSelector(store => store.map);
  const { userLocation, map, showMap, filters } = state;

  const distance = utilities.getFilterType(filters, 'distance');

  const setUserLocation = useSetUserLocationCallback(
    setAddress,
    state,
    dispatch
  );
  const onBlurHandler = createOnBlurHandler(setUserLocation);

  const onNameSearch = useCallback(
    event => {
      const searchQuery = get(event.target, 'value', '');
      const newFilters = { ...filters, searchNameQuery: searchQuery };

      dispatch({ type: FILTER_SCHOOLS, payload: { filters: newFilters } });
      setSearchName(searchQuery);
    },
    [dispatch, setSearchName, filters]
  );

  const addressOnChangeHandler = useCallback(
    event => {
      const address = event.target.value;

      if (isEmpty(event.target)) {
        return;
      }

      setAddress(address);
    },
    [setAddress]
  );

  const limitDistance = useCallback(
    event => {
      const limit = get(event, ['target', 'value']);
      const newFilters = { ...filters, distance: limit };

      dispatch({ type: FILTER_SCHOOLS, payload: { filters: newFilters } });
    },
    [dispatch, filters]
  );

  const onResetHandler = useCallback(
    (event, userLocation, map) => {
      const userMarker = get(userLocation, 'marker', {});

      if (!isEmpty(userMarker)) {
        userMarker.setMap(null);
      }

      if (!isEmpty(map)) {
        map.setZoom(mapDefaultState.zoom);
        map.setCenter(mapDefaultState.center);
      }

      dispatch({ type: RESET });
      setAddress('');
      setSearchName('');
    },
    [dispatch, setAddress, setSearchName]
  );

  const filterButtons = useCallback(
    () => (
      <div className={styles.filterControls}>
        <span
          className={
            mapStyles.removeBasicLinkStyles +
            ' ' +
            mapStyles.button +
            ' ' +
            mapStyles.whiteOutline
          }
          onClick={event => onResetHandler(event, userLocation, map)}>
          <span>
            <Lang textKey="clearFilters" />
          </span>
        </span>
        <span
          className={
            mapStyles.removeBasicLinkStyles +
            ' ' +
            mapStyles.button +
            ' ' +
            mapStyles.whiteOutlineBlueBackground
          }
          onClick={() => setShowPopUp(prevState => !prevState)}>
          <span>
            <Lang textKey="showResults" />
          </span>
        </span>
      </div>
    ),
    [setShowPopUp, onResetHandler, map, userLocation]
  );

  const onSearchChanged = useCallback(
    (event, searchQuery) => {
      onNameSearch(event);
    },
    [onNameSearch]
  );

  const showListView = useCallback(
    () =>
      dispatch({
        type: TOGGLE_SEARCH_VIEW,
        payload: { showMap: false },
      }),
    [dispatch]
  );

  const showMapView = useCallback(
    () =>
      dispatch({
        type: TOGGLE_SEARCH_VIEW,
        payload: { showMap: true },
      }),
    [dispatch]
  );

  const language = useReadableLanguage();

  const onSortChange = useCallback(
    event => {
      trackCustomEvent('Sorted Schools', {
        action: 'selection',
        trigger: capitalize(event.target.value),
        language,
        description: `User changed 'sort by' value`,
        location: 'Map screen',
      });

      dispatch({
        type: SORT_SCHOOLS,
        payload: { sortSchoolsBy: event.target.value },
      });
    },
    [dispatch, language]
  );

  const onSearchLocationFocus = useCallback(() => {
    trackCustomEvent('Filtered Schools Using Address', {
      action: 'click',
      trigger: 'Set Your Location',
      language,
      description: `User is using 'set your location' filter`,
      location: 'Map school search screen',
    });
  }, [language]);

  const onSearchNameFocus = useCallback(() => {
    trackCustomEvent('Filtered Schools Using School Name', {
      action: 'click',
      trigger: 'Search by school name',
      language,
      description: `Use is using the 'search by school name' filter`,
      location: 'Map school search screen',
    });
  }, [language]);

  return (
    <>
      <DesktopLayout>
        <Row>
          <Column className={`${mapStyles.desktopFilterNav}`}>
            <div className={mapStyles.desktopFilters}>
              <span>
                <span className="label">
                  <Lang textKey="setYourLocation" />:
                </span>
                <div className={mapStyles.inputWrapper}>
                  <i
                    className="fa fa-fw fa-map-marker mr-5em"
                    aria-hidden="true"
                  />
                  <SearchLocation
                    id="address_input2"
                    placeholder="Set Your Location"
                    value={address}
                    map={map as GoogleMapsMap}
                    onChange={addressOnChangeHandler}
                    className={`${mapStyles.search_location}`}
                    onBlur={onBlurHandler}
                    onPlacesChanged={setUserLocation}
                    onFocus={onSearchLocationFocus}
                  />
                </div>
              </span>
              <span>
                <span className="label">
                  <Lang textKey="searchSchoolButton" />:
                </span>
                <Search
                  searchQuery={searchName}
                  onSearchChanged={onSearchChanged}
                  onFocus={onSearchNameFocus}
                />
              </span>
            </div>
            <span>{filterButtons()}</span>
          </Column>
        </Row>
      </DesktopLayout>
      <Row className={mapStyles.mapFiltersRow}>
        <MobileLayout>
          <Column>{filterButtons()}</Column>
          <Column col={4}>
            <div>
              <div
                className={`${mapStyles.groupTabs} ${mapStyles.withoutIcons}`}>
                <input
                  name="search_view"
                  id="list-view"
                  type="radio"
                  onChange={showListView}
                  checked={showMap}
                  className={!showMap ? mapStyles.isChecked : ''}
                />
                <label htmlFor="list-view">
                  <i
                    className="fa fa-fw fa-th-list mr-5em"
                    aria-hidden="true"
                  />
                  <Lang textKey="listSearchView" />
                </label>
                <input
                  name="search_view"
                  id="map-view"
                  type="radio"
                  onChange={showMapView}
                  checked={showMap}
                  className={showMap ? mapStyles.isChecked : ''}
                />
                <label htmlFor="map-view">
                  <i className="fa fa-fw fa-map mr-5em" aria-hidden="true" />
                  <Lang textKey="mapSearchView" />
                </label>
              </div>
            </div>
          </Column>
          <Column>
            <h3 className="label mb-1em">
              <Lang textKey="searchSchoolButton" />
            </h3>
            <Search
              searchQuery={searchName}
              onSearchChanged={onSearchChanged}
              className="__search_mobile_4__"
              onFocus={onSearchNameFocus}
            />
            <h3 className="mt-1em mr-1em">
              <Lang textKey="sortBy" />
            </h3>
            <div className={mapStyles.inputWrapper + ' '}>
              <i
                className="fa fa-fw fa-angle-down mr-25em"
                aria-hidden="true"
              />
              <select onChange={onSortChange} value={state.sortBy}>
                <OptionWithLang value="score" textKey="sortByScore" />
                <OptionWithLang value="name" textKey="sortByName" />
                {!!userLocation.address && (
                  <OptionWithLang
                    value={distance}
                    textKey="distTo"
                    interpolate={str => str + ' ' + userLocation.address}
                  />
                )}
              </select>
            </div>
          </Column>
        </MobileLayout>
        <Column col={5}>
          <h3>
            <Lang textKey="locationFilter" />
          </h3>
          <div className={`${mapStyles.inputWrapper} mb-1em`}>
            <i className="fa fa-fw fa-map-marker mr-5em" aria-hidden="true" />
            <SearchLocation
              id="address_input3"
              placeholder="Set Your Location"
              value={address}
              map={map as GoogleMapsMap}
              onChange={addressOnChangeHandler}
              className={`${mapStyles.search_location}`}
              onBlur={onBlurHandler}
              onPlacesChanged={setUserLocation}
              onFocus={onSearchLocationFocus}
            />
          </div>
          {userLocation.address && (
            <div className={'mb-1em'}>
              <h4 className="mt-0 mr-1em">
                <Lang textKey="within" />
              </h4>
              <div className={mapStyles.inputWrapper}>
                <select value={distance} onChange={limitDistance}>
                  <OptionWithLang
                    textKey="distUnit"
                    value=""
                    interpolate={str => 'Select Distance limit'}
                  />
                  <OptionWithLang
                    value="0.5"
                    textKey="distUnit"
                    interpolate={str => `0.5 ${str}`}
                  />
                  <OptionWithLang
                    value="1"
                    textKey="distUnit"
                    interpolate={str => `1 ${str}`}
                  />
                  <OptionWithLang
                    value="2"
                    textKey="distUnit"
                    interpolate={str => `2 ${str}`}
                  />
                  <OptionWithLang
                    value="3"
                    textKey="distUnit"
                    interpolate={str => `3 ${str}`}
                  />
                  <OptionWithLang
                    value="5"
                    textKey="distUnit"
                    interpolate={str => `5 ${str}`}
                  />
                  <OptionWithLang
                    value="100"
                    textKey="distUnit"
                    interpolate={str => `Citywide`}
                  />
                </select>
                <i className="fa fa-angle-down" />
              </div>
            </div>
          )}
          <AccordionTab
            headerTextKey={'communityFilter'}
            utilizeState
            className={styles.accordion}
            headerClassName={styles.accordionTabHeader}
            headerWrapperClassName={styles.accordionTabHeaderWrapper}
            bodyClassName={styles.accordionBody}>
            {createFilterAccordionBody('communityAreas')}
          </AccordionTab>
        </Column>
        <Column col={5}>
          <AccordionTab
            headerTextKey={'gradeFilter'}
            utilizeState
            className={styles.accordion}
            headerClassName={styles.accordionTabHeader}
            headerWrapperClassName={styles.accordionTabHeaderWrapper}
            bodyClassName={styles.accordionBody}>
            {createFilterAccordionBody('grades')}
          </AccordionTab>
        </Column>
        <Column col={5}>
          <AccordionTab
            headerTextKey={'sortByScore'}
            utilizeState
            className={styles.accordion}
            headerClassName={styles.accordionTabHeader}
            headerWrapperClassName={styles.accordionTabHeaderWrapper}
            bodyClassName={styles.accordionBody}>
            {createFilterAccordionBody('scores')}
          </AccordionTab>
          <AccordionTab
            headerTextKey={'schoolTypeFilter'}
            utilizeState
            className={styles.accordion}
            headerClassName={styles.accordionTabHeader}
            headerWrapperClassName={styles.accordionTabHeaderWrapper}
            bodyClassName={styles.accordionBody}>
            {createFilterAccordionBody('schoolTypes')}
          </AccordionTab>
        </Column>
        <Column col={5}>
          <AccordionTab
            headerTextKey={'programsFilter'}
            utilizeState
            className={styles.accordion}
            headerClassName={styles.accordionTabHeader}
            headerWrapperClassName={styles.accordionTabHeaderWrapper}
            bodyClassName={styles.accordionBody}>
            {createFilterAccordionBody('programs')}
          </AccordionTab>
        </Column>
        <Column col={5}>
          <AccordionTab
            headerTextKey={'cteProgramsFilter'}
            utilizeState
            className={styles.accordion}
            headerClassName={styles.accordionTabHeader}
            headerWrapperClassName={styles.accordionTabHeaderWrapper}
            bodyClassName={styles.accordionBody}>
            {createFilterAccordionBody('ctePrograms')}
          </AccordionTab>
        </Column>
        <MobileLayout>
          <Column>{filterButtons()}</Column>
        </MobileLayout>
      </Row>
    </>
  );
};

SearchFilters.propTypes = {
  searchName: PropTypes.string.isRequired,
  setAddress: PropTypes.func.isRequired,
  setShowPopUp: PropTypes.func.isRequired,
};

export default SearchFilters;
