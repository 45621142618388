import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { GoogleMapsMap } from '../../types';

const useShowMarker = (
  showMarker: boolean,
  marker: google.maps.Marker,
  map: GoogleMapsMap
) => {
  useEffect(() => {
    if (!isEmpty(marker) && !showMarker) {
      (marker as google.maps.Marker).setMap(null);
    } else if (!isEmpty(map) && !isEmpty(marker)) {
      (marker as google.maps.Marker).setMap(map as GoogleMapsMap);
    }

    return () => {
      if (!isEmpty(marker)) {
        (marker as google.maps.Marker).setMap(null);
      }
    };
  }, [showMarker, marker]); // eslint-disable-line
};
export default useShowMarker;
