import { ScoreKey } from '../../../reducers/map/types';

const ratings = {
  'Level 1': 'Level-1-1.png',
  'Level 1+': 'Level-1.png',
  'Level 2': 'Level-2-1.png',
  'Level 2+': 'Level-2.png',
  'Level 3': 'Level-3.png',
  'Inability to Rate': 'NA.png',
};

const getRatingLogo = (rating: ScoreKey) => {
  return `/assets/images/${ratings[rating]}`;
};

export default getRatingLogo;
