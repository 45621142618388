import React, { FC, useCallback, useContext } from 'react';

// Context
import { useSchoolContext } from '../../../context/SchoolContext';
import ActiveGridContext from '../../../context/GridContext';

// Components
import Lang from '../../../components/Lang';
import MetricDetail from './MetricDetail';
import SurveyDetail from './SurveyDetail';
import GrowthAttainmentDetail from './GrowthAttainmentDetail';

// Custom Hooks
import useGetTranslationFunc from '../../../hooks/useGetTranslationFunc';
import useReadableLanguage from '../../../hooks/useReadableLanguage';

// Utilities
import { get, last } from 'lodash';
import utility from '../../../services/utilities';
import { trackCustomEvent } from '../../../services/mixpanelTracking';

// Styles
import styles from '../profile.module.scss';

// Types
import { TOGGLE_GRID_TAB } from '../../../reducers/gridTab';
import { School } from '../../../hooks/useSchoolData';

const Header: FC = props => {
  return (
    <div className={styles.sectionHeaderWrapper}>
      <p>{props.children}</p>
    </div>
  );
};

interface RenderPropsFunction<T> {
  (renderProps: T): React.ReactNode;
}

interface RenderProps {
  schoolData: {} | School;
  openedElement: string;
  isSelected?: boolean;
  score?: string;
  colorClass?: string;
  dataKey?: string;
}

interface RowProps {
  children: RenderPropsFunction<RenderProps> | React.ReactNode;
  details?: (renderProps: RenderProps) => React.ReactNode;
}

export const MetricRow: FC<RowProps> = props => {
  const details = props.details;
  const { openedElement } = useContext(ActiveGridContext);
  const schoolData = useSchoolContext();
  const renderProps = { openedElement, schoolData };

  const renderDetails = (children: React.ReactNode) => {
    const isDetailsFunction = 'function' === typeof details;

    if (details && isDetailsFunction) {
      return details(renderProps);
    }

    // Many things going on here...
    // First, we must produce the Details array, which will be placed beneath the flex box
    // these are conditionally shown based on whether the dataKey matches the opened element
    // Next, we must handle the special case of the Survey Metric, which uses a different metric component
    return React.Children.map(children, elem => {
      const reactChildName = get(elem, ['type', 'name']);
      const dataKey = get(elem, ['props', 'dataKey'], '');
      const type = get(elem, ['props', 'metricType'], '');

      if (openedElement !== dataKey) {
        return null;
      }

      // Render the correct 'detail' component for 'Growth' vs 'Attainment' metrics
      if (reactChildName.match(/^GrowthAttainment.*/g)) {
        return <GrowthAttainmentDetail dataKey={dataKey} metricType={type} />;
      }

      return (
        <>
          {dataKey === 'my_voice_my_school_5_essentials_survey' ? (
            <SurveyDetail dataKey={dataKey} />
          ) : (
            <MetricDetail dataKey={dataKey} />
          )}
        </>
      );
    });
  };

  if (typeof props.children === 'function') {
    const children = props.children as RenderPropsFunction<RenderProps>;

    return <div>{children(renderProps)}</div>;
  }

  const children = props.children;

  const ui = (
    <>
      <div className={styles.gridRow}>{children}</div>
      <div className="__tour4__">{renderDetails(children)}</div>
    </>
  );

  return (
    <>
      <div>{ui}</div>
    </>
  );
};

interface ElementProps {
  dataKey: string;
  children: RenderPropsFunction<RenderProps> | React.ReactNode;
  langKey: string;
  left?: boolean;
  className?: string;
}

/**
 * TODO: Merge ALL uses of GrowthAttainment components with this Component
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const MetricElement: FC<ElementProps> = props => {
  const { dataKey, children, langKey } = props;
  const schoolData = useSchoolContext();
  const metricHistory = get(schoolData, dataKey, [{}]);
  const mostRecent = metricHistory[metricHistory.length - 1] as {
    score: string;
    points: string;
  };
  const { openedElement, dispatchGridClick } = useContext(ActiveGridContext);

  const getTranslation = useGetTranslationFunc();
  const langKeyTranslation = getTranslation(langKey);
  const language = useReadableLanguage();

  let { score = '', points = '' } = mostRecent;
  const colorClass = utility.pointToColor(points);

  // for all values EXCEPT survey results, round and add percentile sign
  if (!dataKey.match(/my_voice_my_school_5_essentials_survey/)) {
    // if cannot convert to number, show N/A as a default
    if (parseFloat(score)) {
      score = parseFloat(score).toFixed(0) + '%';
    } else {
      score = 'N/A';
    }
  }

  const isSelected = openedElement === dataKey;
  const renderProps = {
    schoolData,
    openedElement,
    dataKey,
    isSelected,
    score,
    colorClass,
  };

  const onClickHandler = useCallback(() => {
    trackCustomEvent('Metric Used', {
      action: 'click',
      trigger: langKeyTranslation,
      language,
      description: 'User clicked to use metric',
      location: 'School Profile',
    });

    dispatchGridClick({
      type: TOGGLE_GRID_TAB,
      payload: { tabClicked: dataKey },
    });
  }, [dispatchGridClick, dataKey, langKeyTranslation, language]);

  let ui;

  if (typeof props.children === 'function') {
    const children = props.children as RenderPropsFunction<RenderProps>;

    ui = children(renderProps);
  } else {
    ui = (
      <>
        <div className={styles.elementHighlight}>
          {children}
          <p className={colorClass}>{score}</p>
        </div>
        <div className={styles.detailArrowWrapper}>
          <span>
            <Lang textKey="details" />
          </span>
          <i className={'fa fa-chevron-' + (isSelected ? 'up' : 'down')} />
        </div>
      </>
    );
  }

  return (
    <div
      className={`${styles.cellWrapper}
      ${isSelected && styles.selected}
      ${(props.left && styles.leftCell) + ' ' + props.className}
    `}
      onClick={onClickHandler}>
      {ui}
    </div>
  );
};

MetricElement.defaultProps = {
  className: '',
};

interface GrowthAttainmentElementProps {
  dataKey: string;
  langKey: string;
  children: RenderPropsFunction<RenderProps>;
  metricType?: string;
  left?: boolean;
  className?: string;
}

/**
 * TODO: ADHOC Component created to:
 *
 *    1. Not break other 'Element' components that are used to render metrics
 *    2. To render the content specific for 'Growth' vs 'Attainment' section
 *
 * TODO: Refactor `Row` and `Element` components to use render props pattern
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const MetricGrowthAttainmentElement: FC<GrowthAttainmentElementProps> = props => {
  const { dataKey, langKey } = props;
  const schoolData = useSchoolContext();
  const metricHistory = get(schoolData, dataKey, [{}]);

  const getTranslation = useGetTranslationFunc();
  const langKeyTranslation = getTranslation(langKey);
  const language = useReadableLanguage();

  const { openedElement, dispatchGridClick } = useContext(ActiveGridContext);

  const mostRecent = last(metricHistory) as { score: number };

  let { score = '' } = mostRecent;

  const scoreLevels = {
    'above-average': {
      label: 'ABOVE AVERAGE',
      colorClass: 'sqrp-green',
    },
    average: {
      label: 'AVERAGE',
      colorClass: 'sqrp-yellow',
    },
    'below-average': {
      label: 'BELOW AVERAGE',
      colorClass: 'sqrp-red',
    },
  };

  const isSelected = openedElement === dataKey;

  const scoreKey =
    score >= 70
      ? 'above-average'
      : 70 > score && score >= 40
      ? 'average'
      : 'below-average';

  score = get(scoreLevels, [scoreKey, 'label']);
  const colorClass = get(scoreLevels, [scoreKey, 'colorClass']);

  const renderProps = {
    schoolData,
    score,
    colorClass,
    isSelected,
    openedElement,
  };

  const onClickHandler = useCallback(() => {
    trackCustomEvent('Metric Used', {
      action: 'click',
      trigger: langKeyTranslation,
      language,
      description: 'User clicked to use metric',
      location: 'School Profile',
    });

    dispatchGridClick({
      type: TOGGLE_GRID_TAB,
      payload: { tabClicked: dataKey },
    });
  }, [dispatchGridClick, dataKey, langKeyTranslation, language]);

  return (
    <div
      className={`${styles.cellWrapper}
      ${isSelected && styles.selected}
      ${(props.left && styles.leftCell) + ' ' + props.className}
    `}
      onClick={onClickHandler}>
      {props.children(renderProps)}
    </div>
  );
};

MetricGrowthAttainmentElement.defaultProps = {
  className: '',
  metricType: 'growth',
  children: (renderProps: RenderProps) => null,
};

export default {
  Row: MetricRow,
  Element: MetricElement,
  Header,
  GrowthAttainmentElement: MetricGrowthAttainmentElement,
};
