const metricDetails = {
  '%_earning_early_college_and_career_credentials': {
    title: 'Early College & Career Credentials',
    description: `The Early College Credentials rate is the
    percentage of graduates earning college credit or a career credential.`,
    titleKey: 'earningEarlyCollegeAndCareerCredentialsTitle',
    descriptionKey: 'earningEarlyCollegeAndCareerCredentialsDesc',
  },
  percent_meeting_college_readiness_benchmarks: {
    title: 'College Readiness Benchmarks',
    description: `The SAT College Readiness Benchmarks
    help students and educators assess student progress toward college readiness from year to year.`,
    titleKey: 'percentMeetingCollegeReadinessBenchmarksTitle',
    descriptionKey: 'percentMeetingCollegeReadinessBenchmarksDesc',
  },
  college_persistence_rate: {
    title: 'College Persistence Rate',
    description: `The College Persistence Rate is the percentage of students
    who continue to be enrolled in their second year of college.`,
    titleKey: 'collegePersistenceRateTitle',
    descriptionKey: 'collegePersistenceRateDesc',
  },
  college_enrollment_rate: {
    title: 'College Enrollment Rate',
    description: `The College Enrollment Rate is the percentage of
    graduates from last school year who enrolled in college the following fall.`,
    titleKey: 'collegeEnrollmentRateTitle',
    descriptionKey: 'collegeEnrollmentRateDesc',
  },
  '4_year_cohort_graduation_rate': {
    title: '4-Year Graduation Rate',
    description: `The 4-Year Graduation Rate is the percentage of students who graduate from high school within four years.`,
    titleKey: 'fourYearGraduationRateTitle',
    descriptionKey: 'fourYearGraduationRateDesc',
  },
  freshmen_on_track_rate: {
    title: 'Freshmen On-Track Rate',
    description: `The Freshmen On-Track Rate is
    the percentage of students “On Track” to graduate from high school within four years.`,
    titleKey: 'freshmenOnTrackRateTitle',
    descriptionKey: 'freshmenOnTrackRateDesc',
  },
  average_daily_attendance_rate: {
    title: 'Average Daily Attendance Rate',
    description: `The Average Attendance Rate is based on the number
    of days students are present during the school year.`,
    titleKey: 'averageDailyAttendanceRateTitle',
    descriptionKey: 'averageDailyAttendanceRateDesc',
  },
  average_daily_attendance_rate_grades_k_8: {
    title: 'Average Daily Attendance Rate',
    description: `The Average Attendance Rate is based on the number
    of days students are present during the school year.`,
    titleKey: 'averageDailyAttendanceRateElementaryTitle',
    descriptionKey: 'averageDailyAttendanceRateElementaryDesc',
  },
  my_voice_my_school_5_essentials_survey: {
    title: '5 Essentials School Climate Survey',
    description: 'The College Readiness Benchmarks',
    titleKey: 'fiveEssentialsSchoolClimateSurveyTitle',
    descriptionKey: 'fiveEssentialsSchoolClimateSurveyDesc',
  },
  national_school_growth_percentile_reading: {
    title: 'NWEA National Growth Percentile – Reading',
    description: 'Student Growth in Reading..... etc.',
    titleKey: 'nationalGrowthPercentileReadingTitle',
    descriptionKey: 'nationalGrowthPercentileReadingDesc',
  },
  national_school_growth_percentile_math: {
    title: 'NWEA National Growth Percentile – Math',
    description: 'Student Growth in Math..... etc.',
    titleKey: 'nationalGrowthPercentileMathTitle',
    descriptionKey: 'nationalGrowthPercentileMathDesc',
  },
  percent_of_students_meetingexceeding_national_average_growth_norms: {
    title: 'Percent of Students Meeting Average Growth Norms',
    description:
      'This is the percentage of students who meet or excede the average growth norms',
    titleKey: 'percentOfStudentsMeetingAverageGrowthNormsTitle',
    descriptionKey: 'percentOfStudentsMeetingAverageGrowthNormsDesc',
  },
  'national_school_attainment_%ile_reading_grades_3_8': {
    title: 'NWEA National Attainment Percentile – Reading',
    description: 'description here....',
    titleKey: 'nationalAttainmentPercentileReadingTitle',
    descriptionKey: 'nationalAttainmentPercentileReadingDesc',
  },
  'national_school_attainment_%ile_math_grades_3_8': {
    title: 'NWEA National Attainment Percentile – Math',
    description: 'description here...',
    titleKey: 'nationalAttainmentPercentileMathTitle',
    descriptionKey: 'nationalAttainmentPercentileMathDesc',
  },
  sat11_african_american_cohort_growth_percentile: {
    title: 'SAT11 African-American Cohort Growth  Percentile',
    description: 'description here...',
    titleKey: 'SATAfricanAmericanCohortGrowthPercentileTitle',
    descriptionKey: 'SATAfricanAmericanCohortGrowthPercentileDesc',
  },
  sat11_hispanic_cohort_growth_percentile: {
    title: 'SAT11 Hispanic Cohort Growth Percentile',
    description: 'description here...',
    titleKey: 'SATHispanicCohortGrowthPercentileTitle',
    descriptionKey: 'SAYHispanicCohortGrowthPercentileDesc',
  },
  sat11_english_learner_cohort_growth_percentile: {
    title: 'SAT11English Learner Cohort Growth  Percentile',
    description: 'description here...',
    titleKey: 'SATEnglishLearnerCohortGrowthPercentileTitle',
    descriptionKey: 'SATEnglishLearnerCohortGrowthPercentileDesc',
  },
  sat11_diverse_learner_cohort_growth_percentile: {
    title: 'SAT11 Diverse Learner Cohort Growth  Percentile',
    description: 'description here...',
    titleKey: 'SATDiverseLearnerCohortGrowthPercentileTitle',
    descriptionKey: 'SATDiverseLearnerCohortGrowthPercentileDesc',
  },
  sat11_cohort_growth_percentile: {
    title: 'SAT Growth',
    titleKey: 'SATGrowthTitle',
    descriptionKey: 'SATGrowthDesc',
  },
  hispanic_growth_percentile_math: {
    title: 'Hispanic Priority Group Growth',
    description: 'description here...',
    titleKey: 'hispanicPriorityGroupGrowthMathTitle',
    descriptionKey: 'hispanicPriorityGroupGrowthMathDesc',
  },
  hispanic_growth_percentile_reading: {
    title: 'Hispanic Priority Group Growth',
    description: 'description here...',
    titleKey: 'hispanicPriorityGroupGrowthReadingTitle',
    descriptionKey: 'hispanicPriorityGroupGrowthReadingDesc',
  },
  african_american_growth_percentile_math: {
    title: 'African American Priority Group Growth',
    description: 'description here...',
    titleKey: 'africanAmericanPriorityGroupGrowthMathTitle',
    descriptionKey: 'africanAmericanPriorityGroupGrowthMathDesc',
  },
  african_american_growth_percentile_reading: {
    title: 'African American Priority Group Growth',
    description: 'description here...',
    titleKey: 'africanAmericanPriorityGroupGrowthReadingTitle',
    descriptionKey: 'africanAmericanPriorityGroupGrowthReadingDesc',
  },
  diverse_learner_growth_percentile_math: {
    title: 'Diverse Learner Growth Percentile',
    description: 'description here...',
    titleKey: 'diverseLearnerGrowthPercentileMathTitle',
    descriptionKey: 'diverseLearnerGrowthPercentileMathDesc',
  },
  diverse_learner_growth_percentile_reading: {
    title: 'Diverse Learner Growth Percentile',
    description: 'description here...',
    titleKey: 'diverseLearnerGrowthPercentileReadingTitle',
    descriptionKey: 'diverseLearnerGrowthPercentileReadingDesc',
  },
  english_learner_growth_percentile_math: {
    title: 'English Learner Growth Percentile',
    description: 'description here...',
    titleKey: 'englishLearnerGrowthPercentileMathTitle',
    descriptionKey: 'englishLearnerGrowthPercentileMathDesc',
  },
  english_learner_growth_percentile_reading: {
    title: 'English Learner Growth Percentile',
    description: 'description here...',
    titleKey: 'englishLearnerGrowthPercentileReadingTitle',
    descriptionKey: 'englishLearnerGrowthPercentileReadingDesc',
  },
  average_sat_score: {
    title: 'Average SAT Score',
    description: 'Description here.....',
    titleKey: 'averageSATScoreTitle',
    descriptionKey: 'averageSATScoreDesc',
  },
  sat_growth: {
    title: 'SAT Growth',
    description: 'Description here.....',
    titleKey: 'SATGrowthTitle',
    descriptionKey: 'SATGrowthDesc',
  },
  growth: {
    title: 'Growth',
    description: 'Description here.....',
    titleKey: 'elementaryGrowthTitle',
    descriptionKey: 'elementaryGrowthDesc',
  },
  attainment: {
    title: 'Attainment',
    description: 'Description here.....',
    titleKey: 'elementaryAttainmentTitle',
    descriptionKey: 'elementaryAttainmentDesc',
  },
};

export default metricDetails;
