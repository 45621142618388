import React, { FC, SyntheticEvent } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

// Cookies
import { CookiesProvider, useCookies } from 'react-cookie';

// Components
import Lang from '../Lang';

// Utils
import moment from 'moment';

// Styles
import './popup.scss';

interface RenderProps {
  onCloseHandler: (e: SyntheticEvent) => void;
  wrapperClass: string;
  contentClass: string;
}

interface RenderPropsFunction {
  (renderProps: RenderProps): React.ReactNode;
}

interface PopupProps {
  children: RenderPropsFunction | React.ReactNode;
  wrapperClass?: string;
  contentClass?: string;
  onClose?: (e: SyntheticEvent) => void;
}

const Popup: FC<PopupProps> = props => {
  const [cookies, setCookie] = useCookies(['popup']);
  const { children, wrapperClass = '', contentClass = '', onClose } = props;

  const modalParentDiv = document.querySelector('#modal') as Element;

  const onCloseHandler = (e: SyntheticEvent) => {
    const fourteenDaysLater = moment().add(14, 'days');

    setCookie('popup', 'closed', { expires: fourteenDaysLater.toDate() });

    if (onClose) {
      onClose(e);
    }

    modalParentDiv.classList.remove('show');
  };

  // If cookie is set DON'T render modal
  if (cookies.popup) {
    return null;
  }

  const renderProps = {
    onCloseHandler,
    wrapperClass,
    contentClass,
  };

  const renderPopUp = () => {
    if ('function' === typeof children) {
      return (children as RenderPropsFunction)(renderProps);
    }

    return (
      <div className={`popup-container ${wrapperClass}`}>
        <div className="close">
          <i onClick={onCloseHandler} className="fa fa-2x fa-close" />
        </div>
        <div className={`popup-content ${contentClass}`}>{children}</div>
      </div>
    );
  };

  modalParentDiv.classList.add('show');

  return ReactDOM.createPortal(renderPopUp(), modalParentDiv);
};

Popup.defaultProps = {
  wrapperClass: '',
  contentClass: '',
  onClose: () => {},
};

const PopupModal: FC = () => {
  return (
    <CookiesProvider>
      {
        <Popup>
          {({ onCloseHandler, contentClass, wrapperClass }) => {
            return (
              <>
                <div className={`popup-container ${wrapperClass}`}>
                  <div className="close">
                    <i onClick={onCloseHandler} className="fa fa-2x fa-close" />
                  </div>
                  <div className={`popup-content ${contentClass}`}>
                    <h2 className={'mb-15em text-uppercase text-blue'}>
                      <Lang textKey="popupHeadline" />
                    </h2>
                    <p>
                      <Lang textKey="popupParagraphOne" />
                    </p>
                    <p>
                      <Lang textKey="popupParagraphTwo" />
                    </p>
                    <p className={'mt-2em'}>
                      <span>
                        <Link
                          to={'/'}
                          className={'button'}
                          onClick={onCloseHandler}>
                          <Lang textKey="viewProfile" />
                        </Link>
                      </span>
                      <span className={'ml-1em'}>
                        <Link
                          to={'/map'}
                          className={'button'}
                          onClick={onCloseHandler}>
                          <Lang textKey="searchForSchools" />
                        </Link>
                      </span>
                    </p>
                  </div>
                </div>
              </>
            );
          }}
        </Popup>
      }
    </CookiesProvider>
  );
};

export default PopupModal;
