import React, { FC } from 'react';
import PropTypes from 'prop-types';

// Utilities
import { get } from 'lodash';
import utility from '../services/utilities';

const splitLevelRating = (rating: string) => {
  // this is used to split the level rating to isolate the number.
  // for example, 'Level 1+' => '1+'
  // special case: 'Inability to Rate' => 'N/A'
  const exp = /[1-5]\+?/;
  const matches = rating.match(exp);

  return get(matches, 0, 'N/A');
};

interface CircleRatingProps {
  levelRating: string;
}

const CircleRating: FC<CircleRatingProps> = ({ levelRating }) => {
  const color = utility.levelToColor(levelRating);
  const strokeColor = 'stroke-' + color;
  const fillColor = 'fill-' + color;
  const ratingText = splitLevelRating(levelRating);

  return (
    <svg className="mr-1em" width="60px" height="60px">
      <circle
        cx="50%"
        cy="50%"
        r="23"
        strokeWidth="3"
        fill="#00000000"
        className={strokeColor}
      />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dominantBaseline="middle"
        className={`bold ${strokeColor} ${fillColor}`}>
        {ratingText}
      </text>
    </svg>
  );
};

CircleRating.propTypes = {
  levelRating: PropTypes.string.isRequired,
};

export default CircleRating;
