// Filters
import { defaultFilters } from './index';

// Utilities
import { get, includes, isEmpty } from 'lodash';
import utilities from '../../../services/utilities';
import isFilterGroupChecked from './utils/isFilterGroupChecked';
import getFiltersFromFilterGroup from './utils/getFiltersFromFilterGroup';
import { MapSchool } from '../types';

const ctePrograms = (schools: MapSchool[] = [], filters = defaultFilters) => {
  let schoolsUpdated = [...schools];
  const { ctePrograms } = filters;

  if (isEmpty(ctePrograms)) {
    return schoolsUpdated;
  }

  const filtersChange = isFilterGroupChecked(ctePrograms);

  // To reduce the number of 'filtering' we perform a check if ALL or NONE of the programs
  // have been checked off and return the schools that were passed in.
  if (filtersChange) {
    return schoolsUpdated;
  }

  const filtersChecked = getFiltersFromFilterGroup(ctePrograms, true, 'value');

  schoolsUpdated = schoolsUpdated.filter(school => {
    let isMatch = false;
    const { cte_program = [] } = school;

    if (isEmpty(cte_program)) {
      return isMatch;
    }

    const programs = cte_program
      .filter(program => !isEmpty(get(program, 'program_specialty', '')))
      .map(program =>
        utilities.createObjectKey(get(program, 'program_specialty', ''))
      );

    programs.forEach(program => {
      if (includes(filtersChecked, program)) {
        isMatch = true;
      }
    });

    return isMatch;
  });

  return schoolsUpdated;
};

export default ctePrograms;
