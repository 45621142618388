import React, { FC } from 'react';

// Context
import { StoreContextValue } from '../context/StoreContext';

// Components
import LangContextProvider from './LangContextProvider';
import { TranslationsContextProvider } from '../context/TranslationsContext';
import { Store as StoreProvider } from './Store';

// Custom Hooks
import useAllSchoolData from '../hooks/useAllSchoolData';
import useCompareState from '../hooks/useCompareState';

const AppProvider: FC = ({ children }) => {
  const map = useAllSchoolData();
  const compare = useCompareState();

  const store: StoreContextValue = { map, compare };

  return (
    <StoreProvider store={store}>
      <LangContextProvider>
        <TranslationsContextProvider>{children}</TranslationsContextProvider>
      </LangContextProvider>
    </StoreProvider>
  );
};

export default AppProvider;
