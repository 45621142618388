import { useEffect, useState, useContext } from 'react';

// context
import GoogleMapContext from '../../context/GoogleMapContext';

// Utilities
import { isEmpty } from 'lodash';
import removeValueType from '../../utils/removeValueType';
import { InfoWindowProps } from '../index';

const useInfoWindow = (props: InfoWindowProps) => {
  const { map, google } = useContext(GoogleMapContext);
  const [infoWindow, setInfoWindow] = useState({});
  const { infoWindowContent } = props;

  useEffect(() => {
    let isMounted = true;

    const { marker, showInfoWindow, ...rest } = props;
    const sanitizedOptions = removeValueType(rest, 'function');

    if (!isEmpty(map) && isEmpty(infoWindow) && isMounted) {
      const newInfoWindow = new google.maps.InfoWindow({
        content: infoWindowContent,
        ...sanitizedOptions,
      });

      setInfoWindow(newInfoWindow);
    }

    return () => {
      isMounted = false;
    };
  }, [infoWindowContent]); // eslint-disable-line

  return infoWindow;
};

export default useInfoWindow;
