import React, { FC } from 'react';

// Utilities
import { isPlainObject, get } from 'lodash';

// Styles
import styles from './checkBoxGroup.module.scss';
import Translate from '../../../../components/Translate';
import { Filter } from '../../../../reducers/map/types';

interface CheckboxGroupProps {
  name: string;
  value: string;
  label: string;
  isChecked: boolean;
  nestedChildren?: Filter[];
  showChildren: boolean;
  onParentChange: (e: any) => void;
  onChildChange: (e: any, parentKey: string) => void;
}

const CheckboxGroup: FC<CheckboxGroupProps> = props => {
  const {
    name,
    value,
    label,
    isChecked,
    nestedChildren = [],
    showChildren,
    onChildChange,
    onParentChange,
  } = props;

  const renderSubSelectGroup = (children: any[], parentKey: string) => {
    return children.map((input, index) => {
      const inputValues = { name: '', value: '', label: '', isChecked: false };

      if ('string' === typeof input) {
        inputValues.name = input;
        inputValues.value = input;
        inputValues.label = input;
      }

      if (isPlainObject(input)) {
        inputValues.name = get(input, 'name', '');
        inputValues.value = get(input, 'value', '');
        inputValues.label = get(input, 'label', '');
        inputValues.isChecked = get(input, 'isChecked', false);
      }

      return (
        <div className={styles.select} key={index}>
          <input
            type={'checkbox'}
            id={inputValues.name}
            value={inputValues.value}
            onChange={event => onChildChange(event, parentKey)}
            checked={inputValues.isChecked}
          />
          <label htmlFor={inputValues.name}>
            <Translate content={inputValues.label} />
          </label>
        </div>
      );
    });
  };

  return (
    <div className={styles.selectGroup}>
      <span className={styles.select}>
        <input
          type="checkbox"
          value={value}
          id={name}
          onChange={onParentChange}
          checked={isChecked}
        />
        <label htmlFor={name}>
          <Translate content={label} />
        </label>
      </span>
      {showChildren && 0 !== nestedChildren.length && (
        <div className={styles.selectSubGroup}>
          {renderSubSelectGroup(nestedChildren, name)}
        </div>
      )}
    </div>
  );
};

CheckboxGroup.defaultProps = {
  onParentChange: e => console.log(e),
  onChildChange: e => console.log(e),
  showChildren: false,
  isChecked: false,
  nestedChildren: [],
};

export default CheckboxGroup;
