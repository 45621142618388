import React, { useEffect, useState, createRef, useRef } from 'react';

// Custom Hooks
import useReadableLanguage from './useReadableLanguage';

// Utils
import { throttle } from 'lodash';

/**
 * Check if an element is in viewport
 *
 * @see: https://stackoverflow.com/a/61719846
 *
 * @param {Function} onVisible - a functions that's called when the element is visible
 * @param {number} offset - Number of pixels up to the observable element from the top
 * @param {number} throttleMilliseconds - Throttle observable listener, in ms
 */
const useViewportVisibility = <Element extends HTMLElement>(
  onVisible: (language: string) => void,
  offset = 0,
  throttleMilliseconds = 100
): [Boolean, React.RefObject<Element>] => {
  const [isVisible, setIsVisible] = useState(false);
  const currentElement = createRef<Element>();
  const visibleCount = useRef(0);
  const language = useReadableLanguage();

  const onScroll = throttle(() => {
    if (!currentElement.current) {
      setIsVisible(false);

      return;
    }

    const top = currentElement.current.getBoundingClientRect().top;
    const isVisible = top + offset >= 0 && top - offset <= window.innerHeight;

    if (isVisible) {
      visibleCount.current++;
    }

    // For now we're only tracking the FIRST scroll to view Element
    if (1 === visibleCount.current) {
      onVisible(language);
      setIsVisible(isVisible);
    }
  }, throttleMilliseconds);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  });

  return [isVisible, currentElement];
};

export default useViewportVisibility;
