import React, { FC, useEffect, useState } from 'react';

// Context
import { useTranslationsContext } from '../context/TranslationsContext';
import { useLanguageContext } from '../context/LanguageContext';

// Utils
import { get } from 'lodash';

// Types
import { Translation } from '../hooks/useTranslations';

interface TranslationProps {
  content: string;
}

const findTranslation = (
  text: string,
  lang: string,
  translations: Translation[]
) => {
  let found = text;

  translations.forEach(translation => {
    const { original, translations } = translation;
    const currentLangTranslation = get(translations, [lang], '');

    if (!currentLangTranslation) {
      return;
    }

    let compareString = original;

    /**
     * CTE Programs are transformed when pulled from API endpoint and striped of 'CTE: '
     *
     * @see {useAllSchoolData}
     */
    if (original.includes('CTE: ', 0)) {
      compareString = original.replace('CTE: ', '');
    }

    if (found !== compareString) {
      return;
    }

    found = currentLangTranslation.replace('CTE: ', '');
  });

  return found;
};

const Translate: FC<TranslationProps> = ({ content }) => {
  const [text, setText] = useState(() => content);
  const { translations } = useTranslationsContext();
  const { lang } = useLanguageContext();

  useEffect(() => {
    setText(() => {
      return findTranslation(content, lang, translations);
    });
  }, [lang, translations, setText, content]);

  return <>{text}</>;
};

export default Translate;
