import { useState, useEffect } from 'react';
import Papa from 'papaparse';

// Utilities
import { forEach, get } from 'lodash';

export interface Translations {
  [langKey: string]: { en: string; es: string };
}

/**
 * A custom hook for retrieving the parsed CSV translation values
 */
const useCSVTranslations = () => {
  const csvPath = '../translation/translation_strings.csv';
  const [translationValues, setTranslationValues] = useState<Translations | {}>(
    {}
  );

  useEffect(() => {
    Papa.parse(csvPath, {
      header: false,
      download: true,
      skipEmptyLines: true,
      complete: results => {
        const valuesFromCSV: any = {};

        forEach(get(results, 'data'), row => {
          const key: string = get(row, 0);
          valuesFromCSV[key] = {
            en: get(row, 1, ''),
            es: get(row, 2, ''),
          };
        });

        setTranslationValues(valuesFromCSV);
      },
    });
  }, []);

  return translationValues;
};

export default useCSVTranslations;
