import React, { SyntheticEvent } from 'react';

// Context
import { useStoreSelector } from '../../../../context/StoreContext';

// Components
import CircleRating from '../../../../components/CircleRating';

// Custom Hooks
import useTrackAdvancedFilterUse from '../../hooks/useTrackAdvanceFilterUse';

// Utilities
import { get } from 'lodash';
import utilities from '../../../../services/utilities';

// Styles
import styles from './scores.module.scss';
import { Scores } from '../../../../reducers/map/types';

const scorePointDeviation = {
  'Level 3': '0.0 - 1.9',
  'Level 2': '2.0 - 2.9',
  'Level 2+': '3.0 - 3.4',
  'Level 1': '3.5 - 3.9',
  'Level 1+': '4.0 - 5.0',
};

const ScoreFilters = () => {
  const [mapState, dispatch] = useStoreSelector(store => store.map);
  const { filters } = mapState;
  const scores = utilities.getFilterType<Scores>(filters, 'scores');

  const mixpanelTrackEvent = useTrackAdvancedFilterUse('scores');

  const onScoreFilterChange = (event: SyntheticEvent, score: keyof Scores) => {
    const isChecked = get(event, ['target', 'checked'], false);
    const newScore = { ...scores[score], isChecked };
    const newScores = { ...scores, [score]: newScore };
    const newFilters = { ...filters, scores: newScores };

    mixpanelTrackEvent(score);

    dispatch({ type: 'FILTER_SCHOOLS', payload: { filters: newFilters } });
  };

  return (
    <div className={styles.scoreFilters}>
      {Object.keys(scores).map((score, index) => {
        const scoreKey = score as keyof Scores;
        return (
          <div className={styles.score} key={index}>
            <input
              type="checkbox"
              id={scores[scoreKey].value}
              checked={scores[scoreKey].isChecked}
              onChange={e => onScoreFilterChange(e, scoreKey)}
            />
            <label htmlFor={scores[scoreKey].value}>
              <CircleRating levelRating={score} />
              <span>
                <small>{scorePointDeviation[scoreKey]}</small>
              </span>
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default ScoreFilters;
