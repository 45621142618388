import { useEffect } from 'react';

// Utilities
import { isEmpty } from 'lodash';
import { School } from './useSchoolData';

/**
 * Updates the document title and description to match the current school.
 * Cleans up when component unmounts.
 * @param {object | null} schoolData individual school record
 */
const useDocumentMetaData = (schoolData: School | {}) => {
  useEffect(() => {
    const metaTag = document.querySelector('meta[name="description"]');

    if (!isEmpty(schoolData)) {
      const name =
        (schoolData as School).long_name || (schoolData as School).school_name;
      document.title = `${name} Quality Report`;
      const newDescription = `View school quality information for ${name}. Compare to other CPS schools nearby and search for a high-quality school that is a good fit for your child and family.`;

      if (metaTag) {
        metaTag.setAttribute('content', newDescription);
      }
    }

    return () => {
      document.title = 'K1C School Quality Search';

      if (metaTag) {
        metaTag.setAttribute(
          'content',
          'Find a high-quality Chicago Public School that is a good fit for your child and family. Narrow your search by selecting criteria.'
        );
      }
    };
  }, [schoolData]);
};

export default useDocumentMetaData;
