import React, { FC } from 'react';

// Context
import { useSchoolContext } from '../../../context/SchoolContext';

// Components
import Lang from '../../../components/Lang';

// Utilities
import { get, last } from 'lodash';
import utility from '../../../services/utilities';

// Styles
import styles from '../profile.module.scss';

const SchoolScore: FC = props => {
  const schoolData = useSchoolContext();

  const pointHist = get(schoolData, 'sqrp_total_points_earned');

  const levels = [
    {
      color: 'red',
      width: 39,
    },
    {
      color: 'light-red',
      width: 20,
    },
    {
      color: 'yellow',
      width: 10,
    },
    {
      color: 'light-green',
      width: 10,
    },
    {
      color: 'green',
      width: 21,
    },
  ];

  const levelRating = utility.getMostRecentSQRPRating(schoolData);
  const totalScore = +get(last(pointHist), 'value');
  const xTick = +((+totalScore / 5) * 100).toFixed(1);
  const scoreColor = utility.levelToColor(levelRating);
  let left = 0;
  let last_left = 0;

  const svgHeight = 40;

  return (
    <div>
      <h1 className="text-center mb-0">
        <span className={'bold ' + scoreColor}>{levelRating}</span>
      </h1>
      <p className="mb-0 mb-2em text-center">
        <Lang textKey="currentRatingHeader" />
      </p>
      <div
        style={{
          position: 'relative',
          paddingBottom: '15px',
          fontWeight: 'bold',
        }}>
        <span
          style={{
            position: 'absolute',
            left: xTick + '%',
            transform: 'translateX(-40%)',
          }}>
          {totalScore.toFixed(1)}
        </span>
      </div>
      <svg
        className="mb-5em mt-5em"
        width="100%"
        height={svgHeight + 'px'}
        style={{ borderRadius: '10px' }}>
        {levels.map((level, i) => {
          left += last_left;
          last_left = level.width;
          const lighten =
            xTick >= left && xTick <= left + level.width ? '' : 'lighten-';
          return (
            <rect
              key={i}
              className={'fill-' + lighten + 'sqrp-' + level.color}
              x={left + '%'}
              y="7.5%"
              width={level.width + '%'}
              height="85%"
            />
          );
        })}
        <rect
          className="fill-black"
          x={xTick + '%'}
          y="0"
          width="7px"
          rx="3px"
          ry="3px"
          height="100%"
        />
      </svg>
      <div className={styles.legend}>
        <span>
          <Lang textKey="lowAchieving" />
        </span>
        <span>
          <Lang textKey="highAchieving" />
        </span>
      </div>
      <p className={`${styles.percentile} text-center`}>
        <Lang
          textKey="betterThan"
          values={[totalScore.toFixed(1), levelRating]}
        />
      </p>
    </div>
  );
};

export default SchoolScore;
