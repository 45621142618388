import React from 'react';

// Context
import { useAccordionsContext } from '../../../context/AccordionsContext';

// Reducers
import {
  CLOSE_ALL,
  EXPAND_ALL,
  TOGGLE_ACCORDION,
} from '../../../reducers/accordions';

// Components
import Lang from '../../../components/Lang';
import AccordionTab from '../../../components/AccordionTab';
import MetricGrid, { MetricElement, MetricRow } from './MetricGrid';
import MetricDetail from './MetricDetail';

// Utilities
import { get, isEmpty, last } from 'lodash';
import metricDetailText from '../../../services/metricDetails';

// Styles
import styles from '../profile.module.scss';

const HighSchoolMetrics = () => {
  const { state, dispatch } = useAccordionsContext();
  const { accordions } = state;

  const areAnyAccordionsExpanded =
    Object.keys(accordions).filter(accordion => accordions[accordion]).length >
    0;

  const onAccordionsToggle = (areAccordionsExpanded: boolean) => () => {
    const actionType = areAccordionsExpanded ? CLOSE_ALL : EXPAND_ALL;
    dispatch({ type: actionType });
  };

  return (
    <div>
      <h2 className="text-uppercase">
        <Lang textKey="highSchoolMetricsTitle" />
      </h2>
      <p>
        <Lang textKey="highSchoolMetricsDescription" />
      </p>
      <div className={styles.accordionsToggler}>
        <span onClick={onAccordionsToggle(areAnyAccordionsExpanded)}>
          <span>
            {areAnyAccordionsExpanded ? (
              <Lang textKey="closeAccordions" />
            ) : (
              <Lang textKey="expandAccordions" />
            )}
          </span>
          <i
            className={`fa fa-fw fa-chevron-${
              areAnyAccordionsExpanded ? 'up' : 'down'
            }`}
          />
        </span>
      </div>
      <AccordionTab
        headerTextKey="studentPerformanceOnStandardizedTestsAccordion"
        className={styles.schoolProfileAccordion}
        headerWrapperClassName={styles.accordionWrapper}
        headerClassName={styles.accordionHeader}
        overlayClassName={styles.overlayBackground}
        showCloseButton
        handleClick={() =>
          dispatch({
            type: TOGGLE_ACCORDION,
            payload: 'studentPerformance',
          })
        }
        isOpen={accordions['studentPerformance']}>
        <MetricRow>
          {({ openedElement, schoolData }) => {
            const validElements = [
              'average_sat_score',
              'sat11_cohort_growth_percentile',
            ];
            const satMetrics = get(schoolData, 'sat_test', []);

            const Details = (openedElement: string) => {
              if (!validElements.includes(openedElement)) {
                return null;
              }

              if ('sat11_cohort_growth_percentile' === openedElement) {
                return <MetricDetail dataKey={openedElement} />;
              }

              if (isEmpty(satMetrics)) {
                return null;
              }

              const recentSATMetrics = last(satMetrics);
              const averageMathScore = get(
                recentSATMetrics,
                'average_math_score'
              );
              const averageEBRWSCore = get(
                recentSATMetrics,
                'average_ebrw_score'
              );

              const title = get(metricDetailText, [openedElement, 'title'], '');

              return (
                <div className={styles.detailContainer + ' __tour4__'}>
                  <h4>
                    <Lang textKey="averageSATScoreTitle" />
                  </h4>
                  <p>
                    <Lang textKey="averageSATScoreDesc" />
                  </p>
                  <br />
                  <p>
                    <strong>
                      <Lang textKey="averageMathSATScore" />
                    </strong>
                    : {averageMathScore}
                  </p>
                  <p>
                    <strong>
                      <Lang textKey="averageEBRWSATScore" />
                    </strong>
                    : {averageEBRWSCore}
                  </p>
                  <p>
                    <Lang textKey="metricAccountsFor" values={[title, 0]} />
                  </p>
                </div>
              );
            };

            return (
              <>
                <div className={styles.gridRow}>
                  <MetricElement
                    langKey="averageSATScoreTitle"
                    left
                    dataKey="average_sat_score">
                    {({ schoolData, isSelected, colorClass }) => {
                      const satMetrics = get(schoolData, 'sat_test', []);
                      const recentSATMetrics = last(satMetrics);
                      const compositeScore = get(
                        recentSATMetrics,
                        'average_composite_score'
                      );

                      return (
                        <>
                          <div className={styles.elementHighlight}>
                            <h5>
                              <Lang textKey="averageSATScoreTitle" />
                            </h5>
                            <p className={colorClass}>{compositeScore}</p>
                          </div>
                          <div className={styles.detailArrowWrapper}>
                            <span>
                              <Lang textKey="details" />
                            </span>
                            <i
                              className={
                                'fa fa-chevron-' + (isSelected ? 'up' : 'down')
                              }
                            />
                          </div>
                        </>
                      );
                    }}
                  </MetricElement>
                  <MetricElement
                    langKey="SATGrowthTitle"
                    left
                    dataKey="sat11_cohort_growth_percentile">
                    <h5>
                      <Lang textKey="SATGrowthTitle" />
                    </h5>
                  </MetricElement>
                </div>
                <div className="__tour4__">{Details(openedElement)}</div>
              </>
            );
          }}
        </MetricRow>
      </AccordionTab>
      <AccordionTab
        className={styles.schoolProfileAccordion}
        headerTextKey="studentsOnStandardizedTests"
        headerWrapperClassName={styles.accordionWrapper}
        headerClassName={styles.accordionHeader}
        overlayClassName={styles.overlayBackground}
        showCloseButton
        handleClick={() =>
          dispatch({ type: TOGGLE_ACCORDION, payload: 'priorityGroups' })
        }
        isOpen={accordions['priorityGroups']}>
        <MetricRow>
          <MetricElement
            langKey="africanAmerican"
            left
            dataKey="sat11_african_american_cohort_growth_percentile">
            <h5>
              <Lang textKey="africanAmerican" />
            </h5>
          </MetricElement>
          <MetricElement
            langKey="latinoHispanicStudents"
            dataKey="sat11_hispanic_cohort_growth_percentile">
            <h5>
              <Lang textKey="latinoHispanicStudents" />
            </h5>
          </MetricElement>
        </MetricRow>
        <MetricRow>
          <MetricElement
            langKey="englishLanguageLearners"
            left
            dataKey="sat11_english_learner_cohort_growth_percentile">
            <h5>
              <Lang textKey="englishLanguageLearners" />
            </h5>
          </MetricElement>
          <MetricElement
            langKey="diverseLearners"
            dataKey="sat11_diverse_learner_cohort_growth_percentile">
            <h5>
              <Lang textKey="diverseLearners" />
            </h5>
          </MetricElement>
        </MetricRow>
      </AccordionTab>
      <AccordionTab
        className={`${styles.schoolProfileAccordion} __school_profile_5__`}
        headerTextKey="studentsGraduationProgress"
        headerWrapperClassName={styles.accordionWrapper}
        headerClassName={styles.accordionHeader}
        overlayClassName={styles.overlayBackground}
        showCloseButton
        handleClick={() =>
          dispatch({
            type: TOGGLE_ACCORDION,
            payload: 'progressTowardsGraduation',
          })
        }
        isOpen={accordions['progressTowardsGraduation']}>
        <MetricRow>
          <MetricElement
            langKey="freshmenOnTrackRate"
            className={`__school_profile_6__`}
            dataKey="freshmen_on_track_rate">
            <h5>
              <Lang textKey="freshmenOnTrackRate" />
            </h5>
          </MetricElement>
          <MetricElement
            langKey="fourYearGraduationRate"
            left
            dataKey="4_year_cohort_graduation_rate"
            className="__tour3__">
            <h5>
              <Lang textKey="fourYearGraduationRate" />
            </h5>
          </MetricElement>
        </MetricRow>
      </AccordionTab>
      <AccordionTab
        className={styles.schoolProfileAccordion}
        headerTextKey="studentsCollegePreparedness"
        headerWrapperClassName={styles.accordionWrapper}
        headerClassName={styles.accordionHeader}
        overlayClassName={styles.overlayBackground}
        showCloseButton
        handleClick={() =>
          dispatch({
            type: TOGGLE_ACCORDION,
            payload: 'preparationForCollege',
          })
        }
        isOpen={accordions['preparationForCollege']}>
        <MetricRow>
          <MetricElement
            langKey="collegeReadinessBenchmarks"
            left
            dataKey="percent_meeting_college_readiness_benchmarks">
            <h5>
              <Lang textKey="collegeReadinessBenchmarks" />
            </h5>
          </MetricElement>
          <MetricGrid.Element
            langKey="earlyCollegeCredentials"
            dataKey="%_earning_early_college_and_career_credentials">
            <h5>
              <Lang textKey="earlyCollegeCredentials" />
            </h5>
          </MetricGrid.Element>
        </MetricRow>
        <MetricRow>
          <MetricElement
            langKey="collegeEnrollmentRate"
            dataKey="college_enrollment_rate">
            <h5>
              <Lang textKey="collegeEnrollmentRate" />
            </h5>
          </MetricElement>
          <MetricElement
            langKey="collegePersistenceRate"
            left
            dataKey="college_persistence_rate">
            <h5>
              <Lang textKey="collegePersistenceRate" />
            </h5>
          </MetricElement>
        </MetricRow>
      </AccordionTab>
      <AccordionTab
        className={styles.schoolProfileAccordion}
        headerTextKey="studentsAttendance"
        headerWrapperClassName={styles.accordionWrapper}
        headerClassName={styles.accordionHeader}
        overlayClassName={styles.overlayBackground}
        showCloseButton
        handleClick={() =>
          dispatch({
            type: TOGGLE_ACCORDION,
            payload: 'schoolClimateCulture',
          })
        }
        isOpen={accordions['schoolClimateCulture']}>
        <MetricRow>
          <MetricElement
            langKey="avgDailyAttendance"
            left
            dataKey="average_daily_attendance_rate">
            <h5>
              <Lang textKey="avgDailyAttendance" />
            </h5>
          </MetricElement>
          <MetricElement
            langKey="schoolClimateAndCulture"
            dataKey="my_voice_my_school_5_essentials_survey">
            <h5>
              <Lang textKey="schoolClimateAndCulture" />
            </h5>
          </MetricElement>
        </MetricRow>
      </AccordionTab>
      <div className={styles.accordionsToggler}>
        <span onClick={onAccordionsToggle(areAnyAccordionsExpanded)}>
          <span>
            {areAnyAccordionsExpanded ? (
              <Lang textKey="closeAccordions" />
            ) : (
              <Lang textKey="expandAccordions" />
            )}
          </span>
          <i
            className={`fa fa-fw fa-chevron-${
              areAnyAccordionsExpanded ? 'up' : 'down'
            }`}
          />
        </span>
      </div>
    </div>
  );
};

export default HighSchoolMetrics;
