import React, { FC } from 'react';

// Types
import { LoadingProps } from './types';

// Styles
import styles from './loading.module.scss';

const LoadingIcon: FC<LoadingProps> = props => {
  const { fixedCenter, className = styles.blue, children } = props;

  return (
    <div
      className={`${styles.wrapper} ${
        fixedCenter ? styles.fixedCenter : null
      } ${className}`}>
      <div className={styles.loader}>
        <svg className={styles['circular-loader']} viewBox="25 25 50 50">
          <circle
            className={styles['loader-path']}
            cx="50"
            cy="50"
            r="20"
            fill="none"
            stroke="#70c542"
            strokeWidth="2"
          />
        </svg>
        {children}
      </div>
    </div>
  );
};

LoadingIcon.defaultProps = {
  fixedCenter: false,
};

export default LoadingIcon;
