import React from 'react';

// Components
import Lang from '../../components/Lang';
import ToggleLang from '../../components/ToggleLang';

// Types
import { TourStep } from '../../components/Tour/types';

// Tour default options
import { createTourSteps } from '../../services/tourSteps';

// Styles
const homepageSteps: TourStep[] = [
  {
    title: <Lang textKey="homeTourStepOneTitle" />,
    content: (
      <div>
        <ToggleLang className="rounded-button">
          <Lang textKey="toggleLanguage" />
        </ToggleLang>
        <p>
          <Lang textKey="homeTourStepTwoDescription" />
        </p>
      </div>
    ),
    target: 'body',
    placement: 'center',
  },
  {
    title: <Lang textKey="homeTourStepTwoTitle" />,
    content: <Lang textKey="homeTourStepTwoDescription" />,
    target: '.__home_tour_1__',
    placement: 'right',
  },
  {
    title: <Lang textKey="homeTourStepThreeTitle" />,
    content: <Lang textKey="homeTourStepThreeDescription" />,
    target: '.__home_tour_2__',
    placement: 'bottom-start',
    placementBeacon: 'left',
  },
  {
    title: <Lang textKey="homeTourStepThreeTitle" />,
    content: <Lang textKey="homeTourStepThreeDescription" />,
    target: '.__home_tour_3__',
    placement: 'auto',
  },
];

export default createTourSteps(homepageSteps);
