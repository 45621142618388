// Filters
import { defaultFilters } from './index';

// Utilities
import { isEmpty } from 'lodash';
import isWithinBounds from './utils/isWithinBounds';
import Utilities from '../../../services/utilities';
import { Bounds, MapSchool } from '../types';

const bounds = (schools: MapSchool[] = [], filters = defaultFilters) => {
  let schoolsUpdated = [...schools];
  const bounds = filters.bounds as Bounds;

  if (isEmpty(bounds)) {
    return schoolsUpdated;
  }

  schoolsUpdated = schoolsUpdated
    .filter(school => {
      const lat = Utilities.getSchoolLatitude(school);
      const lng = Utilities.getSchoolLongitude(school);

      return isWithinBounds({ lat, lng }, bounds);
    })
    .map(school => ({ ...school, showMarker: true }));

  return schoolsUpdated;
};

export default bounds;
