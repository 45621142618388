// Filters
import { defaultFilters } from './index';

// Utilities
import {
  split,
  toLower,
  trim,
  map,
  forEach,
  get,
  includes,
  filter,
} from 'lodash';
import { MapSchool } from '../types';

const search = (schools: MapSchool[] = [], filters = defaultFilters) => {
  let schoolMatches = [...schools];
  const { searchNameQuery = '' } = filters;

  if (!searchNameQuery) {
    return schoolMatches;
  }

  let words = split(toLower(searchNameQuery), ' ');

  words = filter(words, word => !!word);
  words = map(words, trim);

  schoolMatches = schoolMatches.filter(school => {
    let match = true;

    forEach(words, word => {
      if (!includes(get(school, 'name_lower'), word)) {
        match = false;
      }
    });

    return match;
  });

  return schoolMatches;
};

export default search;
