import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Types
import { UseListenForTour } from '../types';

// Tour utilities
import { doesHashMatchTourHashes } from '../utils';

const useListenForTour = ({
  isTourActive = false,
  setIsTourActive,
}: UseListenForTour) => {
  const history = useHistory();

  useEffect(() => {
    const clearHistoryListener = history.listen(currentLocation => {
      const { hash } = currentLocation;
      const isHashMatchTour = doesHashMatchTourHashes(hash);

      if (isTourActive !== isHashMatchTour) {
        setIsTourActive(isHashMatchTour);
      }
    });

    return () => {
      // Clear history listener when un-mounting
      clearHistoryListener();
    };
  }, [history, isTourActive, setIsTourActive]);
};

export default useListenForTour;
