import React, { FC } from 'react';
import { Link } from 'react-router-dom';

// Context
import { useSchoolContext } from '../../../context/SchoolContext';

// Components
import Lang from '../../../components/Lang';

// Custom Hooks
import useReadableLanguage from '../../../hooks/useReadableLanguage';

// Mixpanel
import { trackCustomEvent } from '../../../services/mixpanelTracking';

// Utils
import { get } from 'lodash';

// Styles
import styles from '../profile.module.scss';

interface ProfileNavProps {}

const ProfileNav: FC<ProfileNavProps> = props => {
  const schoolData = useSchoolContext();
  const language = useReadableLanguage();
  const schoolID = get(schoolData, 'school_id');
  const group = get(schoolData, 'group');

  return (
    <div className={styles.profileNav}>
      <Link
        className={styles.navChevron}
        onClick={() => {
          trackCustomEvent('Navigated To Search', {
            action: 'click',
            trigger: 'Back to search',
            language,
            description: 'Link to navigated back to school search',
            location: 'School profile navigation bar',
          });
        }}
        to="/map">
        <i className="fa fa-angle-left" />
        <span>
          <Lang textKey="back" />
        </span>
      </Link>
      <div className={styles.navSchoolName}>
        <Link className="white" to={'/' + group + '/' + schoolID + '#tour'}>
          <Lang textKey="schoolViewTakeTour" />
        </Link>
      </div>
    </div>
  );
};

export default ProfileNav;
