import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

// Styles
import './index.scss';
import 'font-awesome/css/font-awesome.min.css';

// Components
import AppProvider from './components/AppProvider';
import ComparePopup from './components/ComparePopup';
import ScrollToTop from './components/ScrollToTop';

// Views
import Home from './views/Home';
import HighSchoolFullReport from './views/FullReport/HighSchool';
import ElemSchoolFullReport from './views/FullReport/ElemSchool';
import MapView from './views/Map';
import CompareView from './views/Compare';
import SchoolProfile from './views/Profile/SchoolProfile';
import DataTransparency from './views/DataTransparency';
import PopupModal from './components/Popup';

const App = () => {
  return (
    <AppProvider>
      <BrowserRouter>
        <ScrollToTop>
          <PopupModal />
          <ComparePopup />
          <Switch>
            <Route exact path="/map" component={MapView} />
            <Route
              exact
              path="/high-school/:schoolID"
              component={SchoolProfile}
            />
            <Route
              exact
              path="/elementary-school/:schoolID"
              component={SchoolProfile}
            />
            <Route exact path="/combo/:schoolID" component={SchoolProfile} />
            <Route
              exact
              path="/high-school/:schoolID/full-report"
              component={HighSchoolFullReport}
            />
            <Route
              exact
              path="/elementary-school/:schoolID/full-report"
              component={ElemSchoolFullReport}
            />
            <Route
              exact
              path="/data-transparency"
              component={DataTransparency}
            />
            <Route path="/compare/:group?" component={CompareView} />
            <Route component={Home} />
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    </AppProvider>
  );
};

export default App;
