import React, { FC } from 'react';

import s from './footer.module.scss';
import Lang from '../Lang';

const Footer: FC = props => {
  return (
    <div className={s.wrapper}>
      <div className={s.container}>
        <img src="/assets/images/full-logo.png" width="200px" alt="" />
        <div className={s.widget}>
          <h4 className="mb-25em">
            <Lang textKey="kidsFirstChicago" />
          </h4>
          <p className="mt-25em">info@kidsfirstchicago.org</p>
        </div>
        <div className={s.widget}>
          <h4 className="mb-25em">
            <Lang textKey="generalInquiries" />
          </h4>
          <p className="mt-25em">312-853-1212</p>
        </div>
        <div className={s.widget}>
          <h4 className="mb-25em">
            <Lang textKey="address" />
          </h4>
          <p className="mt-25em">
            <Lang textKey="k1cAddress" />
          </p>
        </div>
        <div className={s.widget}>
          <h4 className="mb-25em">
            <Lang textKey="enrollmentSupportHotline" />
          </h4>
          <p className="mt-25em">312-212-5800</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
