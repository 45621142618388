import React, { FC } from 'react';
import PropTypes from 'prop-types';

// Services
import metricDetails from '../../../services/metricDetails';

// Components
import Lang from '../../../components/Lang';

// Utils
import { get } from 'lodash';

import styles from '../profile.module.scss';

interface GrowthAttainmentDetailProps {
  dataKey: string;
  metricType: string;
}

const GrowthAttainmentDetail: FC<GrowthAttainmentDetailProps> = props => {
  const { metricType } = props; // in this case, this key will always be the survey results

  const langKey = get(metricDetails, [metricType, 'titleKey'], '');

  return (
    <div className={styles.detailContainer}>
      <h3>
        <Lang textKey={langKey} />
      </h3>
      <h4>What is the difference between growth and attainment?</h4>
      <p>
        Growth and attainment are two ways to look at student performance.
        Growth measures how student test scores changed from last year. Growth
        is less influenced by income and race. Attainment is how high the
        students score on the test that year. Another way to think about
        attainment is to consider whether students are performing at grade
        level.
      </p>
      <p>
        Growth counts for 45% and attainment counts for 15% of a school’s SQRP
        score. If growth—or learning—is very high, but test scores are low, it
        is still possible for a school to have a high-rating.
      </p>
    </div>
  );
};

GrowthAttainmentDetail.propTypes = {
  dataKey: PropTypes.string.isRequired,
  metricType: PropTypes.oneOf(['growth', 'attainment']).isRequired,
};

GrowthAttainmentDetail.defaultProps = {
  metricType: 'growth',
};

export default GrowthAttainmentDetail;
