import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

// Context
import SchoolContext from '../../context/SchoolContext';

// Components
import Loading from '../../components/Loading';
import GroupBreakdown from './components/GroupBreakdown';
import GroupItem from './components/GroupItem';
import NavBar from '../../components/NavBar';

// Custom Hooks
import useSchoolData from '../../hooks/useSchoolData';
import useDocumentMetaData from '../../hooks/useDocumentMetaData';
import useRatingSchema from '../../hooks/useRatingSchema';

// Utils
import { get } from 'lodash';

// Styles
import styles from './full-report.module.scss';

const HighSchool: FC = props => {
  const { schoolID } = useParams();

  const schoolData = useSchoolData(schoolID);
  const [activeGroup, setActiveGroup] = useState('SATIndicators');

  useDocumentMetaData(schoolData);
  useRatingSchema(schoolData);

  if (!schoolData) return <Loading fixedCenter />;

  const name = get(schoolData, 'long_name', get(schoolData, 'school_name'));

  return (
    <div>
      <NavBar />
      <div className="pl-5em pr-5em">
        <h3>{name}</h3>
        <SchoolContext.Provider value={schoolData}>
          <div className={styles.groupTabsContainer}>
            <GroupItem
              icon="fa-line-chart"
              text="College Readiness"
              colorFromGroup="collegeReadiness"
              onClick={() => setActiveGroup('collegeReadiness')}
              active={activeGroup === 'collegeReadiness'}
            />
            <GroupItem
              icon="fa-list"
              text="SAT Indicators"
              colorFromGroup="SATIndicators"
              onClick={() => setActiveGroup('SATIndicators')}
              active={activeGroup === 'SATIndicators'}
            />
            <GroupItem
              icon="fa-graduation-cap"
              text="Progress To Graduation"
              colorFromGroup="progressToGraduation"
              active={activeGroup === 'progressToGraduation'}
              onClick={() => setActiveGroup('progressToGraduation')}
            />
            <GroupItem
              icon="fa-globe"
              text="Other"
              colorFromGroup="otherHS"
              onClick={() => setActiveGroup('otherHS')}
              active={activeGroup === 'otherHS'}
            />
          </div>
          {activeGroup === 'progressToGraduation' && (
            <GroupBreakdown groupKey="progressToGraduation" />
          )}
          {activeGroup === 'SATIndicators' && (
            <GroupBreakdown groupKey="SATIndicators" />
          )}
          {activeGroup === 'collegeReadiness' && (
            <GroupBreakdown groupKey="collegeReadiness" />
          )}
          {activeGroup === 'otherHS' && <GroupBreakdown groupKey="otherHS" />}
        </SchoolContext.Provider>
      </div>
    </div>
  );
};

export default HighSchool;
