import React, { useState, useCallback, useMemo, FC } from 'react';

// Types
import { TourProps } from './types';

// React Router
import { useHistory, useLocation } from 'react-router-dom';

// Components
import Joyride from 'react-joyride';
import Lang from '../Lang';

// Hooks
import useListenForTour from './hooks/useListenForTour';
import useTourCallback from './hooks/useTourCallback';

// Utilities
import { doesHashMatchTourHashes } from './utils';

// @help https://docs.react-joyride.com/props
// @help https://docs.react-joyride.com/styling

// $ui-blue: #13b1df;
const styles = {
  options: {
    primaryColor: '#13b1df',
    width: 350,
    zIndex: 999999,
  },
};

const Tour: FC<TourProps> = props => {
  const {
    dispatch,
    steps,
    onTourStart = () => {},
    onTourInit = () => {},
  } = props;
  const locale = useMemo(
    () => ({
      next: <Lang textKey="tourNextButton" />,
      back: <Lang textKey="tourBackButton" />,
      last: <Lang textKey="tourLastButton" />,
      skip: <Lang textKey="tourSkipButton" />,
      close: <Lang textKey="tourCloseButton" />,
    }),
    []
  );
  const history = useHistory();
  const { hash, pathname: pathName } = useLocation();

  const [isTourActive, setIsTourActive] = useState(() => {
    return doesHashMatchTourHashes(hash);
  });

  const [stepIndex, setStepIndex] = useState(() =>
    hash === '#continueTour' ? 1 : 0
  );

  const onTourFinish = useCallback(() => {
    history.push(pathName);
    setIsTourActive(false);
    setStepIndex(0);
  }, [pathName, setIsTourActive, setStepIndex, history]);

  useListenForTour({ isTourActive, setIsTourActive });

  const newNextTourCardCallback = useTourCallback({
    setRunTour: setIsTourActive,
    dispatch,
    setStepIndex,
    onFinish: onTourFinish,
    onStart: onTourStart,
    onInit: onTourInit,
  });

  return (
    <Joyride
      steps={steps}
      stepIndex={stepIndex}
      run={isTourActive}
      callback={newNextTourCardCallback}
      styles={styles}
      continuous
      showProgress
      disableOverlayClose
      scrollToFirstStep
      spotlightClicks={false}
      locale={locale}
    />
  );
};

export default Tour;
