import React, { FC, ReactElement, useState } from 'react';

// Components
import Tab from './Tab';

// Styles
import tabStyles from './tabs.module.scss';

// Types
import { TabsProps, TabProps } from './types';

const Tabs: FC<TabsProps> = props => {
  const {
    defaultTab = '',
    children,
    activeTab: controlledActiveTab,
    onTabClick: controlledTabClick = (tabKey: string) => {},
    tabsClassName = '',
  } = props;
  const [activeTab, setActiveTab] = useState(defaultTab);

  // check if control prop has been passed
  const isActiveTabControlled = controlledActiveTab != null;

  // Allow active tab to be controlled via a passed prop;
  const currentTab = isActiveTabControlled ? controlledActiveTab : activeTab;

  const onTabClick = (tabKey: string) => {
    if (!isActiveTabControlled) {
      setActiveTab(tabKey);
    }

    controlledTabClick(tabKey);
  };

  const childrenThatCanBeRendered = React.Children.toArray(
    children
  ).filter(child => React.isValidElement(child));

  return (
    <div>
      <div className={`${tabStyles.tabItems} ${tabsClassName}`}>
        {childrenThatCanBeRendered.map((child, index: number) => {
          const { props } = child as ReactElement<TabProps>;
          const { className, tabKey, translationKey } = props;

          return (
            <Tab
              className={className}
              tabKey={tabKey}
              isActive={currentTab === tabKey}
              key={index}
              onClick={onTabClick}
              translationKey={translationKey}
            />
          );
        })}
      </div>
      <div className={tabStyles.tabContent}>
        {childrenThatCanBeRendered.map((child, index: number) => {
          const { props } = child as ReactElement<TabProps>;
          const { tabKey, children } = props;

          if (currentTab !== tabKey) {
            return null;
          }

          return children;
        })}
      </div>
    </div>
  );
};

export default Tabs;
