import { useEffect, useState } from 'react';

// Utilities
import { debounce } from 'lodash';
import { NoOp } from '../types';
import { WindowDimensions } from '../context/WindowDimensions';

const windowDims = (): WindowDimensions => ({
  height: window.innerHeight,
  width: window.innerWidth,
});

/**
 * Get current window dimensions
 *
 * Courtesy of:
 * @see https://github.com/pdeona/responsive-layout-hooks/blob/master/src/components/WindowDimensionsProvider/index.js
 *
 * @param {number} timeout
 * @returns {{width : number, height : number}}
 */
const useWindowDimensions = (timeout: number = 0): WindowDimensions => {
  const [dimensions, setDimensions] = useState(() => windowDims());

  useEffect(() => {
    let handleResize: NoOp;

    handleResize = debounce(() => setDimensions(windowDims()), timeout);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setDimensions, timeout]);

  return dimensions;
};

export default useWindowDimensions;
