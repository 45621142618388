/*
holds the required data for the metric groups, their names and component parts
*/

const metricGroups = {
  progressToGraduation: {
    name: 'Progress To Graduation',
    description: `High School Graduation Inicators are Lorem ipsum dolor sit amet, case eleifend honestatis 
    ut mel. Noster aliquam nominati ea est. Vim an error quaeque appetere, vix nominati indoctum dignissim ad.
     An his quodsi eloq.`,
    metrics: [
      '4-Year Cohort Graduation Rate',
      'Freshmen On-Track Rate',
      '1-Year Dropout Rate',
    ],
  },
  SATIndicators: {
    name: 'SAT Indicators',
    description: `The SAT, or "Scholastic Aptitude Test", is intended to assess students'
     readiness for college.`,
    metrics: [
      'PSAT09 Cohort Growth Percentile',
      'PSAT10 EBRW Annual Growth Percentile',
      'PSAT10 MATH Annual Growth Percentile',
      'Percent Meeting College Readiness Benchmarks',
      'SAT11 African-American Cohort Growth  Percentile',
      'SAT11 Cohort Growth Percentile',
      'SAT11 Diverse Learner Cohort Growth  Percentile',
      'SAT11 EBRW Annual Growth Percentile',
      'SAT11 Hispanic Cohort Growth  Percentile',
      'SAT11 MATH Annual Growth Percentile',
      'SAT11English Learner Cohort Growth  Percentile',
    ],
  },
  collegeReadiness: {
    name: 'College and Career Readiness Indicators',
    description: `These indicators Lorem ipsum dolor sit amet, case eleifend honestatis 
    ut mel. Noster aliquam nominati ea est. Vim an error quaeque appetere, vix nomi.`,
    metrics: [
      'College Persistence Rate',
      'College Enrollment Rate',
      '% Earning Early College and Career Credentials',
    ],
  },
  otherHS: {
    name: 'Other Indicators',
    description: ``,
    metrics: [
      'Average Daily Attendance Rate',
      'My Voice My School 5 Essentials Survey',
      'Data Quality Index Score',
    ],
  },
  studentGrowth: {
    name: 'Student Growth',
    description: ``,
    metrics: [
      'National School Growth Percentile - Math',
      'National School Growth Percentile - Reading',
    ],
  },
  studentAttainment: {
    name: 'Student Attainment',
    description: ``,
    metrics: [
      'National School Attainment %ile - Math (Grade 2)',
      'National School Attainment %ile - Reading (Grade 2)',
      'National School Attainment %ile - Math (Grades 3-8)',
      'National School Attainment %ile - Reading (Grades 3-8)',
    ],
  },
  attendance: {
    name: 'Average Attendance',
    description: ``,
    metrics: ['Average Daily Attendance Rate (Grades K-8)'],
  },
  schoolClimate: {
    name: 'School Climate',
    description: ``,
    metrics: ['My Voice My School 5 Essentials Survey'],
  },
};

export default metricGroups;
