import { useState, useEffect } from 'react';

// Utilities
import { isEmpty } from 'lodash';

// Types
import { MapSchool } from '../../../reducers/map/types';

const useSetIsMapLoading = (map: any, filteredSchools: MapSchool[]) => {
  const [isMapLoading, setIsMapLoading] = useState(true);

  useEffect(() => {
    if (!isEmpty(map) && filteredSchools.length > 0) {
      setIsMapLoading(false);
    }
  }, [setIsMapLoading, map, filteredSchools]);

  return [isMapLoading, setIsMapLoading];
};

export default useSetIsMapLoading;
