import { get, isEmpty } from 'lodash';
import { Filter, FilterGroup } from '../../types';

/**
 * Create a flatten array of filters of provided `filterGroup`.
 *
 * Ex:
 *        { central: { isChecked: false, ..., children: [ { name: 'loop', label: 'Loop' }, ...] } }
 *
 *              becomes
 *
 *        [ { name: 'loop', label: 'Loop', ... }, ...]
 *
 * @param filterGroup {{}}
 * @param checked {boolean}
 * @param prop {string}
 * @returns {*[]}
 */
const getFiltersFromFilterGroup = (
  filterGroup: FilterGroup,
  checked = false,
  prop = 'name'
) => {
  let filterFunction = checked
    ? (filter: Filter) => filter.isChecked
    : () => true;

  return Object.keys(filterGroup).reduce((acc: any[], filter) => {
    let children = get(filterGroup, [filter, 'children'], []);

    // If `filterGroup` has a `children' property the returned array will contain all `children`.
    // Otherwise it'll return an object with a single entry of the top level filter of `filterGroup`
    if (isEmpty(children)) {
      const filterObj = get(filterGroup, filter);
      children = [
        {
          name: filterObj.name,
          value: filterObj.value,
          isChecked: filterObj.isChecked,
          label: filterObj.name,
        },
      ];
    }

    return [
      ...acc,
      ...children
        .filter(filterFunction)
        .map((filter: Filter) => get(filter, prop)),
    ];
  }, []);
};

export default getFiltersFromFilterGroup;
