import React, { createContext, Dispatch, FC, useContext } from 'react';

// Types
import { NoOp } from '../types';
import {
  AccordionsState,
  AccordionDispatchAction,
} from '../reducers/accordions/types';

export interface AccordionsContextValue {
  state: AccordionsState;
  dispatch: Dispatch<AccordionDispatchAction> | NoOp;
}

const AccordionsContext = createContext<AccordionsContextValue>({
  state: { accordions: {} },
  dispatch: () => {},
});

export const useAccordionsContext = () => useContext(AccordionsContext);

export const AccordionsContextProvider: FC<{
  value: AccordionsContextValue;
}> = ({ value, children }) => {
  return (
    <AccordionsContext.Provider value={value}>
      {children}
    </AccordionsContext.Provider>
  );
};
