import React from 'react';

// Components
import Lang from '../../components/Lang';
import ToggleLang from '../../components/ToggleLang';

// Utilities
import { createTourSteps } from '../../services/tourSteps';

// Types
import { TourStep } from '../../components/Tour/types';

export const tourStepTargetKeys = {
  stepOne: '.__school_profile_1__',
  stepTwo: '.__school_profile_2__',
  stepThree: '.__school_profile_3__',
  stepFour: '.__school_profile_4__',
  stepFive: '.__school_profile_5__',
  stepSix: '.__school_profile_6__',
  stepSeven: '.__school_profile_7__',
};

const tourSteps: TourStep[] = [
  {
    target: tourStepTargetKeys.stepOne,
    title: <Lang textKey="schoolProfileTourStepOneTitle" />,
    content: (
      <div>
        <ToggleLang className="rounded-button">
          <Lang textKey="toggleLanguage" />
        </ToggleLang>
        <p>
          <Lang textKey="schoolProfileTourStepOneDescription" />
        </p>
      </div>
    ),
  },
  {
    target: tourStepTargetKeys.stepTwo,
    title: <Lang textKey="schoolProfileTourStepTwoTitle" />,
    content: <Lang textKey="schoolProfileTourStepTwoDescription" />,
  },
  {
    target: tourStepTargetKeys.stepThree,
    title: <Lang textKey="schoolProfileTourStepThreeTitle" />,
    content: <Lang textKey="schoolProfileTourStepThreeDescription" />,
  },
  {
    target: tourStepTargetKeys.stepFour,
    title: <Lang textKey="schoolProfileTourStepFourTitle" />,
    content: <Lang textKey="schoolProfileTourStepFourDescription" />,
    placement: 'top',
  },
  {
    target: tourStepTargetKeys.stepFive,
    title: <Lang textKey="schoolProfileTourStepFiveTitle" />,
    content: <Lang textKey="schoolProfileTourStepFiveDescription" />,
    placement: 'top-start',
  },
  {
    target: tourStepTargetKeys.stepSix,
    title: <Lang textKey="schoolProfileTourStepSixTitle" />,
    content: <Lang textKey="schoolProfileTourStepSixDescription" />,
    placement: 'top',
  },
  {
    target: tourStepTargetKeys.stepSeven,
    title: <Lang textKey="schoolProfileTourStepSevenTitle" />,
    content: <Lang textKey="schoolProfileTourStepSevenDescription" />,
  },
];

export default createTourSteps(tourSteps);
