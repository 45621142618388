// Filters
import search from './search';
import score from './score';
import distance from './distance';
import schoolType from './schoolType';
import grade from './grade';
import bounds from './bounds';
import ctePrograms from './ctePrograms';
import communityArea from './communityArea';
import programs from './programs';

// Types
import { FilterFunction, Filters, MapSchool } from '../types';

export const defaultFilters: Filters = {
  searchNameQuery: '',
  distance: '',
  scores: {
    'Level 1+': { value: 'level-one-plus', isChecked: false },
    'Level 1': { value: 'level-one', isChecked: false },
    'Level 2+': { value: 'level-two-plus', isChecked: false },
    'Level 2': { value: 'level-two', isChecked: false },
    'Level 3': { value: 'level-three', isChecked: false },
  },
  grades: {
    preschool: {
      name: 'preschool',
      value: 'preschool',
      label: 'Preschool (PK)',
      isChecked: false,
    },
    'elementary-school': {
      name: 'elementary-school',
      value: 'elementary-school',
      label: 'Elementary School (ES)',
      isChecked: false,
      children: [
        {
          name: 'K',
          value: 'K',
          label: 'K',
          isChecked: false,
        },
        {
          name: '1',
          value: '1',
          label: '1',
          isChecked: false,
        },
        {
          name: '2',
          value: '2',
          label: '2',
          isChecked: false,
        },
        {
          name: '3',
          value: '3',
          label: '3',
          isChecked: false,
        },
        {
          name: '4',
          value: '4',
          label: '4',
          isChecked: false,
        },
        {
          name: '5',
          value: '5',
          label: '5',
          isChecked: false,
        },
        {
          name: '6',
          value: '6',
          label: '6',
          isChecked: false,
        },
        {
          name: '7',
          value: '7',
          label: '7',
          isChecked: false,
        },
        {
          name: '8',
          value: '8',
          label: '8',
          isChecked: false,
        },
      ],
    },
    'high-school': {
      name: 'high-school',
      value: 'high-school',
      label: 'High School (HS)',
      isChecked: false,
      children: [
        {
          name: '9',
          value: '9',
          label: '9',
          isChecked: false,
        },
        {
          name: '10',
          value: '10',
          label: '10',
          isChecked: false,
        },
        {
          name: '11',
          value: '11',
          label: '11',
          isChecked: false,
        },
        {
          name: '12',
          value: '12',
          label: '12',
          isChecked: false,
        },
      ],
    },
  },
  bounds: {},
  /**
   * This will have the following shape:
   *
   *  'school-type-key': {
   *    name: 'school-type-key',
   *    value: 'school-type-key',
   *    label: 'School Type',
   *    isChecked: false
   *  }
   *
   */
  schoolTypes: {},
  programs: {},
  /**
   * This will have the following shape:
   *
   *  'community-area-key': {
   *    name: 'community-area-key',
   *    value: 'community-area-key',
   *    label: 'Community Area',
   *    isChecked: false
   *  }
   *
   */
  communityAreas: {},
  userLocation: { marker: {}, address: '', position: {} },
  ctePrograms: {},
};

const composeFilters = (...fns: FilterFunction[]) => (
  schools: MapSchool[] = [],
  filters: Filters = defaultFilters
) =>
  fns.reduceRight(
    (schools: MapSchool[], fn) => fn([...schools], { ...filters }),
    [...schools]
  );

/**
 * All filter functions MUST support the following two parameters:
 *
 *      - schools (an array of all the school data)
 *      - filters (an object that contains all the filter data of search page)
 *        @see defaultFilters
 *
 * All functions MUST be pure
 *
 * @type {Function}
 */
const applyFilters = composeFilters(
  bounds,
  search,
  grade,
  score,
  distance,
  schoolType,
  programs,
  ctePrograms,
  communityArea
);

export const filterSchools = (
  schools: MapSchool[] = [],
  newFilters = defaultFilters
) => {
  return applyFilters(schools, newFilters);
};
