import React, { useRef, useEffect, FC } from 'react';
import PropTypes from 'prop-types';

// Context
import { useSchoolContext } from '../../../context/SchoolContext';

// Services
import metricDetailText from '../../../services/metricDetails';
import makeChart from '../../../services/makeMetricHistoryChart';

// Components
import Lang from '../../../components/Lang';

// Utilities
import { get, isEmpty } from 'lodash';

// Styles
import styles from '../profile.module.scss';
import { YearRecord } from '../types';

interface MetricDetailProps {
  dataKey: string;
}

const MetricDetail: FC<MetricDetailProps> = props => {
  const { dataKey } = props;
  const canvasElement = useRef<HTMLCanvasElement>(null);

  const schoolData = useSchoolContext();
  const metricData = get(schoolData, dataKey) as YearRecord[];

  const chartData = { values: [], points: [], years: [] } as {
    values: (string | null)[];
    points: (string | null)[];
    years: string[];
  };

  useEffect(() => {
    const chart = canvasElement.current;
    // check whether a chart is displayed
    // if no chart, do nothing
    if (chart) {
      const ctx = chart.getContext('2d') as CanvasRenderingContext2D;
      makeChart(chartData, ctx);
    }
  }, [chartData]);

  if (isEmpty(metricData)) {
    return null;
  }

  const thisYear = metricData[metricData.length - 1];
  let { weight } = thisYear;
  // @ts-ignore
  weight = +weight;

  // prepare data for makeChart function,
  // values: data values (percentiles, rates, etc.);
  // points: 1-5 sqrp points given (needed for coloring-in data points)

  metricData.forEach((yearRecord: YearRecord) => {
    if (yearRecord.score && yearRecord.points) {
      chartData.values.push(yearRecord.score);
      chartData.points.push(yearRecord.points);
    } else {
      chartData.values.push(null);
      chartData.points.push(null);
    }

    chartData.years.push(yearRecord.year);
  });

  const Chart = () => (
    <div className="mt-5em mr-5em ml-5em" style={{ height: '200px' }}>
      <canvas ref={canvasElement} />
    </div>
  );

  const NoData = () => (
    <p>
      <strong>
        <Lang textKey="noDataOnThisMetric" />
      </strong>
    </p>
  );

  const titleLangKey = get(metricDetailText, [dataKey, 'titleKey']);
  const descriptionLangKey = get(metricDetailText, [dataKey, 'descriptionKey']);
  const metricTitle = get(metricDetailText, [dataKey, 'title']);

  return (
    <div className={styles.detailContainer + ' __tour4__'}>
      <h4 className="mt-0 mb-25em">
        <Lang textKey={titleLangKey} />
      </h4>
      <p className="smaller mb-0 mt-25em">
        <Lang textKey={descriptionLangKey} />
      </p>
      {thisYear.score ? <Chart /> : <NoData />}
      <p className="mb-0">
        <Lang textKey="metricAccountsFor" values={[metricTitle, weight]} />
      </p>
    </div>
  );
};

MetricDetail.propTypes = {
  dataKey: PropTypes.string.isRequired,
};

export default MetricDetail;
