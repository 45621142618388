import React, { FC, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

// context
import GoogleMapContext from './context/GoogleMapContext';

// Custom Hooks
import useGoogleMap from './useGoogleMap';
import useMapEventListeners from './useMapEventListeners';

// Utilities
import mapEventNames from './EventNames/map';
import { isEmpty } from 'lodash';
import camelize from './utils/camelize';

// Types
import { GoogleMapProps, GoogleMapsMap, Options } from './types';

const GoogleMap: FC<GoogleMapProps> = props => {
  const {
    containerID = 'map',
    center = {
      lat: 41.8781,
      lng: -87.6298,
    },
    zoom = 14,
    mapHeight = 500,
  } = props;

  // We only want the options to be set when the component mounts
  const mapOptions: Options = useMemo(
    () => ({ containerID, center, zoom }),
    [] //eslint-disable-line
  );

  const map = useGoogleMap(mapOptions) as GoogleMapsMap;
  useMapEventListeners(props, map);

  useEffect(() => {
    if (!isEmpty(map)) {
      // Ensure `bounds_changed` event is triggered in order
      // for map state to be stored correctly in global store
      window.google.maps.event.trigger(map, 'bounds_changed');
    }
  }, [map]);

  function renderChildren() {
    return !isEmpty(map) && !isEmpty(window.google) ? props.children : null;
  }

  const context = { map, google: window.google };

  return (
    <GoogleMapContext.Provider value={context}>
      <div id={containerID} style={{ height: `${mapHeight}px` }} />
      {renderChildren()}
    </GoogleMapContext.Provider>
  );
};

const eventListenerDefaults = (eventNames: string[]) => {
  const obj: any = {};

  eventNames.forEach(
    eventName => (obj[`on${camelize(eventName)}`] = PropTypes.func)
  );

  return obj;
};

GoogleMap.propTypes = {
  containerID: PropTypes.string,
  markers: PropTypes.array,
  center: PropTypes.object,
  ...eventListenerDefaults(mapEventNames),
};

GoogleMap.defaultProps = {
  containerID: 'map',
  center: {
    lat: 41.8781,
    lng: -87.6298,
  },
  zoom: 14,
};

export default GoogleMap;
