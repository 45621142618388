import React, { FC } from 'react';

// Context
import { useLanguageContext } from '../../context/LanguageContext';

// Services
import { langKeyMap } from '../LangContextProvider';

import { LangProps } from './types';

// Utilities
import _ from 'lodash';

/**
 * React Component for adding translated text
 *
 * @param textKey
 * @param values
 * @returns {string|null|*}
 * @constructor
 */
const Lang: FC<LangProps> = ({ textKey, values = [] }) => {
  const { lang, translationValues } = useLanguageContext();
  const langKey = (langKeyMap as any)[lang];

  if (_.isEmpty(translationValues)) {
    return null;
  }

  if (!_.has(translationValues, textKey)) {
    //throw new Error( `invalid translation text key: ${textKey}` );
    console.error(`translations for key ${textKey} missing from CSV`);
    return <>{`translations for key ${textKey} missing from CSV`}</>;
  }

  if (!_.has(translationValues, [textKey, langKey])) {
    console.error(`missing ${langKey} for ${textKey} translation`);

    return <>{`missing ${langKey} for ${textKey} translation`}</>;
  }

  let translation = _.get(translationValues, [textKey, langKey]);

  if (!_.isEmpty(values) && /(%s|%d)/g.test(translation)) {
    if (!_.isArray(values)) {
      values = [values];
    }

    const matches = translation.match(/(%s|%d)/g);
    if (_.size(matches) !== _.size(values)) {
      console.error(
        'Translation string placeholder count does not match value count: ',
        translation,
        matches
      );
      return <>{translation}</>;
    }

    _.forEach(matches, (match, i: number) => {
      translation = translation.replace(match, values[i]);
    });
  }

  if (!_.includes(translation, 'href=')) {
    return <>{translation}</>;
  }

  return (
    <>
      <span dangerouslySetInnerHTML={{ __html: translation }} />
    </>
  );
};

export default Lang;
