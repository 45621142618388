// Filters
import { defaultFilters, filterSchools } from './filters';

// Utilities
import { get, isEmpty, orderBy } from 'lodash';

// Types
import { MapSchool, MapState, MapStateAction, SortByFunctions } from './types';

export const mapDefaultState: MapState = {
  schools: [],
  filteredSchools: [],
  searchNameQuery: '',
  map: {},
  userLocation: { marker: {}, address: '', position: {} },
  center: {
    lat: 41.8781,
    lng: -87.6298,
  },
  zoom: 10,
  sortBy: 'score',
  showMap: true,
  filters: defaultFilters,
};

const sortByFunctions: SortByFunctions = {
  score: [
    (school: MapSchool) =>
      get(school, ['sqrp_total_points_earned', 0, 'value']),
  ],
  name: [
    (school: MapSchool) =>
      get(school, 'long_name', get(school, 'school_name')).toLowerCase(),
  ],
  distance: [(school: MapSchool) => get(school, 'relativeDist', 0)],
};

export const SHOW_INFO_WINDOW = 'SHOW_INFO_WINDOW';
export const CLOSE_INFO_WINDOW = 'CLOSE_INFO_WINDOW';
export const UPDATE_BOUNDS = 'UPDATE_BOUNDS';
export const FILTER_SCHOOLS = 'FILTER_SCHOOLS';
export const SET_USER_LOCATION = 'SET_USER_LOCATION';
export const SORT_SCHOOLS = 'SORT_SCHOOLS';
export const TOGGLE_SEARCH_VIEW = 'TOGGLE_SEARCH_VIEW';
export const RESET = 'RESET';
export const SET = 'SET';

export const schoolReducer = (
  state: MapState = mapDefaultState,
  action: MapStateAction
) => {
  const { type = '' } = action;

  switch (type) {
    case SHOW_INFO_WINDOW:
    case CLOSE_INFO_WINDOW: {
      const {
        payload: { schoolID },
      } = action;
      const { filteredSchools } = state;

      const schoolsUpdated = filteredSchools.map(school => {
        const { school_id } = school;

        // This will close any currently opened Info Windows
        // Only ONE can be open at a single time
        const showInfoWindow =
          CLOSE_INFO_WINDOW === type && schoolID === school_id
            ? false
            : schoolID === school_id;

        return { ...school, showInfoWindow };
      });

      return { ...state, filteredSchools: schoolsUpdated };
    }
    // TODO: Merge 'UPDATE_BOUNDS', 'FILTER_SCHOOLS', 'SET_USER_LOCATION'
    case UPDATE_BOUNDS: {
      const {
        payload: { bounds, zoom, center, map },
      } = action;
      const { schools, filters } = state;

      const newFilters = { ...filters, bounds };

      const schoolsUpdated = filterSchools(schools, newFilters);

      return {
        ...state,
        filteredSchools: schoolsUpdated,
        center,
        zoom,
        map,
        filters: newFilters,
      };
    }
    case FILTER_SCHOOLS: {
      const {
        payload: { filters },
      } = action;
      const { schools } = state;

      const schoolsFiltersApplied = filterSchools(schools, filters);

      return { ...state, filteredSchools: schoolsFiltersApplied, filters };
    }
    case SET_USER_LOCATION: {
      const {
        payload: { userLocation },
      } = action;
      const { schools, filters } = state;

      const newFilters = { ...filters, userLocation };

      const schoolsUpdated = filterSchools(schools, newFilters);

      return {
        ...state,
        userLocation,
        filteredSchools: schoolsUpdated,
        filters: newFilters,
      };
    }
    case SORT_SCHOOLS: {
      const {
        payload: { sortSchoolsBy },
      } = action;
      const { filteredSchools } = state;

      const schoolsSorted = orderBy(
        filteredSchools,
        sortByFunctions[sortSchoolsBy],
        'score' === sortSchoolsBy ? ['desc'] : ['asc']
      );

      return {
        ...state,
        filteredSchools: schoolsSorted,
        sortBy: sortSchoolsBy,
      };
    }
    case TOGGLE_SEARCH_VIEW: {
      const payload = get(action, 'payload', null);
      const { showMap } = state;
      let newShowMapValue;

      if (!isEmpty(payload)) {
        newShowMapValue = get(payload, 'showMap');
      } else {
        newShowMapValue = !showMap;
      }

      // Don't re-render components if 'showMap' value has not changed
      // Unnecessary re-render can be very 'expensive'
      if (newShowMapValue === showMap) {
        return state;
      }

      return { ...state, showMap: newShowMapValue };
    }
    case RESET: {
      const { defaultFilters, schools } = state;

      return {
        ...state,
        ...mapDefaultState,
        schools,
        filteredSchools: schools,
        filters: { ...defaultFilters },
        defaultFilters,
      };
    }
    case SET:
      const {
        payload: {
          schoolData,
          schoolTypes,
          communityAreas,
          programs,
          ctePrograms,
        },
      } = action;
      const { sortBy, filters } = state;

      const newFilters = {
        ...filters,
        schoolTypes,
        communityAreas,
        programs,
        ctePrograms,
      };

      const mutatedAllSchoolData = orderBy(
        schoolData.map((school: any) => ({
          ...school,
          showMarker: true,
          showInfoWindow: false,
          name_lower: get(
            school,
            'long_name',
            get(school, 'school_name')
          ).toLowerCase(),
        })),
        sortByFunctions[sortBy],
        ['desc']
      );

      return {
        ...state,
        schools: mutatedAllSchoolData,
        filteredSchools: mutatedAllSchoolData,
        filters: { ...newFilters },
        defaultFilters: { ...newFilters },
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};
