import camelize from './camelize';
import { MVCObject } from '../types';

/**
 * Handle a callback passed into a component via a support event prop
 *
 * Ex: <Marker onClick={() => console.log('Marker was clicked')}/>
 *
 * @param {string} eventName
 * @param {Object} props
 * @param {MVCObject} obj   all objects that inherit from Google Maps Javascript API MVCObject constructutor
 *
 * @see: https://developers.google.com/maps/documentation/javascript/reference/event#MVCObject
 */
const handleEvent = (eventName: string, props: any, obj: MVCObject) => {
  let timeout: NodeJS.Timeout | null;
  const handlerName = `on${camelize(eventName)}`;

  return (e: any) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }

    timeout = setTimeout(() => {
      if (props[handlerName]) {
        props[handlerName](props, obj, e);
      }
    }, 0);
  };
};

export default handleEvent;
