import React, { FC, useCallback, useState } from 'react';

// Context
import { AccordionContext } from './context/AccordionContext';

// Components
import Lang from '../Lang';

// Styles
import styles from './accordionTab.module.scss';
import { AccordionProps } from './types';

const AccordionTab: FC<AccordionProps> = props => {
  const {
    isOpen,
    handleClick,
    headerTextKey,
    icon,
    utilizeState,
    image = 0,
    className = '',
    headerWrapperClassName = '',
    overlayClassName = '',
    headerClassName = '',
    bodyClassName = '',
    showCloseButton,
    children,
  } = props;

  const [tabIsOpen, setTabIsOpen] = useState(() => (isOpen ? isOpen : false));

  const bgImages = ['bgImage1', 'bgImage2', 'bgImage3'];

  const isTabOpen = utilizeState ? tabIsOpen : isOpen;

  const thisImage = bgImages[image];

  const onClickHandler = useCallback(() => {
    if (handleClick) {
      handleClick();
    }

    if (utilizeState) {
      setTabIsOpen(isTapOpen => !isTapOpen);
    }
  }, [handleClick, setTabIsOpen, utilizeState]);

  const renderProps = {
    isTabOpen,
    handleClick,
    headerTextKey,
    icon,
    image,
    utilizeState,
    className,
    headerWrapperClassName,
    overlayClassName,
    headerClassName,
    showCloseButton,
    bodyClassName,
    onClickHandler,
    accordionStyles: styles,
  };

  if ('function' === typeof children) {
    return children(renderProps);
  }

  return (
    <div className={`${styles.card} ${className} ${isTabOpen ? 'open' : ''}`}>
      <div
        className={`${styles[thisImage]} ${styles.bg} ${headerWrapperClassName}`}
        onClick={onClickHandler}>
        <div className={`${styles.imageOverlay} ${overlayClassName}`} />
        <h2 className={`${styles.headerContent} ${headerClassName}`}>
          <div>
            {icon && <i className={'fa fs-12em mr-5em ' + icon} />}
            <span>
              <Lang textKey={headerTextKey} />
            </span>
          </div>
          <div>
            <i
              className={
                'fa fa-fw fa-chevron-down ' + (isTabOpen && styles.rotate)
              }
              style={{ transformOrigin: 'center' }}
            />
          </div>
        </h2>
      </div>
      <AccordionContext.Provider
        value={{ toggleAccordion: onClickHandler, ...renderProps }}>
        {isTabOpen && (
          <>
            <div className={`${styles.body} ${bodyClassName}`}>
              {props.children}
            </div>
            {showCloseButton && (
              <div className={styles.closeAccordion} onClick={onClickHandler}>
                Close Section <i className="fa fa-fw fa-chevron-up" />
              </div>
            )}
          </>
        )}
      </AccordionContext.Provider>
    </div>
  );
};

AccordionTab.defaultProps = {
  isOpen: false,
  utilizeState: false,
  handleClick: () => {},
  showCloseButton: false,
  className: '',
};

export default AccordionTab;
