import React, { FC } from 'react';

// Styles
import styles from './card.module.scss';

interface CardProps {
  className?: string;
}

const Card: FC<CardProps> = ({ className = '', children }) => {
  return <div className={`${styles.card} ${className}`}>{children}</div>;
};

export default Card;
