import { useEffect, useState } from 'react';
import axios from '../api';

// Types
import { SetStateFunction } from '../types';

type APISuccess<S> = {
  success: true;
  data: S;
};

interface APIError<E> {
  success: false;
  data: E;
}

export interface Translation {
  _id: string;
  key: string;
  original: string;
  translations: {
    [propName: string]: string;
  };
  origin: string;
}

interface Success {
  currentPage: number;
  perPage: number;
  total: number;
  translations: Translation[];
}

interface Error {
  message: string;
}

type APIResponse<S, E> = APISuccess<S> | APIError<E>;

const useTranslations = (): [
  Translation[],
  SetStateFunction<Translation[]>
] => {
  const [translations, setTranslations] = useState<Translation[]>([]);

  useEffect(() => {
    const fetchTranslations = async (): Promise<Translation[]> => {
      const { data: responseBody } = await axios.get<
        APIResponse<Success, Error>
      >('/translations');

      if (!responseBody.success) {
        throw new Error(`Received an unsuccessful server response.`);
      }

      const { translations } = responseBody.data;
      return translations;
    };

    fetchTranslations()
      .then(translations => {
        setTranslations(translations);
      })
      .catch(err => {
        console.error('An error occurred when fetching translations', err);
      });
  }, [setTranslations]);

  return [translations, setTranslations];
};

export default useTranslations;
