import React, { FC } from 'react';

// Components
import Lang from '../Lang';

import { TabProps } from './types';

// Styles
import tabStyles from './tabs.module.scss';

const Tab: FC<TabProps> = props => {
  const {
    isActive = false,
    className = '',
    tabKey,
    onClick = (tabKey: string) => {},
    translationKey,
  } = props;

  const elementClasses = `${tabStyles.tabItem} ${
    isActive ? tabStyles.activeTab : ''
  } ${className}`;

  const onClickHandler = () => {
    onClick(tabKey);
  };

  return (
    <span onClick={() => onClickHandler()} className={elementClasses}>
      <Lang textKey={translationKey} />
    </span>
  );
};

export default Tab;
