import React, { FC } from 'react';
import { Link, Redirect, useParams } from 'react-router-dom';

// Store
import { useStoreSelector } from '../../context/StoreContext';

// Components
import Lang from '../../components/Lang';
import NavBar from '../../components/NavBar';
import GroupLabel from './components/GroupLabel';
import QuickLinks from '../../components/QuickLinks';
import CompareDetails from './components/CompareDetails';
import Tour from '../../components/Tour';

// Custom Hooks
import useTrackPageVisit from '../../hooks/useTrackPageVisit';
import useReadableLanguage from '../../hooks/useReadableLanguage';

// Tour Steps
import compareTourSteps from './tourSteps';

// Mixpanel
import { trackCustomEvent } from '../../services/mixpanelTracking';

// Utilities
import {
  includes,
  keys,
  forEach,
  set,
  get,
  size,
  pickBy,
  isEmpty,
} from 'lodash';

// Styles
import styles from './compare.module.scss';

const CompareView: FC = props => {
  const compare = useStoreSelector(store => store.compare);
  const language = useReadableLanguage();
  const [compareState] = compare;
  const dispatch = compare[1];
  let { group = '' } = useParams();

  useTrackPageVisit('Compare');

  let groups = {
    'high-school': 0,
    'elementary-school': 0,
  };

  // Check if current `group` Router params exists in `groups`
  // This is check in case the user visits a route that ISN'T
  // 'elementary-school` or `high-school`
  if (!isEmpty(group) && !includes(keys(groups), group)) {
    return <Redirect to="/compare" />;
  }

  forEach(groups, (sizeOfGroup: number, key: string) => {
    // `compareState` at a `key` (i.e. 'elementary-school')
    // will be an array of school IDs (as strings): [ '400010', '400011', ... ]
    // `groups` will then looks like the following:
    // { 'elementary-school': 2, 'high-school': 0 }
    set(groups, key, size(get(compareState, key)));
  });

  // Check if current `group` param has no selected schools to compare
  if (0 === get(groups, group)) {
    group = '';
  }

  // If there's no group set, AND
  if (!group) {
    // Select group(s) with at least one school selected for Compare
    const withSchools = pickBy(groups);

    if (1 === size(withSchools)) {
      group = get(keys(withSchools), [0]);
      // Force user redirect to school with AT LEAST two schools to compare
      return <Redirect to={'/compare/' + group} />;
    } else {
      return (
        <>
          <Tour dispatch={dispatch} steps={compareTourSteps.compareTourSteps} />
          <NavBar />
          <QuickLinks />
          <div
            className="__compare_1__ pt-2em pb-2em"
            style={{ background: 'white' }}>
            <h3 className="text-center mt-0">
              <Lang textKey="compareChooseHeading" />
            </h3>
            <div className="text-center pb-1em">
              <Link to="/compare/high-school">
                <span className={styles.button}>
                  <Lang
                    textKey="compareHighSchoolsButton"
                    values={[groups['high-school']]}
                  />
                </span>
              </Link>
            </div>
            <div className="text-center">
              <Link to="/compare/elementary-school">
                <span className={styles.button}>
                  <Lang
                    textKey="compareElementarySchoolsButton"
                    values={[groups['elementary-school']]}
                  />
                </span>
              </Link>
            </div>
            <div className="text-center">
              <Link
                onClick={() => {
                  trackCustomEvent('Navigated To Search', {
                    action: 'click',
                    trigger: 'Add Anoter School',
                    language,
                    description: 'Link to add another school to compare',
                    location: 'Compare view',
                  });
                }}
                to="/map"
                className={styles.resultItem + ' ' + styles.addAnotherSchool}>
                <div>
                  <Lang textKey="addAnotherSchool" />
                </div>
              </Link>
            </div>
          </div>
        </>
      );
    }
  }

  return (
    <>
      <Tour
        dispatch={dispatch}
        steps={compareTourSteps.compareDetailsTourSteps}
      />
      <NavBar />
      <QuickLinks />
      <div>
        <h2 className="text-center">
          Compare <GroupLabel quantity={2} />
        </h2>
        <CompareDetails group={group} />
      </div>
    </>
  );
};

export default CompareView;
