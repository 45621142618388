// Filters
import { defaultFilters } from './index';

// Utilities
import { isEmpty, get } from 'lodash';
import utilities from '../../../services/utilities';
import { MapSchool } from '../types';

const schoolType = (schools: MapSchool[] = [], filters = defaultFilters) => {
  let schoolsUpdated = [...schools];
  const { schoolTypes } = filters;

  // Only mutate schools IFF 'School Type' filters are set.
  // This is merely precautionary since 99.99999% of the time they WILL be.
  if (isEmpty(schoolTypes)) {
    return schoolsUpdated;
  }

  const schoolTypesFilters = Object.keys(schoolTypes).filter(
    schoolType => schoolTypes[schoolType].isChecked
  );

  // For 'School Type' filter type, having ALL or NONE should return the same result
  // In this case, just return schools unfiltered
  if (
    isEmpty(schoolTypesFilters) ||
    Object.keys(schoolTypes).length === schoolTypesFilters.length
  ) {
    return schoolsUpdated;
  }

  schoolsUpdated = schoolsUpdated.filter(school => {
    const { school_type: schoolType = '' } = school;
    const schoolTypeKey = utilities.createObjectKey(schoolType);

    return get(schoolTypes, [schoolTypeKey, 'isChecked'], false);
  });

  return schoolsUpdated;
};

export default schoolType;
