// Types
import { AccordionDispatchAction, AccordionsState } from './types';

// Mixpanel tracking
import { trackAccordionOpened } from '../../services/mixpanelTracking';

// Utilities
import { has } from 'lodash';

export const elementaryAccordionsDefaultState: AccordionsState = {
  accordions: {
    priorityGroups: false,
    otherIndicators: false,
    nweaMapAttainmentIndicators: false,
    nweaMapGrowthIndicators: false,
    growthVersusAttainment: false,
  },
};

export const highSchoolAccordionsDefaultState: AccordionsState = {
  accordions: {
    progressTowardsGraduation: false,
    preparationForCollege: false,
    schoolClimateCulture: false,
    priorityGroups: false,
    studentPerformance: false,
  },
};

export const EXPAND_ALL = 'EXPAND_ALL';
export const CLOSE_ALL = 'CLOSE_ALL';
export const OPEN_ACCORDION = 'OPEN_ACCORDION';
export const TOGGLE_ACCORDION = 'TOGGLE_ACCORDION';

export const accordionsReducer = (
  state: AccordionsState,
  action: AccordionDispatchAction
) => {
  const { type = '' } = action;

  switch (type) {
    case EXPAND_ALL: {
      const { accordions } = state;
      const accordionsUpdated = { ...accordions };

      Object.keys(accordionsUpdated).forEach(accordion => {
        accordionsUpdated[accordion] = true;
      });

      return { ...state, accordions: accordionsUpdated };
    }
    case CLOSE_ALL: {
      const { accordions } = state;
      const accordionsUpdated = { ...accordions };

      Object.keys(accordionsUpdated).forEach(accordion => {
        accordionsUpdated[accordion] = false;
      });

      return { ...state, accordions: accordionsUpdated };
    }
    case OPEN_ACCORDION:
    case TOGGLE_ACCORDION: {
      const { payload: accordion = '' } = action;
      const { accordions } = state;

      if (!has(accordions, accordion)) {
        return state;
      }

      const accordionsUpdated = { ...accordions };
      accordionsUpdated[accordion] = !accordionsUpdated[accordion];

      trackAccordionOpened(accordion);

      if (OPEN_ACCORDION === type) {
        accordionsUpdated[accordion] = true;
      }

      return { ...state, accordions: accordionsUpdated };
    }
    default:
      return state;
  }
};
