// Utils
import { get, isEmpty, last } from 'lodash';

// Types
import { Metric } from '../index';
import { MapSchool } from '../../../../../reducers/map/types';

/**
 * Sort passed metric array into three distinct arrays:
 *
 *    High Scores: 4 <= x <= 5
 *    Medium Scores: x == 3
 *    Low Scores: 1 <= x <= 2
 *
 * @param metrics {[]}
 * @param school {{}}
 * @return {[[], [], []]}
 */
const sortMetrics = (metrics: Metric[], school: MapSchool) => {
  const highScores: Metric[] = [];
  const mediumScores: Metric[] = [];
  const lowScores: Metric[] = [];

  metrics.forEach(metric => {
    const { metric: metricName } = metric;
    const metricHistory = get(school, metricName, []);

    if (isEmpty(metricHistory)) {
      return;
    }

    const metricCurrentYear = last(metricHistory);
    let points = get(metricCurrentYear, 'points', '');

    if (isEmpty(points)) {
      return;
    }

    points = parseInt(points);

    /**
     * Sort metric 'points' value by the following point system:
     *
     *
     */
    if (points <= 2) {
      lowScores.push(metric);
    } else if (points === 3) {
      mediumScores.push(metric);
    } else {
      highScores.push(metric);
    }
  });

  return [lowScores, mediumScores, highScores];
};

export default sortMetrics;
