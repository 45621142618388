// Filters
import { defaultFilters } from './index';

// Utilities
import { isEmpty, get } from 'lodash';
import utilities from '../../../services/utilities';
import { MapSchool } from '../types';

const distance = (schools: MapSchool[] = [], filters = defaultFilters) => {
  let schoolsUpdated = [...schools];
  const { distance, userLocation } = filters;

  // This 'Distance' filter type can only be applied IFF the user has set their location
  if (isEmpty(userLocation)) {
    return schoolsUpdated;
  }

  schoolsUpdated = schoolsUpdated.map(school => {
    const lat = utilities.getSchoolLatitude(school);
    const lng = utilities.getSchoolLongitude(school);
    const relativeDist = utilities.calcDistance(userLocation.position, {
      lat,
      lng,
    });

    return { ...school, relativeDist };
  });

  if (!distance) {
    return schoolsUpdated;
  }

  schoolsUpdated = schoolsUpdated.filter(school => {
    const relativeDist = get(school, 'relativeDist', 0);

    return relativeDist <= +distance;
  });

  return schoolsUpdated;
};

export default distance;
