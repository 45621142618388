import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Custom Hooks
import useReadableLanguage from './useReadableLanguage';

// Mixpanel
import { trackCustomEvent } from '../services/mixpanelTracking';

const useTrackPageVisit = (page: string) => {
  const location = useLocation();
  const language = useReadableLanguage();

  useEffect(() => {
    trackCustomEvent('Page Visit', {
      action: 'page visit',
      trigger: page,
      language,
      description: `The user visited '${location.pathname}'`,
      location: location.pathname,
    });
  }, []); //eslint-disable-line
};

export default useTrackPageVisit;
