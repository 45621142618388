import React from 'react';

// Components
import Lang from '../../components/Lang';
import ToggleLang from '../../components/ToggleLang';

// Utilities
import { createTourSteps } from '../../services/tourSteps';

// Types
import { TourStep } from '../../components/Tour/types';

export const tourDesktopStepKeys = {
  stepOne: '.__search_desktop_1__',
  stepTwo: '.__search_desktop_2__',
  stepThree: '.__search_desktop_3__',
  stepFour: '.__search_desktop_4__',
  stepFive: '.__search_desktop_4__',
};

export const tourMobileStepKeys = {
  stepOne: '.__search_mobile_1__',
  stepTwo: '.__search_mobile_2__',
  stepThree: '.__search_mobile_3__',
  stepFour: '.__search_mobile_4__',
  stepFive: '#school-list > :first-child',
  stepSix: '#school-list > :first-child > :nth-child(2)',
  stepSeven: '#school-list > :first-child > :last-child',
  stepEight: '#school-list > :first-child',
};

const desktopSteps: TourStep[] = [
  {
    target: tourDesktopStepKeys.stepOne,
    title: <Lang textKey="searchDesktopTourStepOneTitle" />,
    content: (
      <div>
        <ToggleLang className="rounded-button">
          <Lang textKey="toggleLanguage" />
        </ToggleLang>
        <p>
          <Lang textKey="searchDesktopTourStepOneDescription" />
        </p>
      </div>
    ),
  },
  {
    target: tourDesktopStepKeys.stepTwo,
    title: <Lang textKey="searchDesktopTourStepTwoTitle" />,
    content: <Lang textKey="searchDesktopTourStepTwoDescription" />,
  },
  {
    target: tourDesktopStepKeys.stepThree,
    title: <Lang textKey="searchDesktopTourStepThreeTitle" />,
    content: <Lang textKey="searchDesktopTourStepThreeDescription" />,
  },
  {
    target: tourDesktopStepKeys.stepFour,
    title: <Lang textKey="searchDesktopTourStepFourTitle" />,
    content: <Lang textKey="searchDesktopTourStepFourDescription" />,
  },
  {
    target: tourDesktopStepKeys.stepFive,
    title: <Lang textKey="searchDesktopTourStepFiveTitle" />,
    content: '',
    placement: 'right',
  },
];

const mobileSteps: TourStep[] = [
  {
    target: 'body',
    title: <Lang textKey="searchMobileTourStepOneTitle" />,
    content: (
      <div>
        <ToggleLang className="rounded-button">
          <Lang textKey="toggleLanguage" />
        </ToggleLang>
        <p>
          <Lang textKey="searchMobileTourStepOneDescription" />
        </p>
      </div>
    ),
    placement: 'center',
  },
  {
    target: tourMobileStepKeys.stepTwo,
    title: <Lang textKey="searchMobileTourStepTwoTitle" />,
    content: <Lang textKey="searchMobileTourStepTwoDescription" />,
  },
  {
    target: tourMobileStepKeys.stepThree,
    title: <Lang textKey="searchMobileTourStepThreeTitle" />,
    content: <Lang textKey="searchMobileTourStepThreeDescription" />,
  },
  {
    target: tourMobileStepKeys.stepFour,
    title: <Lang textKey="searchMobileTourStepFourTitle" />,
    content: <Lang textKey="searchMobileTourStepFourDescription" />,
  },
  {
    target: tourMobileStepKeys.stepFive,
    title: <Lang textKey="searchMobileTourStepFiveTitle" />,
    content: <Lang textKey="searchMobileTourStepFiveDescription" />,
  },
  {
    target: tourMobileStepKeys.stepSix,
    title: <Lang textKey="searchMobileTourStepSixTitle" />,
    content: <Lang textKey="searchMobileTourStepSixDescription" />,
  },
  {
    target: tourMobileStepKeys.stepSeven,
    title: <Lang textKey="searchMobileTourStepSevenTitle" />,
    content: <Lang textKey="searchMobileTourStepSevenDescription" />,
  },
  {
    target: tourMobileStepKeys.stepEight,
    title: <Lang textKey="searchMobileTourStepEightTitle" />,
    content: '',
  },
];

export const desktopTourSteps = createTourSteps(desktopSteps);
export const mobileTourSteps = createTourSteps(mobileSteps);
