export const Constants = {
  groupTextMap: {
    'high-school': {
      singular: 'High School',
      plural: 'High Schools',
    },
    'elementary-school': {
      singular: 'Elementary School',
      plural: 'Elementary Schools',
    },
  },
};
