// Utils
import { get, isEmpty } from 'lodash';
import utility from '../../../../../services/utilities';

// Types
import { GoogleMapsMap } from '../../../../../components/GoogleMap/types';

const createOnBlurHandler = (
  setUserLocation: ({
    pos: position,
    short_address,
    map,
  }: {
    pos: any;
    short_address: string;
    map: any;
  }) => void
) => {
  let debounce: NodeJS.Timeout;

  return (event: Event, map: GoogleMapsMap) => {
    const address = get(event, ['target', 'value']);

    if (isEmpty(address)) {
      return;
    }

    if (debounce) {
      clearTimeout(debounce);
    }

    // There's a slight delay between SearchBox selection and input value added
    debounce = setTimeout(async () => {
      try {
        const { pos, short_address } = await utility.geocode(
          address.replace(' ', '+')
        );

        setUserLocation({ pos, short_address, map });
      } catch (e) {
        console.error('failed geocode', e);
      }
    }, 200);
  };
};

export default createOnBlurHandler;
