import { useEffect } from 'react';

// Utilities
import { isEmpty } from 'lodash';
import { School } from './useSchoolData';

/**
 * Updates the document meta data to include schema.org rating structured data.
 * Allows Google crawlers to parse 'Review' data type for rich results in search.
 * @param {null | object} schoolData individual school record
 */
const useRatingSchema = (schoolData: School | {}) => {
  useEffect(() => {
    if (isEmpty(schoolData)) {
      return;
    }

    const pointsHistory = (schoolData as School).sqrp_total_points_earned;
    const points = pointsHistory[pointsHistory.length - 1].value;

    const script = document.createElement('script');
    script.id = 'sqrp-rating-schema';
    script.type = 'application/ld+json';
    script.innerHTML = JSON.stringify({
      '@context': 'http://schema.org',
      '@type': 'Review',
      author: {
        '@type': 'Organization',
        name: 'Kids First Chicago',
        logo:
          'https://static1.squarespace.com/static/5b212dce5417fcd9ddec5349/t/5b213dd71ae6cfe4e051924b/1538145105132/?format=1500w',
        url: 'https://kidsfirstchicago.org/',
      },
      reviewRating: {
        '@type': 'Rating',
        worstRating: '0',
        bestRating: '5',
        ratingValue: points,
      },
      itemReviewed: {
        '@type': 'School',
      },
    });
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(script);

    return () => {
      const schemaScript = document.getElementById('sqrp-rating-schema');
      const parentNode = (schemaScript as HTMLElement)
        .parentNode as HTMLElement;

      if (schemaScript) {
        parentNode.removeChild(schemaScript);
      }
    };
  }, [schoolData]);
};

export default useRatingSchema;
