import React, { createContext, FC, useContext } from 'react';
import useTranslations, { Translation } from '../hooks/useTranslations';
import { SetStateFunction } from '../types';

interface TranslationsContextValue {
  translations: Translation[];
  setTranslations: SetStateFunction<Translation[]>;
}

const TranslationsContext = createContext<TranslationsContextValue>({
  translations: [],
  setTranslations: () => {},
});

export const useTranslationsContext = () => useContext(TranslationsContext);
export const TranslationsContextProvider: FC = ({ children }) => {
  const [translations, setTranslations] = useTranslations();
  const context: TranslationsContextValue = { translations, setTranslations };

  return (
    <TranslationsContext.Provider value={context}>
      {children}
    </TranslationsContext.Provider>
  );
};
