// Context
import { useLanguageContext } from '../context/LanguageContext';

// Utils
import { get } from 'lodash';
import { langReadableMap } from '../components/LangContextProvider';

const useReadableLanguage = () => {
  const { lang } = useLanguageContext();

  return get(langReadableMap, lang, 'English');
};

export default useReadableLanguage;
