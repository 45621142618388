import React from 'react';

// Context
import { useSchoolContext } from '../../../../context/SchoolContext';

// Components
import Card from '../../../../components/Card';
import Lang from '../../../../components/Lang';

// Custom Hooks
import useViewportVisibility from '../../../../hooks/useElementIsInViewport';

// Mixpanel
import { trackCustomEvent } from '../../../../services/mixpanelTracking';

// Utilities
import { isEmpty } from 'lodash';
import { highSchoolMetrics, elementarySchoolMetrics } from './utils/metrics';
import sortMetrics from './utils/sortMetrics';

// Styles
import styles from './summaryMetrics.module.scss';
import { MapSchool } from '../../../../reducers/map/types';

export interface Metric {
  metric: string;
  priority: boolean;
  langKey: string;
}

const SummaryMetrics = ({ className = '' }) => {
  const school = useSchoolContext();

  const [
    isElementaryMetricsVisible, // eslint-disable-line
    elementaryMetricsRef,
  ] = useViewportVisibility<HTMLDivElement>(language => {
    trackCustomEvent('School Profile Elementary Summary In View', {
      action: 'scroll',
      trigger: 'User Scroll',
      language,
      description: 'Use scrolled to view Elementary Metrics Summary',
      location: 'School profile',
    });
  });

  const [
    isHighSchoolMetricsVisible, // eslint-disable-line
    highSchoolMetricsRef,
  ] = useViewportVisibility<HTMLDivElement>(language => {
    trackCustomEvent('School Profile High School Summary In View', {
      action: 'scroll',
      trigger: 'User Scroll',
      language,
      description: 'Use scrolled to view High School Metrics Summary',
      location: 'School profile',
    });
  });

  if (isEmpty(school)) {
    return null;
  }

  const { group } = school as MapSchool;

  const renderMetricCard = (metrics: Metric[], cardLabelKey: string) => {
    const [lowScores, averageScores, highScores] = sortMetrics(
      metrics,
      school as MapSchool
    );

    const renderScoreGroup = (
      metrics: { metric: string; priority: boolean; langKey: string }[]
    ) => {
      if (isEmpty(metrics)) {
        return (
          <span>
            <Lang textKey="noMetricsMeetCriteria" />
          </span>
        );
      }

      const nonPriorityMetrics = metrics.filter(metric => !metric.priority);
      const priorityMetrics = metrics.filter(metric => metric.priority);
      const renderMetric = (metric: Metric, index: number) => (
        <span key={index}>
          <Lang textKey={metric.langKey} />
        </span>
      );

      return (
        <>
          {nonPriorityMetrics.map(renderMetric)}
          {0 !== priorityMetrics.length && (
            <h5
              className={`text-uppercase mb-0 ${isEmpty(nonPriorityMetrics) &&
                'mt-0'} ${styles.uiBlueColor}`}>
              <Lang textKey="priorityGroupLabel" />
            </h5>
          )}
          {priorityMetrics.map(renderMetric)}
        </>
      );
    };

    return (
      <Card className={className}>
        <h4 className={`${styles.cardHeader} ${styles.uiBlueColor}`}>
          <Lang textKey={cardLabelKey} />
        </h4>
        <div className={styles.scoreGroup}>
          <div className={`${styles.scoreGroupIcon} ${styles.highScore}`}>
            <i className="fa fa-fw fa-arrow-circle-up" />
          </div>
          <div className={styles.metrics}>
            <h4 className={`${styles.highScore} mt-0 mb-0`}>
              <span className="text-uppercase">
                <Lang textKey="highScore" />
              </span>{' '}
              | 4-5 <Lang textKey="pointsLabel" />
            </h4>
            {renderScoreGroup(highScores)}
          </div>
        </div>
        <div className={styles.scoreGroup}>
          <div className={`${styles.scoreGroupIcon} ${styles.averageScore}`}>
            <i className="fa fa-fw fa-minus-circle" />
          </div>
          <div className={styles.metrics}>
            <h4 className={`${styles.averageScore} mt-0 mb-0`}>
              <span className="text-uppercase">
                <Lang textKey="averageScore" />
              </span>{' '}
              | 3 <Lang textKey="pointsLabel" />
            </h4>
            {renderScoreGroup(averageScores)}
          </div>
        </div>
        <div className={styles.scoreGroup}>
          <div className={`${styles.scoreGroupIcon} ${styles.lowScore}`}>
            <i className="fa fa-fw fa-arrow-circle-down" />
          </div>
          <div className={styles.metrics}>
            <h4 className={`${styles.lowScore} mt-0 mb-0`}>
              <span className="text-uppercase">
                <Lang textKey="lowScore" />
              </span>{' '}
              | 1-2 <Lang textKey="pointsLabel" />
            </h4>
            {renderScoreGroup(lowScores)}
          </div>
        </div>
      </Card>
    );
  };

  return (
    <>
      {('elementary-school' === group || 'combo' === group) && (
        <div ref={elementaryMetricsRef}>
          {renderMetricCard(
            elementarySchoolMetrics,
            'elementarySummaryMetricsLabel'
          )}
        </div>
      )}
      {('high-school' === group || 'combo' === group) && (
        <div ref={highSchoolMetricsRef}>
          {renderMetricCard(highSchoolMetrics, 'highSchoolSummaryMetricsLabel')}
        </div>
      )}
    </>
  );
};

export default SummaryMetrics;
