import { useEffect } from 'react';

const useOpenNavBar = (open: boolean) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.right = '0';
      document.body.style.left = '0';
    } else {
      document.body.style.overflow = '';
      document.body.style.position = '';
    }

    // in case of component unmounting, allow scrolling
    return () => {
      document.body.style.overflow = '';
      document.body.style.position = '';
    };
  }, [open]);
};

export default useOpenNavBar;
