import React, { FC } from 'react';
import PropTypes from 'prop-types';

// Utilities
import { isFunction } from 'lodash';
import useWindowDimensions from '../../hooks/useWindowDimensions';

interface MobileLayoutProps {
  onShow?: () => void;
}

const MobileLayout: FC<MobileLayoutProps> = ({ children, onShow }) => {
  const { width } = useWindowDimensions();

  if (992 < width) {
    return null;
  }

  if (isFunction(onShow)) {
    onShow();
  }

  return <>{children}</>;
};

MobileLayout.propTypes = {
  onShow: PropTypes.func,
};

MobileLayout.defaultProps = {
  onShow: () => {},
};

export default MobileLayout;
