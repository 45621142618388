import { createContext, useContext } from 'react';

// Reducers
import { mapDefaultState } from '../reducers/map';
import { compareDefaultState } from '../reducers/compare';

// Types
import { MapContext } from '../reducers/map/types';
import { CompareContext } from '../reducers/compare/types';

export interface StoreContextValue {
  map: MapContext;
  compare: CompareContext;
}

export interface StoreSelector<T> {
  (store: StoreContextValue): T;
}

const defaultStore: StoreContextValue = {
  map: [mapDefaultState, () => {}],
  compare: [compareDefaultState, () => {}],
};

export const StoreContext = createContext<StoreContextValue>(defaultStore);

export const useStoreValue = () => useContext(StoreContext);

export const useStoreSelector = <T,>(fn: StoreSelector<T>): T => {
  const store = useStoreValue();

  return fn(store);
};
