import { useState, useEffect, RefObject } from 'react';

// Utils
import { offset } from '../utils';

// Types
import { ScrollOffset } from '../types';

const defaultState: ScrollOffset = {
  top: 0,
  height: 0,
  scroll: 0,
};

const useStickyNav = (navRef: RefObject<HTMLElement>) => {
  const [scrollOffset, setScrollOffset] = useState(defaultState);

  useEffect(() => {
    const currentNav = navRef.current as HTMLElement;
    const navOffset = offset(currentNav);

    const handleScroll = () => {
      setScrollOffset(prevState => ({ ...prevState, scroll: window.scrollY }));
    };

    setScrollOffset(prevState => ({
      ...prevState,
      top: navOffset.top,
      height: currentNav.offsetHeight,
    }));

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [navRef]);

  return scrollOffset;
};

export default useStickyNav;
