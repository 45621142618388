import React, { FC, useEffect, useRef } from 'react';

import makeChart from '../../../services/makeComparisonChart';

// Styles
import styles from '../compare.module.scss';

// store chart so can destroy when replaced
let chart: any;

interface CompareChartProps {
  metric: string;
  schoolData: {
    schoolID: string;
    label: string;
    data: number;
    sqrpColor: string;
  }[];
}

const CompareChart: FC<CompareChartProps> = props => {
  const { metric, schoolData } = props;
  const chartElement = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (chartElement.current) {
      const ctx = chartElement.current.getContext(
        '2d'
      ) as CanvasRenderingContext2D;

      if (chart) {
        chart.destroy();
      }

      chart = makeChart(metric, schoolData, ctx);
    }
  }, [metric, schoolData]);

  return (
    <div className={styles.historyChartContainer}>
      <canvas ref={chartElement} />
    </div>
  );
};

export default CompareChart;
