import { useMemo } from 'react';

// Store Context
import { useStoreSelector } from '../../../context/StoreContext';

// Tour Steps
import { mobileTourSteps, tourMobileStepKeys } from '../tourSteps';

// Types
import {
  BeforeStepWithDispatch,
  TourStep,
} from '../../../components/Tour/types';
import { TOGGLE_SEARCH_VIEW } from '../../../reducers/map';
import { SetStateFunction } from '../../../types';

export interface UseMobileTourStepsDependencies {
  setShowFilters: SetStateFunction<boolean>;
}

const useMobileTourSteps = (
  deps: UseMobileTourStepsDependencies
): TourStep[] => {
  const [_, dispatch] = useStoreSelector(store => store.map); // eslint-disable-line
  const { setShowFilters } = deps;

  return useMemo<TourStep[]>(() => {
    return mobileTourSteps.map(tourStep => {
      const { target } = tourStep;

      const listViewTransition: BeforeStepWithDispatch = {
        dispatcher: dispatch,
        action: { type: TOGGLE_SEARCH_VIEW, payload: { showMap: false } },
      };

      const mapViewTransition: BeforeStepWithDispatch = {
        dispatcher: dispatch,
        action: { type: TOGGLE_SEARCH_VIEW, payload: { showMap: true } },
      };

      const modalOpenTransition: BeforeStepWithDispatch = {
        dispatcher: setShowFilters,
        action: true,
      };

      const modalCloseTransition: BeforeStepWithDispatch = {
        dispatcher: setShowFilters,
        action: false,
      };

      if (tourMobileStepKeys.stepTwo === target) {
        const beforeNext: BeforeStepWithDispatch[] = [listViewTransition];

        return { ...tourStep, beforeNext };
      }

      if (tourMobileStepKeys.stepThree === target) {
        const beforePrev: BeforeStepWithDispatch[] = [mapViewTransition];
        const beforeNext: BeforeStepWithDispatch[] = [modalOpenTransition];

        return { ...tourStep, beforePrev, beforeNext };
      }

      if (tourMobileStepKeys.stepFour === target) {
        const beforePrev: BeforeStepWithDispatch[] = [modalCloseTransition];
        const beforeNext: BeforeStepWithDispatch[] = [modalCloseTransition];

        return { ...tourStep, beforePrev, beforeNext };
      }

      if (tourMobileStepKeys.stepFive === target) {
        const beforePrev: BeforeStepWithDispatch[] = [modalOpenTransition];

        return { ...tourStep, beforePrev };
      }

      return tourStep;
    });
  }, [dispatch, setShowFilters]);
};

export default useMobileTourSteps;
