import React, { FC } from 'react';
import './block.scss';

interface BlockProps {
  className?: string;
}

const Block: FC<BlockProps> = ({ children, className = '' }) => (
  <div className={`content-block ${className}`}>{children}</div>
);

export default Block;
