import React, { FC, useContext } from 'react';
import PropTypes from 'prop-types';
import eventNames from '../EventNames/marker';

// Context
import GoogleMapContext from '../context/GoogleMapContext';

// Custom Hooks
import useMarker from './hooks/useMarker';
import useEventListeners from './hooks/useEventListeners';
import useShowMarker from './hooks/useShowMarker';

// Utilities
import camelize from '../utils/camelize';
import { GoogleMapsMap } from '../types';

export interface MarkerProps {
  lat: number;
  lng: number;
  showMarker?: boolean;
  title?: string;
}

const Marker: FC<MarkerProps> = props => {
  const marker = useMarker(props) as google.maps.Marker;
  useEventListeners(marker, props);

  const { map } = useContext(GoogleMapContext);
  const { showMarker = true } = props;

  useShowMarker(showMarker, marker, map as GoogleMapsMap);

  return (
    <>
      {props.children && marker
        ? React.Children.only(
            React.cloneElement(props.children as React.ReactElement, { marker })
          )
        : null}
    </>
  );
};

const eventListenerDefaults = (eventNames: string[]) => {
  const obj: { [prop: string]: any } = {};

  eventNames.forEach(
    eventName => (obj[`on${camelize(eventName)}`] = PropTypes.func)
  );

  return obj;
};

Marker.propTypes = {
  title: PropTypes.string,
  ...eventListenerDefaults(eventNames),
};

Marker.defaultProps = {
  lat: 0,
  lng: 0,
  title: 'Marker Title',
  showMarker: true,
};

export default Marker;
