// Mixpanel
import { trackAdvancedFilterUse } from '../../../services/mixpanelTracking';

// Custom Hooks
import useReadableLanguage from '../../../hooks/useReadableLanguage';

const useMixpanelTrackAdvancedFilterUse = (
  filterKey: string
): ((trigger: string, parentKey?: string) => void) => {
  const language = useReadableLanguage();

  return (trigger: string, parentKey?: string) =>
    trackAdvancedFilterUse(filterKey, trigger, language, parentKey);
};

export default useMixpanelTrackAdvancedFilterUse;
