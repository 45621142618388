import React, { useState, useCallback, useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';

// Store
import { useStoreSelector } from '../../context/StoreContext';

// Custom Hooks
import useSetMarker from './hooks/useSetMarker';
import useTrackPageVisit from '../../hooks/useTrackPageVisit';
import useReadableLanguage from '../../hooks/useReadableLanguage';
import useSetIsMapLoading from './hooks/useSetIsMapLoading';
import useSetUserLocationCallback from './hooks/useSetUserLocationCallback';
import useMobileTourSteps from './hooks/useMobileTourSteps';

// Reducers
import {
  CLOSE_INFO_WINDOW,
  FILTER_SCHOOLS,
  SHOW_INFO_WINDOW,
  SORT_SCHOOLS,
  TOGGLE_SEARCH_VIEW,
  UPDATE_BOUNDS,
} from '../../reducers/map';

// Mixpanel
import { trackCustomEvent } from '../../services/mixpanelTracking';

// Utilities
import { get, isEmpty, isEqual, debounce, capitalize } from 'lodash';
import getRatingLogo from '../../components/GoogleMap/utils/getRatingLogo';
import createOnBlurHandler from './components/SearchFiltersScreen/utils/createOnBlurHandler';
import utilities from '../../services/utilities';

// Components
import Loading from '../../components/Loading';
import GoogleMap from '../../components/GoogleMap';
import Marker from '../../components/GoogleMap/Marker';
import InfoWindow from '../../components/GoogleMap/InfoWindow';
import SearchLocation from './components/SearchLocation';
import Lang from '../../components/Lang';
import OptionWithLang from '../../components/Lang/OptionWithLang';
import QuickLinks from '../../components/QuickLinks';
import NavBar from '../../components/NavBar';
import Tour from '../../components/Tour';
import Modal from '../../components/Modal';
import StickyNav from '../../components/StickyNav';
import ResultItem from '../../components/ResultItem';
import Search from './components/SearchName';
import SearchFilters from './components/SearchFiltersScreen';
import MobileLayout from '../../components/ResponsiveLayout/MobileLayout';
import DesktopLayout from '../../components/ResponsiveLayout/DesktopLayout';
import ToggleLang from '../../components/ToggleLang';

// Tour Steps
import { desktopTourSteps } from './tourSteps';

// Styles
import styles from './map.module.scss';
import loadingStyles from '../../components/Loading/loading.module.scss';
import { MapSchool } from '../../reducers/map/types';

// Types
import { GoogleMapsMap } from '../../components/GoogleMap/types';

const MapView = () => {
  // Global "store" Map state
  const [state, dispatch] = useStoreSelector(store => store.map);
  const {
    userLocation,
    map,
    filteredSchools,
    showMap,
    filters,
    center,
    zoom,
    sortBy,
  } = state;
  const assertedMap = map as GoogleMapsMap;
  const { searchNameQuery } = filters;

  // Localized state
  const [address, setAddress] = useState(() => userLocation.address);
  const [searchName, setSearchName] = useState(() => searchNameQuery);
  const [showPopUp, setShowPopUp] = useState(false);

  const [isMapLoading] = useSetIsMapLoading(map, filteredSchools);

  const language = useReadableLanguage();

  useSetMarker(userLocation, map);
  useTrackPageVisit('Map');

  const onBoundsChanged = useCallback(
    map => {
      const debounced = debounce(() => {
        let bounds = map.getBounds();
        const zoom = map.getZoom();
        const center = map.getCenter();
        const northEastBounds = bounds.getNorthEast();
        const southWestBounds = bounds.getSouthWest();

        bounds = {
          northEastBounds: northEastBounds.toJSON(),
          southWestBounds: southWestBounds.toJSON(),
        };

        dispatch({
          type: UPDATE_BOUNDS,
          payload: {
            bounds,
            zoom,
            center: center.toJSON(),
            map,
          },
        });
      }, 250);

      debounced();
    },
    [dispatch]
  );

  const onNameSearch = useCallback(
    (event, search) => {
      const searchQuery = get(event, ['target', 'value'], '');
      const newFilters = { ...filters, searchNameQuery: searchQuery };

      dispatch({ type: FILTER_SCHOOLS, payload: { filters: newFilters } });

      setSearchName(searchQuery);
    },
    [dispatch, setSearchName, filters]
  );

  const setUserLocation = useSetUserLocationCallback(
    setAddress,
    state,
    dispatch
  );

  const onBlurHandler = createOnBlurHandler(setUserLocation);

  const getVisibleSchoolCount = useCallback(
    (schoolData: MapSchool[]) =>
      schoolData.filter(({ showMarker }) => showMarker).length,
    []
  );

  const addressOnChangeHandler = useCallback(
    event => {
      const address = get(event, ['target', 'value'], '');

      if (isEmpty(event.target)) {
        return;
      }

      setAddress(address);
    },
    [setAddress]
  );

  const renderSchoolMarker = useCallback(
    (school, index) => {
      if (!school.showMarker) {
        return null;
      }

      const recentSQRPRating = utilities.getMostRecentSQRPRating(school);

      const icon = {
        url: getRatingLogo(recentSQRPRating),
        scaledSize: new (window as any).google.maps.Size(35, 39),
      };

      const onInfoWindowClose = (school: any) => {
        const { school_id: schoolID } = school;

        dispatch({
          type: CLOSE_INFO_WINDOW,
          payload: { schoolID: schoolID },
        });
      };

      const onMarkerClick = (school: any) => {
        const { school_id: schoolID } = school;

        dispatch({ type: SHOW_INFO_WINDOW, payload: { schoolID: schoolID } });
      };

      const {
        grades_served,
        enrollment,
        address,
        school_id: schoolID,
        group,
      } = school;

      const name = get(school, 'long_name', get(school, 'school_name'));
      const schoolLink = (
        <a
          className={styles.removeBasicLinkStyles}
          href={`/${group}/${schoolID}`}>
          {name}
        </a>
      );

      let infoWindowContent = `<div>
              <h3 class="mt-0 mb-25em">${ReactDOMServer.renderToString(
                schoolLink
              )}</h3>
          <p class="${styles.schoolDetail}">Grades Served: ${grades_served}</p>
          <p class="${
            styles.schoolDetail
          }">Enrollment: ${enrollment} students</p>
          <p class="${styles.schoolDetail}">${address}</p>
          </div>`;

      const lat = utilities.getSchoolLatitude(school);
      const lng = utilities.getSchoolLongitude(school);

      return (
        <Marker
          {...{
            key: school._id,
            title: school.long_name,
            lat,
            lng,
            icon,
            showMarker: school.showMarker,
            onClick: () => onMarkerClick(school),
          }}>
          <InfoWindow
            showInfoWindow={school.showInfoWindow}
            infoWindowContent={infoWindowContent}
            {...{
              onCloseclick: () => {
                onInfoWindowClose(school);
              },
            }}
          />
        </Marker>
      );
    },
    [dispatch]
  );

  const numberOfActiveFilters = utilities.getActiveFilterCount(filters);

  const togglePopUp = useCallback(() => setShowPopUp(prevState => !prevState), [
    setShowPopUp,
  ]);

  const onSortByChange = useCallback(
    event => {
      trackCustomEvent('Sorted Schools', {
        action: 'selection',
        trigger: capitalize(event.target.value),
        language,
        description: `User changed 'sort by' value`,
        location: 'Map screen',
      });

      dispatch({
        type: SORT_SCHOOLS,
        payload: { sortSchoolsBy: event.target.value },
      });
    },

    [dispatch, language]
  );

  const onToggleSearchView = useCallback(
    () => dispatch({ type: TOGGLE_SEARCH_VIEW }),
    [dispatch]
  );

  const onBoundsChangedHandler = useCallback(
    (props, map, e) => onBoundsChanged(map),
    [onBoundsChanged]
  );

  const TourMemo = useMemo(
    () => <Tour steps={desktopTourSteps} dispatch={dispatch} />,
    [dispatch]
  );

  const mobileTourSteps = useMobileTourSteps({ setShowFilters: setShowPopUp });

  const onSearchLocationFocus = useCallback(() => {
    trackCustomEvent('Address Search', {
      action: 'click',
      trigger: 'Set Your Location',
      language,
      description: `User is using 'set your location' filter`,
      location: 'Map school search screen',
    });
  }, [language]);

  const onSearchNameFocus = useCallback(() => {
    trackCustomEvent('School Name Search', {
      action: 'click',
      trigger: 'Search by school name',
      language,
      description: `Use is using the 'search by school name' filter`,
      location: 'Map school search screen',
    });
  }, [language]);

  if (!filteredSchools) return <Loading fixedCenter />;

  return (
    <>
      <Modal show={showPopUp}>
        <SearchFilters
          address={address}
          searchName={searchName}
          setSearchName={setSearchName}
          setAddress={setAddress}
          setShowPopUp={setShowPopUp}
        />
      </Modal>
      <NavBar />
      <QuickLinks />
      <div className={styles.mainContentWrapper}>
        <DesktopLayout>
          <StickyNav className={`${styles.stickyNav} __search_desktop_1__`}>
            <div className={styles.desktopFilterNav}>
              <div className={styles.desktopFilters}>
                <span>
                  <span className="label">
                    <Lang textKey="setYourLocation" />:
                  </span>
                  <div className={styles.inputWrapper}>
                    <i
                      className="fa fa-fw fa-map-marker mr-5em"
                      aria-hidden="true"
                    />
                    <SearchLocation
                      id="address_input"
                      placeholder="Set Your Location"
                      {...{
                        map: assertedMap,
                        value: address,
                        onChange: addressOnChangeHandler,
                        className: `${styles.search_location}`,
                        onBlur: onBlurHandler,
                        onPlacesChanged: setUserLocation,
                        onFocus: onSearchLocationFocus,
                      }}
                    />
                  </div>
                </span>
                <span>
                  <span className="label">
                    <Lang textKey="searchSchoolButton" />:
                  </span>
                  <Search
                    searchQuery={searchName}
                    onSearchChanged={onNameSearch}
                    onFocus={onSearchNameFocus}
                  />
                </span>
              </div>
              <span>
                <ToggleLang
                  className={
                    styles.removeBasicLinkStyles +
                    ' ' +
                    styles.button +
                    ' ' +
                    styles.whiteOutline
                  }>
                  <Lang textKey="toggleLanguage" />
                </ToggleLang>
                <span
                  className={
                    styles.removeBasicLinkStyles +
                    ' ' +
                    styles.button +
                    ' ' +
                    styles.whiteOutline +
                    ' __search_desktop_2__'
                  }
                  onClick={togglePopUp}>
                  <i className="fa fa-fw fa-sliders" aria-hidden="true" />{' '}
                  <span>
                    <Lang
                      textKey="moreFilters"
                      values={[numberOfActiveFilters]}
                    />
                  </span>
                </span>
              </span>
            </div>
          </StickyNav>
          {showPopUp && (
            <div className={`${styles.mapFilters}`}>
              <SearchFilters
                address={address}
                searchName={searchName}
                setSearchName={setSearchName}
                setAddress={setAddress}
                setShowPopUp={setShowPopUp}
              />
            </div>
          )}
          <div className={styles.desktopSearch}>
            {isMapLoading && (
              <div className={styles.mapLoader}>
                <div className={styles.spinner}>
                  <Loading className={`${loadingStyles.white} text-center`}>
                    <h3>
                      <Lang textKey="loadingText" />
                    </h3>
                  </Loading>
                </div>
              </div>
            )}
            <div className={styles.desktopSearchList}>
              <div
                className={`${styles.filterWrapper} text-center __search_desktop_3__`}>
                <div className={styles.labelSelectWrapper}>
                  <p className="mt-0 mb-0 mr-1em">
                    <Lang textKey="sortBy" />
                  </p>
                  <div className={styles.inputWrapper + ' __tour4__'}>
                    <i
                      className="fa fa-fw fa-angle-down mr-25em"
                      aria-hidden="true"
                    />
                    <select onChange={onSortByChange} value={sortBy}>
                      <OptionWithLang value="score" textKey="sortByScore" />
                      <OptionWithLang value="name" textKey="sortByName" />
                      {!!userLocation.address && (
                        <OptionWithLang
                          value="distance"
                          textKey="distTo"
                          interpolate={str => str + ' ' + userLocation.address}
                        />
                      )}
                    </select>
                  </div>
                </div>
              </div>
              <div className={styles.count + ' pt-1em pb-15em'}>
                <Lang textKey="schoolCount" values={[filteredSchools.length]} />
              </div>
              <div
                id="school-list"
                className={`__tour_1__ ${styles.filteredSchools} ${styles.tourStep} __search_desktop_4__`}>
                {filteredSchools.map(school => {
                  const { school_id } = school;

                  return <ResultItem school={school} key={school_id} />;
                })}
              </div>
            </div>
            <div
              className={'__tour_3__ hide-on-tablet ' + styles.filteredSchools}>
              <GoogleMap
                {...{
                  center,
                  zoom,
                  onBoundsChanged: onBoundsChangedHandler,
                }}>
                {filteredSchools.map(renderSchoolMarker)}
              </GoogleMap>
            </div>
          </div>
        </DesktopLayout>
        <MobileLayout>
          <StickyNav className={`${styles.stickyNav} __search_desktop_1__`}>
            <div>
              <span
                className={`${styles.removeBasicLinkStyles} __search_mobile_2__`}
                onClick={onToggleSearchView}>
                <i
                  className={`fa fa-fw fa-${showMap ? 'th-list' : 'map'}`}
                  aria-hidden="true"
                />{' '}
                <span>
                  {showMap ? (
                    <Lang textKey="listSearchView" />
                  ) : (
                    <Lang textKey="mapSearchView" />
                  )}
                </span>
              </span>
              <span
                className={`${styles.removeBasicLinkStyles} __search_mobile_3__`}
                onClick={togglePopUp}>
                <i className="fa fa-fw fa-sliders" aria-hidden="true" />{' '}
                <span>
                  <Lang textKey="filterAndSort" />
                </span>
              </span>
            </div>
          </StickyNav>
          <div
            style={
              showMap
                ? {
                    display: 'flex',
                    position: 'relative',
                    flexFlow: 'column nowrap',
                    flexGrow: 1,
                  }
                : {}
            }>
            {isMapLoading && (
              <div className={styles.mapLoader}>
                <div className={styles.spinner}>
                  <Loading className={`${loadingStyles.white} text-center`}>
                    <h3>
                      <Lang textKey="loadingText" />
                    </h3>
                  </Loading>
                </div>
              </div>
            )}
            {showMap ? (
              <div className={'__tour_3__ ' + styles.filteredSchools}>
                <GoogleMap
                  {...{
                    center,
                    zoom,
                    onBoundsChanged: onBoundsChangedHandler,
                  }}>
                  {filteredSchools.map(renderSchoolMarker)}
                </GoogleMap>
              </div>
            ) : (
              <>
                <div className={styles.count + ' pt-2em'}>
                  <Lang
                    textKey="schoolCount"
                    values={[getVisibleSchoolCount(filteredSchools)]}
                  />
                </div>
                <div
                  id="school-list"
                  className={`__tour_1__ ${styles.filteredSchools}`}>
                  {filteredSchools.map(school => {
                    const { school_id } = school;

                    return <ResultItem school={school} key={school_id} />;
                  })}
                </div>
              </>
            )}
          </div>
        </MobileLayout>
      </div>
      <MobileLayout>
        <Tour steps={mobileTourSteps} dispatch={dispatch} />
      </MobileLayout>
      <DesktopLayout>{TourMemo}</DesktopLayout>
    </>
  );
};

MapView.whyDidYouRender = true;

/**
 * This functions is used to perform a Component 'props' check to prevent any unnecessary re-renders
 * Theoretically we can perform a much deeper check if necessary
 *
 * NOTE: With this callback we CANNOT preform a preemptive check on any state or
 *       context value(s) utilized by this component
 *
 * @param {object} prevProps
 * @param {object} nextProps
 * @returns {boolean}
 */
const areRouterPropEqual = (prevProps: any, nextProps: any) => {
  // After utilizing `whyDidYouRender` the Router injected props
  // was found to be the culprit of odd re-renders
  const prevRouterMatch = get(prevProps, 'match', {});
  const nextRouterMatch = get(nextProps, 'match', {});

  return isEqual(prevRouterMatch, nextRouterMatch);
};

export default React.memo(MapView, areRouterPropEqual);
