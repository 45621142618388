import React, { FC } from 'react';
import PropTypes from 'prop-types';

// Context
import { isFunction } from 'lodash';
import useWindowDimensions from '../../hooks/useWindowDimensions';

interface DesktopLayoutProps {
  onShow?: () => void;
}

const DesktopLayout: FC<DesktopLayoutProps> = ({ children, onShow }) => {
  const { width } = useWindowDimensions();

  if (992 >= width) {
    return null;
  }

  if (isFunction(onShow)) {
    onShow();
  }

  return <>{children}</>;
};

DesktopLayout.propTypes = {
  onShow: PropTypes.func,
};

DesktopLayout.defaultProps = {
  onShow: () => {},
};

export default DesktopLayout;
