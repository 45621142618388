import React, { FC, useReducer } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

// Mixpanel Tracking
import {
  trackCustomEvent,
  trackHomeMenuOpen,
} from '../../services/mixpanelTracking';

// Components
import AccordionTab from '../../components/AccordionTab';
import NavBar from '../../components/NavBar';
import QuickLinks from '../../components/QuickLinks';
import Footer from '../../components/Footer';
import Lang from '../../components/Lang';
import Tour from '../../components/Tour';
import ToggleLang from '../../components/ToggleLang';

// Custom Hooks
import useTrackPageVisit from '../../hooks/useTrackPageVisit';
import useReadableLanguage from '../../hooks/useReadableLanguage';

// Tour steps
import homepageSteps from './tourSteps';

// Utilities
import { get } from 'lodash';

// Styles
import s from './home.module.scss';

function activeTabReducer(
  oldOpenedTab: string,
  action: { tabClicked: string }
) {
  // track mixpanel home page menu event
  if (action.tabClicked !== oldOpenedTab) trackHomeMenuOpen(action.tabClicked);

  return oldOpenedTab === action.tabClicked ? '' : action.tabClicked;
}

const Home: FC = props => {
  // Route state passed from `SQRP Info` tab
  const location = useLocation();
  const tab = get(location, ['state', 'tab'], '');
  const [openTab, dispatchClick] = useReducer(activeTabReducer, tab);

  useTrackPageVisit('Home');

  return (
    <>
      <Tour dispatch={dispatchClick} steps={homepageSteps} />
      <NavBar className="__home_tour_2__" />
      <QuickLinks className="__home_tour_3__" />
      <div className="pt-1em">
        <AccordionTab
          isOpen={openTab === 'whatIsSQRP'}
          handleClick={() => dispatchClick({ tabClicked: 'whatIsSQRP' })}
          headerTextKey="sqrpExplainHeader"
          image={0}
          icon="fa-question-circle"
          className="__home_tour_1__">
          <WhatIsSQRP />
        </AccordionTab>
        <AccordionTab
          isOpen={openTab === 'overview'}
          handleClick={() => dispatchClick({ tabClicked: 'overview' })}
          headerTextKey="schoolToolOverviewHeader"
          image={1}
          icon="fa-info-circle">
          <Overview />
        </AccordionTab>
        <AccordionTab
          isOpen={openTab === 'searchBy'}
          handleClick={() => dispatchClick({ tabClicked: 'searchBy' })}
          headerTextKey="findASchoolHeader"
          image={2}
          icon="fa-search">
          <SearchBy />
        </AccordionTab>
        <div className={s.helpButtonWrapper}>
          <a className={s.buttonLinks} href="https://kidsfirstchicago.org/">
            <Lang textKey="needHelpButton" />
          </a>
          <ToggleLang className={`${s.buttonLinks} ml-1em`}>
            <Lang textKey="toggleLanguage" />
          </ToggleLang>
        </div>
      </div>
      <Footer />
    </>
  );
};

const WhatIsSQRP: FC = () => (
  <div className="pr-15em pl-15em pb-15em">
    <p>
      <Lang textKey="sqrpExplainBody" />
    </p>
    <div
      className="mt-15em"
      style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
      <iframe
        src="https://player.vimeo.com/video/370078917?title=0&amp;byline=0&amp;portrait=0"
        style={{
          position: 'absolute',
          top: 0,
          left: '0',
          width: '100%',
          height: '100%',
        }}
        frameBorder="0"
        allow="autoplay; fullscreen"
        title="SQRP Video"
      />
    </div>
    <a
      className={s.buttonLinks}
      href="https://vimeo.com/373415236"
      target="_blank"
      rel="noreferrer nofollow noopener">
      <Lang textKey="watchVideoLink" />
    </a>
    <div
      className="mt-1em"
      style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
      <iframe
        src="https://player.vimeo.com/video/504809345?title=0&amp;byline=0&amp;portrait=0"
        style={{
          position: 'absolute',
          top: 0,
          left: '0',
          width: '100%',
          height: '100%',
        }}
        frameBorder="0"
        allow="autoplay; fullscreen"
        title="SQRP Video"
      />
    </div>
    <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
      <iframe
        src="https://player.vimeo.com/video/504818734?title=0&amp;byline=0&amp;portrait=0"
        style={{
          position: 'absolute',
          top: 0,
          left: '0',
          width: '100%',
          height: '100%',
        }}
        frameBorder="0"
        allow="autoplay; fullscreen"
        title="SQRP Video"
      />
    </div>
  </div>
);

const Overview: FC = props => {
  const language = useReadableLanguage();

  return (
    <div className="pr-15em pl-15em pb-15em">
      <p>
        <Lang textKey="schoolToolOverviewBody" />
      </p>
      <div className={s.spaceButtons}>
        <Link
          className={s.buttonLinks}
          onClick={() => {
            trackCustomEvent('Navigated To Search', {
              action: 'click',
              trigger: 'Take the Tour',
              language,
              description: 'Button link',
              location: "'How to use this tool' accordion",
            });
          }}
          to={{
            pathname: '/map',
            hash: '#tour',
          }}>
          <Lang textKey="tourLink" />
        </Link>
        <a
          className={s.buttonLinks}
          href="https://kidsfirstchicago.org/contact"
          target="_blank"
          rel="noopener noreferrer">
          <Lang textKey="contactUsButton" />{' '}
        </a>
      </div>
    </div>
  );
};

const SearchBy = () => {
  const language = useReadableLanguage();

  return (
    <div className="pr-15em pl-15em pb-15em">
      <p>
        <Lang textKey="findASchoolBody" />
      </p>
      <div className="text-center">
        <Link
          className={s.buttonLinks + ' mb-1em'}
          onClick={() => {
            trackCustomEvent('Navigated To Search', {
              action: 'click',
              trigger: 'Search by school name',
              language,
              description: 'Button link',
              location: "'Find a school' accordion",
            });
          }}
          to="/map">
          <i className="fa fa-search mr-5em" />
          <span>
            <Lang textKey="searchSchoolButton" />
          </span>
        </Link>
        <Link
          className={s.buttonLinks + ' mb-1em ml-1em mr-1em'}
          onClick={() => {
            trackCustomEvent('Navigated To Search', {
              action: 'click',
              trigger: 'Search by location',
              language,
              description: 'Button link',
              location: "'Find a school' accordion",
            });
          }}
          to="/map">
          <i className="fa fa-map-pin mr-5em" />
          <span>
            <Lang textKey="searchLocationButton" />
          </span>
        </Link>
        <Link
          className={s.buttonLinks}
          onClick={() => {
            trackCustomEvent('Navigated To Search', {
              action: 'click',
              trigger: 'Explore map',
              language,
              description: 'Button link',
              location: "'Find a school' accordion",
            });
          }}
          to="/map">
          <i className="fa fa-map mr-5em" />
          <span>
            <Lang textKey="exploreMapButton" />
          </span>
        </Link>
      </div>
    </div>
  );
};

export default Home;
