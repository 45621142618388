import React, { Dispatch } from 'react';
import { GridTabReducerAction } from '../reducers/gridTab/types';

export interface ActiveGridContextValue {
  openedElement: string;
  dispatchGridClick: Dispatch<GridTabReducerAction>;
}

const ActiveGridContext = React.createContext<ActiveGridContextValue>({
  openedElement: '',
  dispatchGridClick: () => {},
});

export default ActiveGridContext;
