import React from 'react';

// Store Context
import { useStoreSelector } from '../../../../../context/StoreContext';

// Components
import Lang from '../../../../../components/Lang';
import ScoreFilters from '../../ScoresFilter';
import SearchFilter from '../../SearchFilter';

// Utilities
import utilities from '../../../../../services/utilities';
import styles from '../../../../../components/AccordionTab/accordionTab.module.scss';

// Types
import { AccordionRenderProps } from '../../../../../components/AccordionTab/types';
import { Filters } from '../../../../../reducers/map/types';

const createFilterAccordionBody = (filterKey: keyof Filters = 'grades') => (
  props: AccordionRenderProps
) => {
  const [state] = useStoreSelector(store => store.map);
  const { filters } = state;

  const {
    isTabOpen,
    headerTextKey,
    className,
    headerWrapperClassName,
    headerClassName,
    bodyClassName,
    onClickHandler,
    accordionStyles,
    showCloseButton = true,
  } = props;

  const filterGroup = utilities.getFilterType(filters, filterKey);
  const filtersSelected = Object.keys(filterGroup).reduce(
    utilities.getFilterCountReducer(filterGroup),
    0
  );

  const selectionsText = (count = 0) => {
    return 0 === count ? (
      <Lang textKey="noSelectionsMade" />
    ) : count > 1 ? (
      <Lang textKey="manySelectionsMade" values={[count]} />
    ) : (
      <Lang textKey="oneSelectionMade" values={[count]} />
    );
  };

  return (
    <div
      className={`${accordionStyles.card} ${className} ${
        isTabOpen ? 'open' : ''
      }`}>
      <div className={`${headerWrapperClassName}`} onClick={onClickHandler}>
        <div className={`${accordionStyles.headerContent} ${headerClassName}`}>
          <div>
            <h3>
              <Lang textKey={headerTextKey} />
            </h3>
            <span>{selectionsText(filtersSelected)}</span>
          </div>
          <div>
            <h3 style={{ margin: 0 }}>
              <i
                className={
                  'fa fa-fw fa-chevron-down ' +
                  (isTabOpen && accordionStyles.rotate)
                }
                style={{ transformOrigin: 'center' }}
              />
            </h3>
          </div>
        </div>
      </div>
      {isTabOpen && (
        <>
          <div className={bodyClassName}>
            {'scores' === filterKey ? (
              <>
                <p>
                  <small>
                    <Lang textKey="sortByScoreDescription" />
                  </small>
                </p>
                <ScoreFilters />
              </>
            ) : (
              <SearchFilter filterKey={filterKey} />
            )}
          </div>
          {showCloseButton && (
            <div style={{ textAlign: 'right' }} className={'hide-on-desktop'}>
              <span
                className={`${styles.closeAccordion} ${styles.mapFilter}`}
                onClick={onClickHandler}>
                Close <i className="fa fa-fw fa-chevron-up" />
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default createFilterAccordionBody;
