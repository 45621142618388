import axios from 'axios';

export default axios.create({
  baseURL: 'https://kidsfirst-admin.herokuapp.com/api/v1',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Context-Type': 'application/json',
    'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token',
  },
});
