const camelize = (str: string) => {
  if ('undefined' === typeof str) {
    str = '';
  }

  return str
    .split('_')
    .map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join('');
};

export default camelize;
