import React, { FC, useCallback } from 'react';

// Context
import { useLanguageContext } from '../context/LanguageContext';

export interface ToggleLangProps {
  className?: string;
}

const ToggleLang: FC<ToggleLangProps> = ({
  children = 'Toggle Language',
  className = '',
}) => {
  const { lang, setLang } = useLanguageContext();

  const handleClick = useCallback(
    () => setLang('en-US' === lang ? 'es' : 'en-US'),
    [setLang, lang]
  );

  // Support render props pattern
  if ('function' === typeof children) {
    const renderProps = { lang, handleClick };

    return children(renderProps);
  }

  return (
    <span className={`button ${className}`} onClick={handleClick}>
      {children}
    </span>
  );
};

export default ToggleLang;
