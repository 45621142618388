import React, { FC, useEffect, useRef } from 'react';

// Custom Hooks
import useSearchLocation from './hooks/useSearchLocation';

// Utilities
import { isEmpty } from 'lodash';
import { SearchLocationProps } from './types';

const SearchLocation: FC<SearchLocationProps> = props => {
  const {
    map,
    id,
    placeholder,
    onPlacesChanged: _,
    onBlur,
    ...restOfProps
  } = props;
  const searchRef = useRef<HTMLInputElement>(null);

  useSearchLocation(props);

  useEffect(() => {
    let current = searchRef.current as HTMLInputElement;
    let handler: EventListener;

    if (!isEmpty(map)) {
      handler = (e: Event) => onBlur(e, map);

      current.addEventListener('blur', handler);
    }

    return () => {
      if (!isEmpty(map)) {
        current.removeEventListener('blur', handler);
      }
    };
  }, [onBlur, map]);

  return (
    <>
      <input
        ref={searchRef}
        type="text"
        id={id}
        placeholder={placeholder}
        {...restOfProps}
      />
    </>
  );
};

SearchLocation.defaultProps = {
  placeholder: 'Search Location',
  id: 'address_input',
  onPlacesChanged: ({ pos, short_address, map }) => {},
};

export default SearchLocation;
