import React, { FC } from 'react';
import { Link } from 'react-router-dom';

// Components
import CircleRating from './CircleRating';
import CompareCheckbox from './CompareCheckBox';
import Lang from './Lang';

// Utilities
import { get, last, isEmpty, isFinite } from 'lodash';
import utility from '../services/utilities';

// Styles
import styles from '../views/Map/map.module.scss';
import utilities from '../services/utilities';
import { School } from '../hooks/useSchoolData';

interface ResultItemProps {
  school: School;
}

const ResultItem: FC<ResultItemProps> = props => {
  const { school } = props;

  const {
    address,
    relativeDist,
    school_id: schoolID,
    group,
    community_group,
  } = school;

  const grades_served = utilities.getGradesServed(school);
  const enrollment = utilities.getStudentTotal(school);
  const levelRating = get(school, ['sqrp_rating', 0, 'value']);
  const totalScore = get(school, ['sqrp_total_points_earned', 0, 'value']);
  const name = get(school, 'school_name', get(school, 'long_name'));
  const scoreColor = utility.levelToColor(levelRating);
  const accountability_status = utilities.getAccountabilityStatus(school);
  const status = get(last(accountability_status), 'value', '');

  let relativeDistInfo: React.ReactNode = '';

  if (relativeDist && isFinite(relativeDist)) {
    relativeDistInfo = (
      <p className={styles.schoolDetail}>
        <strong>{relativeDist.toFixed(2)}</strong> <Lang textKey="milesAway" />
      </p>
    );
  }

  const renderStatus = (status: string) => {
    if (isEmpty(status)) {
      return;
    }

    if (
      'Intensive Support' !== status &&
      'Provisional Support' !== status &&
      'Charter Warning List' !== status
    ) {
      return;
    }

    return (
      <p className={styles.schoolDetail} style={{ color: '#F8000C' }}>
        <i className="fa fa-exclamation-triangle" />{' '}
        <Lang textKey="accountabilityStatus" values={[status]} />{' '}
      </p>
    );
  };

  return (
    <Link
      to={`/${group}/${schoolID}`}
      className={styles.resultItem}
      key={schoolID}>
      <div className={styles.leftContentWrapper}>
        <CircleRating levelRating={levelRating} />
      </div>
      <div>
        <h3 className="mt-0 mb-25em">{name}</h3>
        <p className={styles.schoolDetail}>
          <Lang textKey="gradesServed" />: {grades_served}
        </p>
        <p className={styles.schoolDetail}>
          {enrollment} <Lang textKey="students" />
        </p>
        <p className={`${styles.schoolDetail} ${styles.schoolAddress}`}>
          {address}
        </p>
        <p className={styles.schoolDetail}>
          <Lang textKey="communityArea" />: {community_group}
        </p>
        <div className={`${styles.totalScore}`}>
          <p className={styles.schoolDetail}>
            {/* TODO: TRANSLATE: needs to be translatable points */}
            <span className={scoreColor}>{totalScore}</span>
            <span> out of 5.0 points</span>
          </p>
        </div>
        {renderStatus(status)}
        {relativeDistInfo}
      </div>
      <CompareCheckbox
        schoolID={schoolID}
        schoolGroup={group}
        className={styles.compareWrapper}
      />
    </Link>
  );
};

// functional component version of 'shouldComponentUpdate()' lifecycle method
// checks whether component needs to rerender on state change high in the tree
// this should only update when a new distance is given through props
// checking 'School ID' is done for saftey
export default React.memo(
  ResultItem,
  (prevProps, nextProps) =>
    prevProps.school.school_id === nextProps.school.school_id &&
    prevProps.school.relativeDist === nextProps.school.relativeDist
);
