import React, { FC, SyntheticEvent } from 'react';

// Store
import { useStoreSelector } from '../../../context/StoreContext';

// Custom Hooks
import useTrackAdvancedFilterUse from '../hooks/useTrackAdvanceFilterUse';

// Components
import CheckboxGroup from './ChecboxGroup';

// Utilities
import { isEmpty, get, findIndex } from 'lodash';
import utilities from '../../../services/utilities';
import {
  FilterGroup,
  Filters,
  FilterWithChildren,
} from '../../../reducers/map/types';

interface SearchFilterProps {
  filterKey: keyof Filters;
}

const SearchFilter: FC<SearchFilterProps> = props => {
  const [state, dispatch] = useStoreSelector(store => store.map);
  const { filters } = state;
  /**
   * `filterKey` is the key in the filters object (in the GLOBAL Store)
   * you'd like to render into a clickable set of checkboxes
   *
   * @type {string}
   */
  const { filterKey, children } = props;
  const searchFilters = utilities.getFilterType<FilterGroup>(
    filters,
    filterKey
  );

  const mixpanelTrackEvent = useTrackAdvancedFilterUse(filterKey);

  const onParentChange = (
    event: SyntheticEvent,
    searchFilters: FilterGroup
  ) => {
    const name = get(event, ['target', 'id']);
    const isChecked = get(event, ['target', 'checked'], false);

    let searchFilter = { ...searchFilters[name], isChecked };

    if (!isEmpty(searchFilter.children) && searchFilter.children) {
      // Ensure all sub-filter have the same 'isChecked' value
      const childrenUpdated = searchFilter.children.map(child => ({
        ...child,
        isChecked,
      }));
      searchFilter = { ...searchFilter, children: childrenUpdated };
    }

    mixpanelTrackEvent(searchFilter.label);

    const newFilters = { ...searchFilters, [name]: searchFilter };

    dispatch({
      type: 'FILTER_SCHOOLS',
      payload: {
        filters: { ...filters, [filterKey]: newFilters },
      },
    });
  };

  const onChildChange = (
    event: SyntheticEvent,
    searchFilters: FilterGroup,
    parentKey: string
  ) => {
    const name = get(event, ['target', 'id'], '');
    const isChecked = get(event, ['target', 'checked'], false);

    let parentFilter = { ...searchFilters[parentKey] } as FilterWithChildren;

    // Find index of sub-filter
    const subFilterIndex = findIndex(
      parentFilter.children,
      subFilter => name === subFilter.name
    );
    const subFilter = { ...parentFilter.children[subFilterIndex], isChecked };

    if (!isChecked) {
      parentFilter = { ...parentFilter, isChecked };
    }

    mixpanelTrackEvent(subFilter.label, parentKey);

    const childrenUpdated = parentFilter.children.map(
      (child, index: number) => {
        let childUpdated = { ...child };

        // Modify children array sub-filters and ONLY update the child with the correct index
        if (index === subFilterIndex) {
          childUpdated = { ...subFilter };
        }

        return childUpdated;
      }
    );

    parentFilter = { ...parentFilter, children: childrenUpdated };

    const numOfChildrenChecked = parentFilter.children.reduce(
      (count, child) => (child.isChecked ? count + 1 : count),
      0
    );

    // Set parent filter to 'checked' if ALL child filters have been 'checked'
    if (parentFilter.children.length === numOfChildrenChecked) {
      parentFilter.isChecked = true;
    }

    const newFilters = { ...searchFilters, [parentKey]: { ...parentFilter } };

    dispatch({
      type: 'FILTER_SCHOOLS',
      payload: {
        filters: { ...filters, [filterKey]: newFilters },
      },
    });
  };

  const renderProps = {
    searchFilters,
    filters,
    onParentChange,
    onChildChange,
  };

  if ('function' === typeof children) {
    return children(renderProps);
  }

  return Object.keys(searchFilters)
    .filter(searchFilter => !isEmpty(searchFilters[searchFilter].name))
    .map((filter, index) => {
      const searchFilter = { ...searchFilters[filter] };
      const { name, value, label, isChecked, children = [] } = searchFilter;

      return (
        <CheckboxGroup
          {...{
            name,
            value,
            label,
            isChecked,
            key: index,
            nestedChildren: children,
            showChildren: true,
            onParentChange: (event: SyntheticEvent) =>
              onParentChange(event, searchFilters),
            onChildChange: (event: SyntheticEvent, parentKey: string) =>
              onChildChange(event, searchFilters, parentKey),
          }}
        />
      );
    });
};

SearchFilter.defaultProps = {
  filterKey: 'grades',
};

export default SearchFilter;
