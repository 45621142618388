import React, { FC, useRef } from 'react';

// Context
import StickyNavContext from './context/StickyNavContext';

// Custom hooks
import useStickyNav from './hooks/useStickyNav';

// Types
import { StickyNavProps } from './types';

// Styles
import styles from './stickyNav.module.scss';

const StickyNav: FC<StickyNavProps> = props => {
  const { containerID, className } = props;
  const navRef = useRef<HTMLElement>(null);

  const scrollOffset = useStickyNav(navRef);

  return (
    <StickyNavContext.Provider value={navRef}>
      <div
        id={containerID}
        style={
          scrollOffset.scroll > scrollOffset.top
            ? { paddingTop: scrollOffset.height + 'px' }
            : { paddingTop: 0 }
        }>
        <nav
          ref={navRef}
          className={`sticky-nav ${className} ${
            scrollOffset.scroll > scrollOffset.top ? styles.fixed : ''
          }`}>
          {props.children}
        </nav>
      </div>
    </StickyNavContext.Provider>
  );
};

StickyNav.defaultProps = {
  containerID: 'nav-sticky',
  className: '',
};

export default StickyNav;
