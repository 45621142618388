/**
 * Remove a specified value type from a object literal
 *
 * @param {Object} obj
 * @param {string} type
 */
const removeValueType = (obj: any, type: string) => {
  const keys = Object.keys(obj);
  const newObj: any = {};

  keys.forEach(key => {
    if (typeof obj[key] !== type) {
      newObj[key] = obj[key];
    }
  });

  return newObj;
};

export default removeValueType;
