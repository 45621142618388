// Filters
import { defaultFilters } from './index';

// Utilities
import { isEmpty, includes } from 'lodash';
import { MapSchool } from '../types';

const grade = (schools: MapSchool[] = [], filters = defaultFilters) => {
  let schoolsUpdates = [...schools];
  const { grades } = filters;

  const isPreSchoolChecked = grades['preschool'].isChecked;

  const checkedElementaryGrades = grades['elementary-school'].children
    .filter(grade => grade.isChecked)
    .map(grade => grade.name);

  const checkedHighSchoolGrades = grades['high-school'].children
    .filter(grade => grade.isChecked)
    .map(grade => grade.name);

  const gradesChecked = [
    ...checkedElementaryGrades,
    ...checkedHighSchoolGrades,
  ];

  if (isPreSchoolChecked) {
    gradesChecked.push('PK');
  }

  /**
   * Get all possible grade values based on the 'Grade' filters available
   *
   * @type {string[]}
   */
  const allPossibleGrades = [
    'PK',
    ...grades['elementary-school'].children.map(grade => grade.name),
    ...grades['high-school'].children.map(grade => grade.name),
  ];

  // Like other filter types return unfiltered schools if NONE or ALL grades are checked
  if (
    isEmpty(gradesChecked) ||
    allPossibleGrades.length === gradesChecked.length
  ) {
    return schoolsUpdates;
  }

  schoolsUpdates = schoolsUpdates.filter(school => {
    let isMatch = false;
    const { grades_served = '', grades_offered_all = '' } = school;

    if (isEmpty(grades_served) && isEmpty(grades_offered_all)) {
      return isMatch;
    }

    /**
     * Split 'grades_offered_all' field (assumed to be string)
     * Ex.
     *      grades_offered_all: '9,10,11,12' => ['9', '10', '11', '12']
     *
     * @type {string[]}
     */
    let gradesOfferedAll = !isEmpty(grades_offered_all)
      ? grades_offered_all.split(',')
      : [];

    // Fall back on `grades_served` field if `grades_offered_all` field is empty
    if (isEmpty(gradesOfferedAll)) {
      /**
       * Split the 'grades_served' field derived from the school data into lower and upper bound school grades
       * Ex.
       *      grades_served: '9-12' => ['9', '12']
       *
       * @type {string}
       */
      let [lowestGradeLevel = '', highestGradeLevel = ''] = grades_served.split(
        '-'
      );

      // In this scenario the schools only offers 1 grade
      if (lowestGradeLevel === highestGradeLevel) {
        gradesOfferedAll.push(lowestGradeLevel);
      }

      let count;

      if ('PK' === lowestGradeLevel && 'K' !== highestGradeLevel) {
        gradesOfferedAll.push('PK');
        gradesOfferedAll.push('K');
        count = 1;
      } else if ('K' === lowestGradeLevel) {
        gradesOfferedAll.push('K');
        count = 1;
      } else {
        count = parseInt(lowestGradeLevel);
      }

      let max = parseInt(highestGradeLevel);

      for (let i = count; i <= max; i++) {
        gradesOfferedAll.push(`${i}`);
      }
    }

    gradesOfferedAll.forEach(grade => {
      if (includes(gradesChecked, grade)) {
        isMatch = true;
      }
    });

    return isMatch;
  });

  return schoolsUpdates;
};

export default grade;
