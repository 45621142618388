import { Chart, ChartData } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; // eslint-disable-line no-unused-vars
// the import above IS used. The library is needed for the history chart data labels

import utility from './utilities';

Chart.defaults.global.defaultFontFamily = 'Libre Franklin';

interface MetricHistoryData {
  values: (string | null)[];
  points: (string | null)[];
  years: string[];
}

function createData(data: MetricHistoryData): ChartData {
  // get rgba point colors
  const { points } = data;

  /**
   * Dynamically get each year in the dataset and format it to get labels to fit
   * nicely within the charts
   *
   * Input => ['2014-2015', '2015-2016', ...]
   * Result => ['14-15', '15-16', ...]
   */
  const dataLabels = data.years.map(year => {
    const [start, end] = year.split('-');
    const formattedStart = start.slice(2);
    const formattedEnd = end.slice(2);

    return `${formattedStart}-${formattedEnd}`;
  });

  // Limit the chart to only display the last 5 years of data
  let startInterval = dataLabels.length > 5 ? dataLabels.length - 5 : 0;
  let endInterval = dataLabels.length;

  const pointColors = [...points]
    .splice(startInterval, endInterval)
    .map(point => utility.pointToCanvasColor(point as string));

  const limitedData = [...data.values]
    .splice(startInterval, endInterval)
    .map(value => parseInt(value as string));

  const historyData = {
    data: limitedData,
    borderColor: 'black',
    borderWidth: 2,
    pointRadius: 23,
    backgroundColor: 'rgba(0,0,0,0)',
    pointBackgroundColor: pointColors as string[],
  };

  let labels = [...dataLabels].splice(startInterval, endInterval);

  return {
    labels,
    datasets: [historyData],
  };
}

export default function makeScoreHistoryChart(
  schoolHistory: MetricHistoryData,
  ctx: CanvasRenderingContext2D
) {
  return new Chart(ctx, {
    type: 'line',
    data: createData(schoolHistory),
    plugins: [ChartDataLabels],
    options: {
      plugins: {
        datalabels: {
          display() {
            return true;
          },
          font: {
            size: 15,
            weight: 'bold',
          },
          color: 'white',
          anchor: 'center',
          align() {
            return 'center';
          },
          formatter(value) {
            return `${String(parseFloat(value).toFixed(0))}%`;
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: false,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              fontSize: 14,
            },
            // stacked: true,
            // categoryPercentage: 1.0,
            // barPercentage: 1.0,
          },
        ],
        yAxes: [
          {
            // stacked: true,
            ticks: {
              fontSize: 14,
              display: false,
            },
            scaleLabel: {
              labelString: 'Score',
              display: false,
              fontSize: 14,
              fontStyle: 'bold',
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      elements: {
        line: {
          tension: 0,
        },
      },
      layout: {
        padding: {
          right: 30,
          left: 20,
          top: 30,
          // bottom: 15,
        },
      },
    },
  });
}
