import { Chart, ChartData, ChartDataSets } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface Entry {
  score: string;
  year: string;
}

function createData(data: Entry[]): ChartData {
  let scores = data.map(record => record.score);

  scores.forEach((item, ix) => {
    if (item === '') {
      scores[ix] = 'null';
    }
  });

  /**
   * Dynamically get each year in the dataset and format it to get labels to fit
   * nicely within the charts
   *
   * Input => ['2014-2015', '2015-2016', ...]
   * Result => ['14-15', '15-16', ...]
   */
  let labels = data.map(record => {
    const [start, end] = record.year.split('-');
    const formattedStart = start.slice(2);
    const formattedEnd = end.slice(2);

    return `${formattedStart}-${formattedEnd}`;
  });

  // Limit the chart to only display the last 5 years of data
  let startInterval = labels.length > 5 ? labels.length - 5 : 0;
  let endInterval = labels.length;

  labels = [...labels].splice(startInterval, endInterval);
  const dataSetScores = [...scores]
    .splice(startInterval, endInterval)
    .map(score => parseFloat(score));

  const historyData: ChartDataSets = {
    label: 'SQRP Score History',
    data: dataSetScores,
    type: 'line',
    borderColor: 'black',
    pointRadius: 5,
    backgroundColor: 'rgba(0,0,0,0)',
    categoryPercentage: 1.0,
    barPercentage: 1.0,
  };

  return {
    labels,
    datasets: [
      {
        label: 'Level 3',
        backgroundColor: 'rgba(200, 0, 11, 0.3)',
        data: [1.95, 1.95, 1.95, 1.95, 1.95],
        categoryPercentage: 1.0,
        barPercentage: 1.0,
      },
      {
        label: 'Level 2',
        backgroundColor: 'rgba(255, 91, 62, 0.3)',
        data: [0.99, 0.99, 0.99, 0.99, 0.99],
        categoryPercentage: 1.0,
        barPercentage: 1.0,
      },
      {
        label: 'Level 2+',
        backgroundColor: 'rgba(255, 218, 73, 0.3)',
        data: [0.5, 0.5, 0.5, 0.5, 0.5],
        categoryPercentage: 1.0,
        barPercentage: 1.0,
      },
      {
        label: 'Level 1',
        backgroundColor: 'rgba(111,210,115, 0.3)',
        data: [0.5, 0.5, 0.5, 0.5, 0.5],
        categoryPercentage: 1.0,
        barPercentage: 1.0,
      },
      {
        label: 'Level 1+',
        backgroundColor: 'rgba(0,149,54, 0.3)',
        data: [1.06, 1.06, 1.06, 1.06, 1.06],
        categoryPercentage: 1.0,
        barPercentage: 1.0,
      },
      historyData,
    ],
  };
}

export default function makeScoreHistoryChart(
  schoolHistory: Entry[],
  ctx: CanvasRenderingContext2D
) {
  return new Chart(ctx, {
    type: 'bar',
    data: createData(schoolHistory),
    plugins: [ChartDataLabels],
    options: {
      plugins: {
        datalabels: {
          display(context) {
            return context.datasetIndex === 5;
          },
          font: {
            size: 20,
            weight: 'bold',
          },
          color: 'black',
          anchor: 'start',
          align(context) {
            // flip data label when top of chart would clip the text
            const value = (context.dataset.data as number[])[context.dataIndex];

            if (value > 4.3) {
              return 'top';
            }

            return 'bottom';
          },
          offset(context) {
            // offset less when label is below
            const value = (context.dataset.data as number[])[context.dataIndex];

            if (value > 4.3) {
              return -40;
            }

            return -50;
          },
          formatter(value) {
            return String(parseFloat(value).toFixed(1));
          },
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        enabled: false,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              fontSize: 14,
            },
            stacked: true,
            scaleLabel: {
              labelString: 'Year',
              display: false,
              fontSize: 14,
              fontStyle: 'bold',
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
            ticks: {
              min: 1.5,
              fontSize: 14,
            },
            scaleLabel: {
              labelString: 'Score',
              display: false,
              fontSize: 14,
              fontStyle: 'bold',
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      elements: {
        line: {
          tension: 0,
        },
      },
    },
  });
}
