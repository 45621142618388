import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface ColumnProps {
  col?: number;
  className?: string;
}

const Column: FC<ColumnProps> = ({ children, col = 1, className = '' }) => {
  return <div className={`col col-${col} ${className}`}>{children}</div>;
};

Column.propTypes = {
  col: PropTypes.number,
  className: PropTypes.string,
};

Column.defaultProps = {
  col: 1,
  className: '',
};

export default Column;
