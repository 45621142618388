import React, { useState } from 'react';

// Context
import LangContext from '../context/LanguageContext';

// Custom Hooks
import useCSVTranslations from '../hooks/useCSVTranslations';

/**
 * TODO: discuss metric detail text handling with both languages
 * option1: use keys as-is and add description to end.. (very fragile)
 * option2: parse metric from DB differently (add underscores, dashes, etc.)
 */

// TODO: show Cale my thinking here...
// @help http://convertcsv.com/json-to-csv.htm
//console.log(
//  JSON.stringify(
//    Object.keys( translations ).map( key => ({
//      key,
//      en: translations[ key ].en,
//      es: translations[ key ].es
//    }) )
//  )
//);

export const langKeyMap = {
  'en-US': 'en',
  es: 'es',
};

export const langReadableMap = {
  'en-US': 'English',
  es: 'Spanish',
};

/**
 * React Component for providing the language context
 * should only be imported into the App.jsx component
 */
const LangContextProvider = React.memo(props => {
  const [lang, setLang] = useState(() =>
    langKeyMap.hasOwnProperty(navigator.language) ? navigator.language : 'en-US'
  );
  const translationValues = useCSVTranslations();

  const contextValue = { lang, translationValues, setLang };

  return (
    <LangContext.Provider value={contextValue}>
      {props.children}
    </LangContext.Provider>
  );
});

export default LangContextProvider;
