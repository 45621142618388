import mapStyles from '../views/Map/map.module.scss';
import { TourStep } from '../components/Tour/types';

// @help https://docs.react-joyride.com/step
// @help https://docs.react-joyride.com/props
// @help https://docs.react-joyride.com/styling

/*
{ beforeNext } - action object that is passed to dispatch function before next step is shown
*/

export const defaultOptions: TourStep = {
  title: 'School Quality Tour',
  disableBeacon: true,
  beforeNext: null,
  beforePrev: null,
  scrollDebounce: 200,
  placement: 'auto',
  content: '',
  target: '',
};

export const createTourSteps = (steps: TourStep[]) => {
  return steps.map(step => ({ ...defaultOptions, ...step }));
};

export const mapPageSteps = [
  {
    title: 'Search',
    target: 'body',
    content:
      'You can zoom and adjust the map to narrow your search for schools',
    placement: 'center',
  },
  {
    title: 'Narrow Results',
    content:
      'Use the options at the top to narrow your search results by school name, location, high schools, or elementary schools. \n' +
      'You can then sort your results',
    target: '#filters',
    placement: 'top',
  },
  {
    title: 'Search Result & SQRP Level',
    content:
      "The search result includes a snapshot of information about the school.  On the left in the circle is the school's level rating, and their score out of 5 points. The search result includes a snapshot of information about the school.  On the left in the circle is the school's level rating, and their score out of 5 points.",
    target: `.${mapStyles.resultItem}:first-child > div:first-child`,
  },
  {
    title: 'School Information',
    content: 'In the middle is basic school profile information.',
    target: `.${mapStyles.resultItem}:first-child > div:nth-child(2)`,
  },
  {
    title: 'Compare Schools',
    content: 'Use this feature to compare 2 or more schools.',
    target: `.${mapStyles.resultItem}:first-child > div:nth-child(3)`,
  },
  {
    title:
      "Select the search result to dive deeper into a school's performance",
    content: '',
    target: `.${mapStyles.resultItem}:first-child`,
  },
].map(step => ({ ...defaultOptions, ...step }));

const profilePageSteps = (tabToClick: string) =>
  [
    {
      target: 'body',
      content: 'Take the tour!',
      placement: 'center',
    },
    {
      target: '.__tour1__',
      content: "View this school's current School Quality Rating here.",
    },
    {
      target: '.__tour2__',
      content: "View this school's Quality Rating score over time here.",
    },
    {
      target: '.__tour3__',
      content:
        'Find individual school quality metrics here. Their values are colorized to indicate their quality relative to other Chicago Public Schools.',
      placement: 'top',
      scrollToFirstStep: true,
      beforeNext: {
        tabClicked: tabToClick,
        payload: { tabClicked: tabToClick },
        type: 'OPEN_GRID_TAB',
      },
    },
    {
      target: '.__tour4__',
      content: "View this school's score history of this sub-metric.",
      placement: 'bottom',
      beforePrev: { tabClicked: tabToClick },
      scrollDebounce: 800,
      spotlightPadding: -5,
    },
  ].map(step => ({ ...defaultOptions, ...step }));

export const highSchoolPageSteps = profilePageSteps(
  '4-Year Cohort Graduation Rate'
);
export const elemSchoolPageSteps = profilePageSteps(
  'National School Growth Percentile - Reading'
);
