import React, { useEffect, useRef, useCallback, FC } from 'react';
import makeChart from '../../../services/makeScoreHistoryChart';
import { useSchoolContext } from '../../../context/SchoolContext';

import s from '../profile.module.scss';
import useViewportVisibility from '../../../hooks/useElementIsInViewport';
import { trackCustomEvent } from '../../../services/mixpanelTracking';

import { get } from 'lodash';

interface TotalScoreHistoryProps {}

interface Record {
  year: string;
  value: string;
}

const TotalScoreHistory: FC<TotalScoreHistoryProps> = props => {
  const schoolData = useSchoolContext();
  const historyData = get(schoolData, 'sqrp_total_points_earned');
  const chartElement = useRef<HTMLCanvasElement>(null);

  const trackHistoryScroll = useCallback(language => {
    trackCustomEvent('School Profile History In View', {
      action: 'scroll',
      trigger: 'User Scroll',
      language,
      description: 'User scrolled to have school profile history in view',
      location: 'School Profile screen',
    });
  }, []);

  const [_, currentElement] = useViewportVisibility<HTMLDivElement>( // eslint-disable-line
    trackHistoryScroll
  );

  useEffect(() => {
    const scores = historyData.map((yearRecord: Record) => ({
      year: yearRecord.year,
      score: yearRecord.value,
    }));

    if (chartElement.current) {
      const ctx = chartElement.current.getContext(
        '2d'
      ) as CanvasRenderingContext2D;

      makeChart(scores, ctx);
    }
  }, [historyData]);

  return (
    <div className={s.historyChartContainer} ref={currentElement}>
      <canvas ref={chartElement} />
    </div>
  );
};

export default TotalScoreHistory;
