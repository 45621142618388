import { useEffect, useReducer } from 'react';

// Reducers
import { compareReducer } from '../reducers/compare';
import { CompareContext, CompareState } from '../reducers/compare/types';

const useCompareState = (reducer = compareReducer): CompareContext => {
  const [compareState, dispatchCompare] = useReducer(
    reducer,
    {
      'high-school': [],
      'elementary-school': [],
      showPopup: false,
      schoolGroup: 'elementary-school',
    },
    (defaultState: CompareState) => {
      const storedCompareState = localStorage.getItem('compareState');

      if (storedCompareState) {
        return {
          ...JSON.parse(storedCompareState),
          showPopup: false,
          schoolGroup: '',
        };
      }

      return defaultState;
    }
  );

  useEffect(() => {
    localStorage.setItem('compareState', JSON.stringify(compareState));
  }, [compareState]);

  return [compareState, dispatchCompare];
};

export default useCompareState;
