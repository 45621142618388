import React, { FC } from 'react';
import './block.scss';

interface FooterContentProps {
  isFooterLink?: boolean;
  className?: string;
}

const FooterContent: FC<FooterContentProps> = ({
  children,
  isFooterLink = false,
  className = '',
}) => (
  <div
    className={`footer-content ${
      isFooterLink ? 'footer-link' : ''
    } ${className}`}>
    {children}
  </div>
);

export default FooterContent;
