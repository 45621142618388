import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

// Context
import SchoolContext from '../../context/SchoolContext';

// Components
import Loading from '../../components/Loading';
import GroupBreakdown from './components/GroupBreakdown';
import GroupItem from './components/GroupItem';
import NavBar from '../../components/NavBar';

// Custom Hooks
import useSchoolData from '../../hooks/useSchoolData';
import useDocumentMetaData from '../../hooks/useDocumentMetaData';
import useRatingSchema from '../../hooks/useRatingSchema';

// Utils
import { get } from 'lodash';

// Styles
import styles from './full-report.module.scss';

const ElemSchool: FC = props => {
  const { schoolID } = useParams();

  const schoolData = useSchoolData(schoolID);
  const [activeGroup, setActiveGroup] = useState('studentGrowth');

  useDocumentMetaData(schoolData);
  useRatingSchema(schoolData);

  if (!schoolData) return <Loading fixedCenter />;

  const name = get(schoolData, 'long_name', get(schoolData, 'school_name'));

  return (
    <div>
      <NavBar />
      <div className="pl-5em pr-5em">
        <h3>{name}</h3>
        <SchoolContext.Provider value={schoolData}>
          <div className={styles.groupTabsContainer}>
            <GroupItem
              icon="fa-line-chart"
              text="NWEA Growth"
              colorFromGroup="studentGrowth"
              onClick={() => setActiveGroup('studentGrowth')}
              active={activeGroup === 'studentGrowth'}
            />
            <GroupItem
              icon="fa-list"
              text="NWEA Attainment"
              colorFromGroup="studentAttainment"
              onClick={() => setActiveGroup('studentAttainment')}
              active={activeGroup === 'studentAttainment'}
            />
            <GroupItem
              icon="fa-graduation-cap"
              text="Attendance"
              colorFromGroup="attendance"
              active={activeGroup === 'attendance'}
              onClick={() => setActiveGroup('attendance')}
            />
            <GroupItem
              icon="fa-globe"
              text="Other"
              colorFromGroup="schoolClimate"
              onClick={() => setActiveGroup('schoolClimate')}
              active={activeGroup === 'schoolClimate'}
            />
          </div>
          {activeGroup === 'attendance' && (
            <GroupBreakdown groupKey="attendance" />
          )}
          {activeGroup === 'studentGrowth' && (
            <GroupBreakdown groupKey="studentGrowth" />
          )}
          {activeGroup === 'studentAttainment' && (
            <GroupBreakdown groupKey="studentAttainment" />
          )}
          {activeGroup === 'schoolClimate' && (
            <GroupBreakdown groupKey="schoolClimate" />
          )}
        </SchoolContext.Provider>
      </div>
    </div>
  );
};

export default ElemSchool;
